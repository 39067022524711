var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BaseLink',{class:_vm.$style.channelLink,attrs:{"to":{ name: 'channel', params: { channelId: _vm.channel.id } },"name":"channel"}},[_c('BasePlaceholder',{class:[_vm.$style.channelTitle, _vm.displayMinimalVersion && _vm.$style.minimal],attrs:{"text":_vm.channel.title,"tag":_vm.displayMinimalVersion ? 'h6' : 'h5',"type":"channel"}})],1),_c('div',{class:[_vm.$style.coverArea, 'coverArea', { [_vm.$style.active]: _vm.index === _vm.activeSlide }]},[_c('div',{class:[_vm.$style.bg, _vm.$style[_vm.channel.id]]},[(_vm.nowPlayingAvailable && _vm.nowPlaying.mixes.album_art)?_c('div',{class:_vm.$style.imageBg,style:(`background-image:url(${_vm.nowPlaying.mixes.album_art.url})`)}):_vm._e(),_c('div',{class:_vm.$style.gradientBg})]),_c('div',{class:[_vm.$style.coverInner, _vm.$style[_vm.channel.id]]},[_c('div',{class:_vm.$style.coverImage},[(_vm.nowPlayingAvailable)?_c('BaseResourceImage',{class:_vm.$style.image,attrs:{"resource":_vm.nowPlaying.mixes.episode,"should-lazyload":false}}):_vm._e()],1),_c('div',{class:_vm.$style.coverImageBlur},[(_vm.nowPlayingAvailable)?_c('BaseResourceImage',{class:_vm.$style.image,attrs:{"resource":_vm.nowPlaying.mixes.episode,"should-lazyload":false}}):_vm._e()],1),(_vm.channel)?_c('BaseResourceCover',{class:_vm.$style.overlay,attrs:{"media-resource":_vm.channel,"media-type":"channel"}},[_c('div',{attrs:{"slot":"secondaryActions"},slot:"secondaryActions"},[_c('ChannelOptions',{attrs:{"channel":_vm.channel}})],1)]):_vm._e()],1)]),(_vm.channel)?_c('div',{class:_vm.$style.nowPlayingArea},[_c('div',{class:['heading', _vm.$style.nowPlayingHead]},[_vm._v("Now Playing")]),_c('div',{class:_vm.$style.nowPlayingContent},[(_vm.nowPlayingAvailable && _vm.nowPlaying.mixes.show)?_c('BaseLink',{attrs:{"to":{ name: 'show', params: { id: _vm.nowPlaying.mixes.show.id } }}},[_c('div',{class:[
                        'title',
                        _vm.$style.nowPlayingTitle,
                        { [_vm.$style.active]: _vm.status === 'playing' && _vm.resource.id === _vm.channel.id },
                    ]},[_vm._v(" "+_vm._s(_vm.nowPlaying.mixes.show.title)+" ")])]):_c('BasePlaceholder',{class:[
                    'title',
                    _vm.$style.nowPlayingTitle,
                    { [_vm.$style.active]: _vm.status === 'playing' && _vm.resource.id === _vm.channel.id },
                ],attrs:{"text":_vm.nowPlayingAvailable && _vm.nowPlaying.mixes.show.title,"type":"show","tag":"div"}}),(_vm.nowPlayingAvailable && _vm.nowPlaying.mixes.id)?_c('BaseLink',{attrs:{"to":{ name: 'mix', params: { id: _vm.nowPlaying.mixes.id } }}},[_c('div',{class:_vm.$style.date},[_vm._v(_vm._s(_vm.airDate(_vm.nowPlaying.mixes.episode.air_start)))])]):_c('BasePlaceholder',{class:_vm.$style.date,attrs:{"text":_vm.nowPlayingAvailable && _vm.airDate(_vm.nowPlaying.mixes.episode.air_start),"size":5,"tag":"div"}}),(_vm.nowPlayingAvailable)?_c('BaseLink',{attrs:{"to":{ path: `/artist/${_vm.nowPlaying.mixes.artist.url}/${_vm.nowPlaying.mixes.artist.id}` }}},[_c('div',{class:['subtitle', 'highlight', _vm.$style.nowPlayingArtist]},[_vm._v(" "+_vm._s(_vm.nowPlaying.mixes.artist.title)+" ")])]):_c('BasePlaceholder',{class:['subtitle', 'highlight', _vm.$style.nowPlayingArtist],attrs:{"text":_vm.nowPlayingAvailable && _vm.nowPlaying.mixes.artist.title,"type":"artist","tag":"div"}})],1),_c('div',{class:_vm.$style.mobilePlayButton},[_c('BaseButtonPlay',{attrs:{"resource":{ type: 'channel', id: _vm.channel.id },"icon":""}})],1)]):_vm._e(),(!_vm.displayMinimalVersion)?_c('ShowScheduleVerticalBlock',{attrs:{"playlist":_vm.playlist,"now-playing":_vm.channel.nowPlaying}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }