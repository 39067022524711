<script>
import DataLayer from '@services/data-layer/index'
import BfhsBlock from '@components/bfhs/bfhs-block'

export default {
    components: { BfhsBlock },

    data() {
        return {
            bfhs: [],
            bfhsLoaded: false,
        }
    },

    mounted() {
        DataLayer.bfhs.getAll({}).then(res => {
            this.bfhs = res.body
            this.bfhsLoaded = true
        })
    },
}
</script>

<template>
    <BfhsBlock
        v-if="!bfhsLoaded || bfhs.length"
        :bfhs="bfhs"
    />
</template>

<style lang="scss" module>
@import '@design';
</style>
