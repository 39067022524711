<script>
import formatDate from '@utils/format-date-full'
import MixOptions from '@components/mix/mix-options'

export default {
    components: { MixOptions },
    props: {
        mix: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        airDate() {
            if (this.mix.episode) {
                const date = new Date(this.mix.episode.air_start)
                return formatDate(date)
            }
            return ''
        },
    },
}
</script>

<template>
    <div>
        <BaseLink
            :class="$style.linkContainer"
            :to="{ name: 'mix', params: { id: mix.id } }"
        >
            <BaseResourceCover
                :image-resource="mix.episode"
                :media-resource="mix"
                :class="$style.image"
                media-type="mix"
            >
                <div slot="secondaryActions">
                    <MixOptions :mix="mix" />
                </div>
            </BaseResourceCover>
        </BaseLink>
        <BaseLink
            v-if="mix.show && mix.show.title"
            :to="{ name: 'show', params: { id: mix.show.id } }"
        >
            <div :class="$style.title">
                {{ mix.show && mix.show.title }}
            </div>
        </BaseLink>
        <BasePlaceholder
            v-else
            :class="$style.title"
            :text="mix.show && mix.show.title"
            type="show"
            tag="div"
        />
        <div :class="$style.artist">
            <BaseLink
                v-if="mix.artist"
                :to="{ path: `/artist/${mix.artist.url}/${mix.artist.id}` }"
            >
                {{ mix.artist.title }}
            </BaseLink>
            <BasePlaceholder
                v-else
                :text="mix.artist && mixt.artist.title"
                type="artist"
            />
        </div>
        <div :class="$style.date">
            <BaseLink
                v-if="mix.episode"
                :to="{ name: 'mix', params: { id: mix.id } }"
            >
                {{ airDate }}
            </BaseLink>
            <BasePlaceholder
                v-else
                :text="mix.episode && airDate"
                :size="7"
            />
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.title {
    @extend %contentTitleM;

    margin-top: $size-grid-padding;
    line-height: 24px;
    text-align: center;
}

.artist {
    @extend %contentSubtitleM;

    text-align: center;
}

.date {
    @extend %contentSubtitleS;

    text-align: center;
}

.icon {
    &Button {
        margin: 0 auto;
        text-align: center;
    }
}
</style>
