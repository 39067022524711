<script>
export default {
    props: {
        text: {
            type: [String, Boolean],
            default: false,
        },
        size: {
            type: [String, Number],
            default: '100%',
        },
        type: {
            type: [String, Boolean],
            default: false, // 'artist', 'show', 'channel', 'genre'
        },
        randomized: {
            type: Boolean,
            default: true,
        },
        tag: {
            type: String,
            default: 'span',
        },
    },
    data() {
        return {
            color: 'black',
        }
    },
    computed: {
        styleWidth() {
            switch (this.type) {
            case 'artist':
                return `${this.randomizeSize(9)}em`
            case 'show':
                return `${this.randomizeSize(10)}em`
            case 'channel':
                return '6em'
            case 'genre':
                return `${this.randomizeSize(10)}em`
            default:
                break
            }
            return Number.isInteger(this.size)
                ? `${this.randomized ? this.randomizeSize(this.size) : this.size}em`
                : this.size
        },
        shouldDisplay() {
            return !this.text || this.text.length === 0
        },
    },
    mounted() {
        this.color = window.getComputedStyle(this.$el).getPropertyValue('color')
    },
    methods: {
        randomizeSize(inputSize, variance = 0.4) {
            return inputSize * (Math.random() * variance + (1 - variance / 2))
        },
    },
}
</script>

<template>
    <component
        :is="tag"
        :class="[$style.container]"
    >
        {{ !shouldDisplay ? text : '' }}
        <span
            v-if="shouldDisplay"
            :class="$style.placeholder"
            :style="{ width: styleWidth, backgroundColor: shouldDisplay ? color : 'none' }"
        >
            <span :class="$style.placeholderAnimation" />
        </span>
    </component>
</template>

<style lang="scss" module>
@import '@design';

@keyframes placeHolderShimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
.placeholder {
    position: relative;
    display: inline-block;
    height: 1em;
    overflow: hidden;
    background: $c-shuttle-gray;
    opacity: 0.3;

    &Animation {
        background: linear-gradient(
            to right,
            transparentize($c-black, 1) 8%,
            $c-shuttle-gray 18%,
            transparentize($c-black, 1) 33%
        );
        background-size: fill;
        animation-name: placeHolderShimmer;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-delay: -1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;

        @include fill;
    }
}
</style>
