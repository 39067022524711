<script>
import Layout from '@layouts/main'
import PlaylistsTableBlock from '@components/playlists/playlists-table-block'
import PlaylistInfo from '@components/playlists/playlist-info'
import DataLayer from '@services/data-layer/index'

export default {
    page() {
        const title = this.currentPlaylist ? this.currentPlaylist.revisions_title : `Playlist`
        const description = `${
            this.currentPlaylist ? this.currentPlaylist.revisions_description : 'Browse this playlist'
        }`
        const image = `${this.currentPlaylist && this.currentPlaylist.revisions_album_art.url}`
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },
                { property: 'og:image', vmid: 'og:image', content: image },
                { property: 'og:type', vmid: 'og:type', content: 'music.playlist' },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
                { property: 'twitter:image', vmid: 'twitter:image', content: image },
            ],
        }
    },
    components: { Layout, PlaylistsTableBlock, PlaylistInfo },
    data() {
        return {
            playlistMixes: Array.from(Array(20), (e, i) => ({
                id: Number.MAX_SAFE_INTEGER - i,
                genre: [],
            })),
            shouldTransition: false,
            maxMixes: 20,
            increaseMixes: 20,
            currentPlaylist: null,
            totalMixes: null,
            shouldLoadMore: false,
            loaded: false,
        }
    },
    computed: {
        id() {
            return this.$route.params.id
        },
        scroll() {
            return this.$store.state.appearance.scroll
        },
        scrollChild() {
            return this.$store.state.appearance.scrollChild
        },
        hasReachedMaxMixes() {
            return this.maxMixes >= Number(this.totalMixes)
        },
        playback() {
            return this.$store.state.playback
        },
        loggedIn() {
            return this.$store.getters['auth/loggedIn']
        },
        currentPosition() {
            return this.$store.state.queue.position
        },
    },
    watch: {
        scroll() {
            if (!this.hasReachedMaxMixes) {
                this.shouldLoadMore = true
                let bottomOfWindow =
                    this.scroll + window.innerHeight >=
                    this.$store.state.appearance.scrollChild.offsetHeight - window.innerHeight / 3
                if (bottomOfWindow && this.shouldLoadMore && this.loaded) {
                    this.shouldLoadMore = false
                    setTimeout(() => {
                        this.shouldLoadMore = true
                    }, 200)
                    this.loadMoreMixes()
                }
            }
        },
    },
    mounted() {
        DataLayer.playlists.getById(this.id).then(res => {
            this.currentPlaylist = res.body
        })
        DataLayer.playlists.getRevisionsMixes(this.id, this.increaseMixes, 0).then(res => {
            this.loaded = true
            this.totalMixes = Number(res.headers['x-items-total'])
            this.playlistMixes = res.body.map(revisionsMixes => revisionsMixes.mixes)
        })
    },
    methods: {
        loadMoreMixes(fillAll = false, play) {
            this.loaded = false
            this.maxMixes += this.increaseMixes
            const increaseMixes = fillAll ? this.totalMixes - this.playlistMixes.length : this.increaseMixes

            return DataLayer.playlists
                .getRevisionsMixes(this.id, increaseMixes, this.playlistMixes.length)
                .then(mixes => {
                    this.loaded = true
                    return this.playlistMixes.push(...mixes.body.map(revisionsMixes => revisionsMixes.mixes))
                })
        },
        playPlaylist(position) {
            if (!this.loggedIn) {
                this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('PLAYER_LOGIN_SIGNUP'),
                    type: 'warning',
                })
                return
            }
            if (this.playlistMixes.length === this.totalMixes) {
                this.$queueService.playMixMultiple({
                    mixes: [...this.playlistMixes],
                    id: this.currentPlaylist.id,
                    position,
                })
            } else {
                this.loadMoreMixes(true).then(mixes => {
                    this.$queueService.playMixMultiple({
                        mixes: [...this.playlistMixes],
                        id: this.currentPlaylist.id,
                        position,
                    })
                })
            }
        },
    },
}
</script>

<template>
    <Layout>
        <BaseContent>
            <BaseSection
                :class="$style.container"
                padded
            >
                <h6>Playlist</h6>
                <div :class="$style.content">
                    <PlaylistInfo
                        :class="$style.details"
                        :playlist-mixes="playlistMixes"
                        :playlist="currentPlaylist"
                        @playPlaylist="playPlaylist(currentPosition !== -1 ? currentPosition : 0)"
                    />
                    <PlaylistsTableBlock
                        :mixes="playlistMixes"
                        :error="{
                            title: `There are no mixes in this playlist yet`,
                        }"
                        :should-transition="shouldTransition"
                        :class="$style.list"
                        playlist-id
                        genres
                        @playPlaylistMix="playPlaylist"
                    />
                </div>
            </BaseSection>
        </BaseContent>
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.container {
    margin-top: $s-h;
}

.content {
    @include widescreen {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: $s-xxxl;
    }
}

.details {
    max-width: 300px;
    margin: 0 auto;
}

.title {
    margin-bottom: $s-xl;
}
</style>
