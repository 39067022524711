<script>
import { donationProducts, paymentServices } from '../../../../configuration'
import analyticsService from '../../../../services/analytics-service'

export default {
    data() {
        return {
            paymentServices,
            donationProducts,
            selectedProduct: donationProducts[1],
            selectedPaymentService: null, // 'stripe' | 'paypal'
        }
    },

    mounted() {
        this.trackDonationInit()
    },

    methods: {
        selectProduct(product) {
            this.trackDonationSelectAmount(product.amount)
            this.selectedProduct = product
        },
        checkoutDonation(paymentService) {
            this.trackDonationSelectCheckout(this.selectedPaymentService?.amount, paymentService)
            if (!this.selectedProduct) return
            this.selectedPaymentService = paymentService
            if(!this.selectedPaymentService) return
            const url = this.selectedProduct[this.selectedPaymentService].url
            window.open(url, '_self')
        },
        trackDonationInit() {
            analyticsService.donationUpsellInitiated()
        },
        trackDonationSelectAmount(amount) {
            analyticsService.donationUpsellAmountSelected({ amount })
        },
        trackDonationSelectCheckout(amount, method) {
            analyticsService.donationUpsellPaymentSelected({ amount, method })
        },
    },
}
</script>

<template>
    <div :class="$style.donationContainer">
        <BaseSection>
            <h3 :class="$style.title">Donate</h3>
            <p>
                We hope our music brings you joy every day. Please consider giving back to support us so we can continue to
                bring you great music!
            </p>
            <p>
                * Note: Donations do not include Premium membership so 100% of your donation goes towards running FRISKY
                service.
            </p>
            <div :class="$style.actions">
                <div :class="$style.amountButtons">
                    <BaseButton
                        v-for="product of donationProducts"
                        :key="'donation-amount' + product.amount"
                        :class="[$style.amountButton, product.amount === selectedProduct.amount ? $style.selected : '']"
                        button-type="blue"
                        @click="selectProduct(product)"
                    >
                        DONATE ${{ product.amount }}
                    </BaseButton>
                </div>
            </div>
        </BaseSection>
        <BaseSection
            v-if="selectedProduct"
            :class="$style.paymentServices"
        >
            <h1 :class="$style.servicesTitle">Select a Donation Method</h1>
            <div :class="$style.services">
                <div
                    v-for="service of paymentServices"
                    :key="'payment-service' + service.alias"
                    :class="[$style.service, service.alias === selectedPaymentService ? $style.selected : '']"
                    :title="service.title"
                    :aria-label="service.title"
                    @click="checkoutDonation(service.alias)"
                >
                    <div :class="$style.serviceDetails">
                        <div>
                            <p :class="$style.serviceTitle">Donate <strong>${{ selectedProduct.amount }}</strong> with {{ service.title }}</p>
                            <div>
                                <p
                                    v-if="service.description"
                                    :class="$style.serviceDescription"
                                >
                                    {{ service.description }}
                                </p>
                            </div>
                        </div>
                        <BaseIcon
                            :class="[$style.icon, $style.iconPayment]"
                            :name="service.icon"
                        />
                    </div>
                </div>
            </div>
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@use '@design' as *;

.donationContainer {
    padding: $s-m $s-m $s-xxxl;
    margin-top: $s-m;
    background-color: $c-kilamanjaro-brown;
}

.title {
    @extend %h4;

    margin: $s-m 0;
    font-weight: 400;
}

.actions {
    margin-top: $s-xxxl;
}

.amountButtons {
    display: flex;
    flex-direction: column;
    gap: $s-xl;
    align-items: center;
    justify-content: center;
}

.amountButton {
    margin: 0;

    &.selected {
        background-color: $c-frisky-channel-grad;
    }
}

.servicesTitle {
    @extend %h6;
}

.services {
    display: flex;
    flex-direction: column;
}

.serviceDetails {
    display: flex;
    gap: $s-m;
    align-items: center;
    justify-content: space-between;
    text-align: left;
}

.serviceTitle {
    margin: 0;
    font-weight: normal;

    @extend %contentTitleM;
}

.serviceDescription {
    margin: 0;

    @extend %contentSubtitleM;
}

.service {
    position: relative;
    width: 500px;
    max-width: 100%;
    padding: $s-m $s-xxl;
    cursor: pointer;
    background: $c-black-grey-gradient;
    border-radius: $button-border-radius;

    + .service {
        margin-top: $s-m;
    }

    &.selected {
        .serviceTitle {
            font-weight: 900;
        }
    }
}

.serviceButton {
    margin-top: $s-xxl;
}

.icon {
    &Payment {
        flex-shrink: 0;
        width: 35px;
        height: 35px;
        fill: $c-white;
    }
}

@include tablet {
    .amountButtons {
        flex-direction: row;
        gap: $s-m;
        justify-content: flex-start;
    }
}

</style>
