<script>
import localSave from '@utils/local-save'

export default {
    data() {
        return {
            timeout: null,
            show: false,
            activeDuration: 8000, // ms
            animationDuration: 500, // ms
            countdownDuration: 60, // secs
        }
    },
    computed: {
        notificationType() {
            return this.$store.state.appearance.notificationType
        },
        hasReloadButton() {
            return this.$store.state.appearance.hasReload
        },
        isPersistentNotification() {
            return this.$store.state.appearance.isPersistentNotification
        },
        forceUpdate() {
            return this.$store.state.appearance.forceUpdate
        },
    },

    watch: {
        countdownDuration() {
            this.countdownDuration === 0 && this.reloadPage()
        },
    },
    mounted() {
        setTimeout(() => {
            this.show = true
        }, 0)
        this.timeout = setTimeout(() => {
            !this.isPersistentNotification && this.close()
        }, this.activeDuration)
        if (this.forceUpdate) {
            setInterval(() => {
                this.countdownDuration--
            }, 1000)
        }
    },
    beforeDestroy() {
        clearTimeout(this.timeout)
    },
    methods: {
        close() {
            this.show = false
            setTimeout(() => {
                this.$store.dispatch('appearance/closeNotification')
            }, this.animationDuration)
        },
        reloadPage() {
            localSave.save('localCodeVersion', localSave.get('actualCodeVersion'))
            window.location.reload(true)
        },
    },
}
</script>

<template>
    <div>
        <div
            :class="[
                'notification',
                $style.container,
                notificationType && $style[notificationType],
                show && $style.active,
            ]"
        >
            <div :class="['main', $style.main]">
                <span
                    :class="$style.message"
                    v-html="$store.state.appearance.notificationMessage"
                />
                <span
                    v-if="forceUpdate"
                    :class="$style.countdown"
                >&nbsp;{{ countdownDuration }}s</span>
                <div
                    v-if="hasReloadButton"
                    :class="$style.reloadButton"
                >
                    <BaseButton
                        :modifiers="{ noMargins: true }"
                        button-type="white"
                        @click="reloadPage()"
                    >Reload</BaseButton>
                </div>
            </div>
            <div
                :class="[$style.iconContainer]"
                @click="close()"
            >
                <BaseIcon
                    :class="[$style.icon]"
                    name="close"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import '@design';

.notification .main a {
    font-size: 17px;
    font-weight: bold;
    color: $c-white;
}
</style>

<style lang="scss" module>
@import '@design';

.container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $layer-message-z-index;
    display: flex;
    width: 100%;
    min-height: 60px;
    color: $c-white;
    background-color: $c-black;
    border: 1px solid $c-shark;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    transform: translateY(-100%);

    &.active {
        opacity: 1;
        transform: translateY(0);
    }

    @extend %contentSubtitleM;

    &.warning {
        background: $c-message-warning;
    }
    &.danger {
        background: $c-message-danger;
    }
    &.success {
        background: $c-message-success;
    }
}

.main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: $s-xs;
}

.message {
    font-size: 15px;
}

.icon {
    position: absolute;
    right: $s-xxl;
    display: none;
    &Container {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    @include square(30px);

    fill: $c-white;
}

.reloadButton {
    margin-left: $s-xl;
}

.countdown {
    font-size: 18px;
    font-weight: bold;
}

@include desktop {
    .icon {
        display: inline-block;
    }
}
</style>
