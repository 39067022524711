<script>
import PlaylistOptions from '@components/playlists/playlist-options'

export default {
    components: { PlaylistOptions },
    props: {
        playlist: {
            type: Object,
            default: () => {},
        },
        type: {
            type: String,
            default: 'playlists',
        },
    },
}
</script>

<template>
    <div :class="$style.container">
        <div :class="$style.imageContainer">
            <BaseLink
                :class="$style.linkContainer"
                :to="{ name: type === 'charts' ? 'browse/charts' : 'browse/playlists', params: { id: playlist.id } }"
            >
                <BaseResourceCover
                    :image-resource="playlist"
                    :class="$style.image"
                    :media-resource="playlist"
                    image-type="revisions_album_art"
                    media-type="playlist"
                >
                    <div slot="secondaryActions">
                        <PlaylistOptions :playlist="playlist" />
                    </div>
                </BaseResourceCover>
            </BaseLink>
        </div>
        <BaseLink
            v-if="playlist && playlist.revisions_title"
            :class="['title', $style.title]"
            :to="{ name: type === 'charts' ? 'browse/charts' : 'browse/playlists', params: { id: playlist.id } }"
        >
            <div :class="$style.title">
                {{ playlist.revisions_title }}
            </div>
        </BaseLink>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.title {
    @extend %contentTitleM;

    margin-top: $size-grid-padding;
    line-height: 24px;
    text-align: center;
}

.artist {
    @extend %contentSubtitleM;

    text-align: center;
}

.dropdown {
    &Menu {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    &Item {
        display: flex;
        align-items: center;
        height: 44px;
        padding: 0 $s-m;
    }
}

.separator {
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
    background: $c-mine-shaft;
}
</style>
