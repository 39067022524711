<script>
import ArtistOptions from '@components/artist/artist-options'
export default {
    components: {
        ArtistOptions,
    },
    props: {
        artist: {
            type: Object,
            default: () => {},
        },
        extended: {
            // Centered on mobiles, expanded width on desktops
            type: Boolean,
            default: false,
        },
        hasTypeTitle: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<template>
    <div :class="[$style.main, extended && $style.mainExtended]">
        <BaseLink
            :class="$style.link"
            :to="{ path: `/artist/${artist.url}/${artist.id}` }"
        >
            <div :class="[$style.artistTileContainer, extended && $style.artistTileContainerExtended]">
                <div :class="$style.inner">
                    <BaseResourceImage
                        :resource="artist"
                        :class="$style.image"
                        :width="150"
                        :height="150"
                        type="photo"
                    />
                    <div
                        :class="$style.options"
                        @click.prevent="() => {}"
                    >
                        <BaseDropdown :padded="false">
                            <BaseButton
                                slot="trigger"
                                :modifiers="{ autoWidth: true, transparent: true, dots: true }"
                                aria-label="Options"
                                button-type="tag"
                                size="small"
                            />
                            <div slot="dropdown">
                                <ArtistOptions :artist="artist" />
                            </div>
                        </BaseDropdown>
                    </div>
                </div>
                <BasePlaceholder
                    :class="$style.title"
                    :text="artist.title"
                    type="artist"
                    tag="div"
                />
                <div
                    v-if="hasTypeTitle"
                    :class="$style.typeTitle"
                >Artist</div>
            </div>
        </BaseLink>
    </div>
</template>

<style lang="scss" module>
@use 'sass:math';
@use '@design' as *;

.title {
    grid-area: title;
    margin-top: $size-grid-padding;

    @extend %contentTitleM;

    @include desktop {
        margin-top: $size-large-grid-padding;
    }
}

.link {
    display: block;
}

.inner {
    position: relative;
}

.image {
    position: relative;
    grid-area: image;
    overflow: hidden;
    background: $c-classics-channel-grad;
    border-radius: 50%;

    @include desktop {
        width: 100%;
    }

    &::after {
        @include fill;

        content: '';
        background: $c-purple-red-gradient;
        border-radius: 50%;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
}

.options {
    @include center;

    z-index: $layer-dropdown-z-index;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.main {
    max-width: none;
    padding: $size-grid-padding;
    margin: 0 0 $s-xl;
    text-align: center;

    @include square(160px);

    @include tablet {
        @include square(math.div(100%, 4));
    }

    @include desktop {
        position: relative;
        max-width: none;
        padding: $size-grid-padding;
        margin: 0;

        @include square(math.div(100%, 6));
    }

    &Extended {
        margin: 0 auto;

        @include desktop {
            width: 33%;
            margin: unset;
        }
    }

    &:hover {
        .image::after {
            opacity: 0.5;
        }
        .options {
            pointer-events: visible;
            opacity: 1;
        }
    }
}

.typeTitle {
    @extend %contentTitleM;

    grid-area: typeTitle;
    margin-top: $s-s;
    text-align: center;
}

.artistTileContainer {
    &Extended {
        @include desktop {
            display: grid;
            grid-template-areas: 'image title' 'typeTitle null';
            grid-template-columns: 50%;
            grid-column-gap: $s-m;
            align-items: center;
            margin: unset;
            text-align: left;
        }
        .title {
            @include h6;

            @include desktop {
                @include h5;
            }
        }
    }
}
.dropdown {
    &Menu {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    &Item {
        display: flex;
        align-items: center;
        height: 44px;
        padding: 0 $s-m;
    }
}

.separator {
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
    background: $c-mine-shaft;
}
</style>
