<script>
import ArtistBlock from '@components/artist/artist-block'

export default {
    components: { ArtistBlock },
    props: {
        searchResults: {
            type: Object,
            default: () => {},
        },
        searchJoin: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            INITIAL_RESOURCES: 30,
            visibleResources: 30,
            shouldLoadMore: true,
        }
    },
    computed: {
        artists() {
            return this.searchResults.Artists
        },
        resourcesRemain() {
            return this.shows.length % this.INITIAL_RESOURCES === 0
        },
        scroll() {
            return this.$store.state.appearance.scroll
        },
        scrollChild() {
            return this.$store.state.appearance.scrollChild
        },
        keyword() {
            return this.$store.state.search.keyword.replace(/ /g, '_')
        },
    },
    watch: {
        scroll() {
            if (this.artists.length < this.visibleResources) {
                return false
            }

            let bottomOfWindow =
                this.scroll + window.innerHeight >=
                this.$store.state.appearance.scrollChild.offsetHeight - window.innerHeight / 3
            if (bottomOfWindow && this.shouldLoadMore) {
                this.shouldLoadMore = false
                setTimeout(() => {
                    this.shouldLoadMore = true
                }, 200)
                this.showMoreResults()
            }
        },
    },
    mounted() {
        this.visibleResources = this.artists.length
    },
    methods: {
        elasticSearch(limit, offset) {
            this.searchJoin(limit, offset, 'Artists').then(res => {
                this.visibleResources = res
            })
        },
        showMoreResults() {
            this.elasticSearch(this.INITIAL_RESOURCES, this.visibleResources)
        },
    },
}
</script>

<template>
    <div>
        <BaseSection
            v-if="artists"
            padded
        >
            <ArtistBlock :artists="artists" />
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
