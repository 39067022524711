var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:[
            _vm.$style.container,
            (_vm.isMixLoaded || _vm.isPlaylistLoaded) && _vm.playbackStatus && _vm.$style.containerPlaying,
            { [_vm.$style.hover]: _vm.hasHoverState },
            { [_vm.$style.containerFill]: _vm.fill },
            { [_vm.$style.containerCentered]: _vm.centered },
        ]},[((_vm.playableMix && _vm.playableMix.title) || _vm.playlistId)?_c('div',{class:_vm.$style.iconContainer,on:{"click":_vm.playMix}},[((_vm.isMixLoaded || _vm.isPlaylistLoaded) && _vm.playbackStatus)?_c('BaseIcon',{class:_vm.$style.iconPlay,attrs:{"name":"pause"}}):_c('BaseIcon',{class:_vm.$style.iconPlay,attrs:{"name":"play_arrow"}}),_c('svg',{class:_vm.$style.iconCircle,attrs:{"viewBox":"0 0 50 50","xmlns":"http://www.w3.org/2000/svg","version":"1.1"}},[_c('circle',{attrs:{"cx":"25","cy":"25","r":"23","stroke":"white","stroke-width":"3px","fill":"none"}})])],1):_vm._e(),_c('div',{directives:[{name:"lazyload",rawName:"v-lazyload",value:(_vm.shouldLazyload),expression:"shouldLazyload"}],class:[_vm.$style.imageContainer, { lazy: !_vm.isBackground && _vm.shouldLazyload }]},[(_vm.hasArt && !_vm.isBackground)?_c('img',{class:[
                    _vm.$style.image,
                    { [_vm.$style.imageFill]: _vm.fill },
                    { [_vm.$style.imageCentered]: _vm.centered },
                    'realImage',
                ],attrs:{"width":_vm.width,"height":_vm.height,"data-src":_vm.resource[_vm.type].url,"src":!_vm.shouldLazyload ? _vm.resource[_vm.type].url : '',"alt":`${_vm.resource.title || _vm.resource.heading} art`}}):_vm._e()]),(_vm.hasArt && _vm.isBackground)?_c('div',{class:[_vm.$style.image, _vm.$style.imageBackground],style:({ backgroundImage: `url(${_vm.resource[_vm.type].url})` })}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }