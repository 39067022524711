<script>
import { mapActions } from 'vuex'
import months from './../../../data/enums/months'
import VueRecaptcha from 'vue-recaptcha'
import { validationMixin } from 'vuelidate'
import { required, between, sameAs, email } from 'vuelidate/lib/validators'
import Layout from './../../layouts/login'
import SocialLogin from './social-login'

export default {
    name: 'SignUp',
    page() {
        const title = 'Sign Up'
        const description = 'Sign Up'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    components: { VueRecaptcha, Layout, SocialLogin },
    mixins: [validationMixin],
    props: {
        redirect: {
            type: [String, Boolean],
            required: false,
            default: false,
        },
    },
    validations: {
        form: {
            email: {
                required,
                email,
            },
            password: {
                required,
            },
        },
        confirmEmail: {
            required,
            sameAsEmail: sameAs(function () {
                return this.form.email
            }),
            email,
        },
        confirmPassword: {
            required,
            sameAsPassword: sameAs(function () {
                return this.form.password
            }),
        },
        month: {
            between: between(1, 12),
        },
        day: {
            between: between(1, 31),
        },
        year: {
            between: between(1900, new Date().getFullYear()),
        },
    },
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            confirmEmail: '',
            confirmPassword: '',
            month: null,
            day: null,
            year: null,
            gender: '',
            tryingToSignUp: false,
            months,
            currentYear: new Date().getFullYear(),
            startYear: 1900,
            recaptchaKey: '',
            socialBaseUrl: `${process.env.VUE_APP_API_BASE_URL}v3/auth/oauth/`,
        }
    },
    computed: {
        siteKey() {
            return process.env.VUE_APP_RECAPTCHA_SITE_KEY || ''
        },
        birthday() {
            if (this.year && this.day && this.month) {
                const timezoneOffset = new Date().getTimezoneOffset()
                return new Date(this.year, this.month, this.day, 0, -timezoneOffset).toJSON()
            } else {
                return null
            }
        },
        years() {
            const years = []
            for (let i = this.startYear; i <= this.currentYear; i++) {
                years.push(i)
            }
            return years
        },
    },
    methods: {
        ...mapActions('auth', ['logIn', 'logOut']),
        signUp() {
            if (this.$v.$invalid) {
                this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('EMAIL_PASSWORD_REQUIRED'),
                    type: 'warning',
                })
                return
            }
            if (!this.recaptchaKey) {
                this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('RECAPTCHA_MISSING_ERROR'),
                    type: 'warning',
                })
                return
            }
            this.tryingToSignUp = true
            const form = { ...this.form }
            if (this.birthday) {
                form.birthday = this.birthday
            }
            if (this.gender) {
                form.gender = this.gender
            }
            form.username = form.email
            return this.$dataLayer.api
                .signup({ data: form })
                .then(res => this._successHandler(res))
                .catch(error => this._errorHandler(error))
        },
        setRecaptcha(val) {
            this.recaptchaKey = val || ''
        },
        async _successHandler(res) {
            const token = res.headers['x-auth-token']

            if (token) {
                const user = {...res.data, token}
                this.$store.dispatch('auth/setUser', {...user, members_id: { id: user.id }})
            }

            this._redirectHandler()
        },
        _errorHandler(error) {
            this.tryingToSignUp = false
            if (error.response.status === 409) {
                const networkUsed = error.response.headers['x-network-used']
                if (networkUsed === 'none') {
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m('USER_ALREADY_EXISTS'),
                        type: 'warning',
                    })
                } else {
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m('USER_ALREADY_EXISTS_SOCIAL', { '{social}': networkUsed }),
                        type: 'warning',
                    })
                }
            } else {
                this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('SOMETHING_WENT_WRONG'),
                    type: 'danger',
                })
            }
        },
        _redirectHandler() {
            const redirectURL = '/premium'
            window.location.replace(redirectURL)
        },
    },
}
</script>

<template>
    <Layout>
        <BaseContent>
            <BaseSection padded>
                <div :class="$style.formContainer">
                    <h4 :class="$style.title">Sign up</h4>
                    <SocialLogin :is-sign-up="true" />
                    <div :class="$style.lineSeparator">OR</div>
                    <h5 :class="$style.subtitle">Sign up with your email address:</h5>
                    <form
                        :class="$style.form"
                        autocomplete="off | unknown-autocomplete-value"
                        @submit.prevent="signUp"
                    >
                        <div :class="$style.fieldContainer">
                            <BaseInput
                                v-model.lazy="form.email"
                                :class="[$style.field, $v.form.email.$error && $style.error]"
                                placeholder="email*"
                                @blur.native="$v.form.email.$touch()"
                            />
                            <label
                                v-if="$v.form.email.$error"
                                :class="[$style.label, $style.error]"
                            >This field is required and must be an email</label>
                        </div>
                        <div :class="$style.fieldContainer">
                            <BaseInput
                                v-model="confirmEmail"
                                :class="[$style.field, $v.confirmEmail.$error && $style.error]"
                                placeholder="confirm email*"
                                @blur.native="$v.confirmEmail.$touch()"
                            />
                            <label
                                v-if="$v.confirmEmail.$error"
                                :class="[$style.label, $style.error]"
                            >This field is required and must match the email above</label>
                        </div>
                        <div :class="$style.fieldContainer">
                            <BaseInput
                                v-model="form.password"
                                :class="[$style.field, $v.form.password.$error && $style.error]"
                                type="password"
                                placeholder="password*"
                                @blur.native="$v.form.password.$touch()"
                            />
                            <label
                                v-if="$v.form.password.$error"
                                :class="[$style.label, $style.error]"
                            >This field is required</label>
                        </div>
                        <div :class="$style.fieldContainer">
                            <BaseInput
                                v-model="confirmPassword"
                                :class="[$style.field, $v.confirmPassword.$error && $style.error]"
                                type="password"
                                placeholder="confirm password*"
                                @blur.native="$v.confirmPassword.$touch()"
                            />
                            <label
                                v-if="$v.confirmPassword.$error"
                                :class="[$style.label, $style.error]"
                            >This field is required and must match password above</label>
                        </div>
                        <div :class="$style.genderSelection">
                            <div :class="$style.gender">
                                <input
                                    v-model="gender"
                                    :class="$style.radio"
                                    type="radio"
                                    name="gender"
                                    value="MALE"
                                >
                                <label
                                    :class="[$style.genderLabel, $style.label]"
                                    for="male"
                                >Male</label>
                            </div>
                            <div :class="$style.gender">
                                <input
                                    v-model="gender"
                                    :class="$style.radio"
                                    type="radio"
                                    name="gender"
                                    value="FEMALE"
                                >
                                <label
                                    :class="[$style.genderLabel, $style.label]"
                                    for="female"
                                >Female</label>
                            </div>
                        </div>
                        <div :class="$style.birthday">
                            <label :class="[$style.label, $style.birthdayLabel]">Date of birth</label>
                            <div :class="$style.birthdayContainer">
                                <BaseSelect v-model="day">
                                    <option
                                        :class="$style.option"
                                        :value="null"
                                        disabled
                                        selected
                                    >Day</option>
                                    <option
                                        v-for="d in 31"
                                        :key="d + 'day'"
                                        :value="d"
                                        :class="$style.option"
                                    >
                                        {{ d }}
                                    </option>
                                </BaseSelect>
                                <BaseSelect v-model="month">
                                    <option
                                        :class="$style.option"
                                        :value="null"
                                        disabled
                                        selected
                                    >Month</option>
                                    <option
                                        v-for="monthItem in months"
                                        :key="monthItem.title + monthItem.value"
                                        :value="monthItem.value"
                                        :class="$style.option"
                                    >
                                        {{ monthItem.title }}
                                    </option>
                                </BaseSelect>
                                <BaseSelect v-model="year">
                                    <option
                                        :class="$style.option"
                                        :value="null"
                                        disabled
                                        selected
                                    >Year</option>
                                    <option
                                        v-for="y in years"
                                        :key="y + 'day'"
                                        :value="y"
                                        :class="$style.option"
                                    >
                                        {{ y }}
                                    </option>
                                </BaseSelect>
                            </div>
                        </div>
                        <div :class="$style.recaptchaContainer">
                            <VueRecaptcha
                                :load-recaptcha-script="true"
                                :sitekey="siteKey"
                                theme="dark"
                                @verify="setRecaptcha"
                                @expired="setRecaptcha"
                            />
                        </div>
                        <div :class="$style.agreement">
                            By signing up you agree to our
                            <a
                                :class="$style.link"
                                href="https://www.friskyradio.com/about/tos"
                                target="_blank"
                            >Terms and Conditions</a>
                            &
                            <a
                                :class="$style.link"
                                href="https://www.friskyradio.com/about/privacy"
                                target="_blank"
                            >Privacy Policy</a>.
                        </div>
                        <BaseButton
                            :class="$style.button"
                            :disabled="tryingToSignUp"
                            :modifiers="{ fill: true }"
                            button-type="highlight"
                            type="submit"
                            @click="() => {}"
                        >
                            Sign Up
                        </BaseButton>
                        <div :class="$style.additionalOptions">
                            <span :class="$style.additionalText">Already have an account?</span>
                            <BaseLink :to="{ name: 'login' }">
                                <BaseButton
                                    button-type="tagBlack"
                                    @click="() => {}"
                                >LOG IN</BaseButton>
                            </BaseLink>
                        </div>
                    </form>
                </div>
            </BaseSection>
        </BaseContent>
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.formContainer {
    position: relative;
    margin-bottom: $s-h;
    .field {
        &.error {
            margin-bottom: $s-xl;
            border-color: $c-punch-red;
            border-width: 2px;
        }
    }
}
.fieldContainer {
    position: relative;
    width: 100%;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.label {
    @extend %contentSubtitleM;

    display: block;
    font-weight: 300;
    text-align: left;

    &.error {
        position: absolute;
        bottom: $s-xs;
        color: $c-punch-red;
    }
}
.title {
    text-align: left;
}
.subtitle {
    @extend %contentTinyHeading;

    font-weight: 400;
    text-align: left;
}

.lineSeparator {
    @include lineSeparator(true, transparentize($c-tuna, 0.5));

    @extend %contentSubtitleS;

    font-weight: 100;
}
.birthday {
    width: 100%;
}
.birthdayContainer {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    grid-gap: $s-m;
    width: 100%;
    margin-bottom: $s-m;
    .day {
        margin-bottom: 0;
    }
    .year {
        margin-bottom: 0;
    }
}

.birthdayLabel {
    margin-bottom: $s-xxs;
}

.genderSelection {
    display: flex;
    width: 100%;
    margin: $s-m 0;
    text-align: left;
}
.gender {
    display: flex;
    align-items: center;
}

.radio {
    @extend %radio;
}

.gender ~ .gender {
    margin-left: $s-m;
}
.genderLabel {
    margin-left: $s-s;
}

.recaptchaContainer {
    margin-top: $s-l;
}

.button {
    margin-top: $s-xl;
}

.agreement {
    @extend %contentSubtitleS;

    margin-top: $s-l;
}

a.link,
a.link:visited {
    color: $c-torch-red;
}

.additional {
    &Options {
        margin-top: $s-h;
    }
    &Text {
        @extend %contentSubtitleM;
    }
}

@include desktop {
    .gender ~ .gender {
        margin-left: $s-h;
    }
    .additionalText {
        margin-right: $s-xl;
    }
}
</style>
