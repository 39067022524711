<script>
import { mapGetters } from 'vuex'
import analyticsService from '../services/analytics-service'

export default {
    data() {
        return {
            isPanelActive: false,
        }
    },
    computed: {
        ...mapGetters('auth', ['loggedIn']),
        member() {
            return { ...this.$store.state.member.currentMember } || {}
        },
        isPremium() {
            return this.$store.getters['premium/isPremium']
        },
        entitledFreeTrial() {
            return !this.$store.getters['premium/hasEverBeenPremium']
        },
    },
    methods: {
        setPanelInfo(isPanelActive) {
            this.isPanelActive = isPanelActive
        },
        onPremiumUpsellClick() {
            analyticsService.premiumUpsellInitiated('top-bar')
        },
    },
}
</script>

<template>
    <div v-if="$route.name !== 'login'">
        <div v-if="!loggedIn">
            <slot />
            <BaseSection padded>
                <BaseLink name="signup">
                    <BaseButton
                        :modifiers="{ noMargins: true }"
                        button-type="transparentWhite"
                        @click="() => {}"
                    >Sign Up</BaseButton>
                </BaseLink>
                <BaseLink
                    :class="$style.loginButton"
                    name="login"
                >
                    <BaseButton
                        :modifiers="{ noMargins: true }"
                        button-type="tagBlack"
                        @click="() => {}"
                    >Log in</BaseButton>
                </BaseLink>
            </BaseSection>
        </div>
        <div v-else>
            <div :class="$style.userDetails">
                <BaseLink
                    v-if="!isPremium"
                    name="premium/view-plans"
                    :class="$style.premiumLink"
                >
                    <BaseButton
                        button-type="highlight"
                        size="xsmall"
                        :modifiers="{ fill: true, noMargins: true }"
                        @click="onPremiumUpsellClick"
                    >{{ entitledFreeTrial ? 'START FREE PREMIUM TRIAL' : 'UPGRADE TO PREMIUM' }}</BaseButton>
                </BaseLink>
                <div :class="$style.iconContainer">
                    <BaseResourceImage
                        v-if="member.avatar"
                        :class="$style.image"
                        :should-lazyload="false"
                        :resource="member"
                        :fill="false"
                        :centered="true"
                        type="avatar"
                    />
                    <BaseIcon
                        v-else
                        :class="[$style.icon, $style.iconUser]"
                        name="user"
                    />
                </div>
                <div :class="$style.username">{{ member.username || member.firstName || member.email }}</div>
                <BaseDropdown
                    :class="$style.button"
                    :padded="false"
                    @is-panel-active="setPanelInfo"
                >
                    <div slot="trigger">
                        <BaseIcon
                            :class="[$style.icon, $style.iconDropdown, isPanelActive && $style.active]"
                            name="arrow_drop_down"
                        />
                    </div>
                    <div
                        slot="dropdown"
                        :class="$style.dropdown"
                    >
                        <ul :class="$style.dropdownMenu">
                            <li :class="$style.dropdownItem">
                                <BaseLink
                                    :class="$style.link"
                                    name="settings"
                                >Settings</BaseLink>
                            </li>
                            <li :class="$style.dropdownItem">
                                <BaseLink
                                    :class="$style.link"
                                    name="account/overview"
                                    target="_blank"
                                >Account Management</BaseLink>
                            </li>
                            <div :class="$style.separator" />
                            <li :class="$style.dropdownItem">
                                <BaseLink
                                    :class="$style.link"
                                    name="logout"
                                >Log out</BaseLink>
                            </li>
                        </ul>
                    </div>
                </BaseDropdown>
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

$dropdown-item-height: 44px;
$dropdown-color: $c-dusty-gray;
$avatar-size: 30px;

.userDetails {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 -5px;
}

.image {
    width: $avatar-size;
    height: $avatar-size;
    overflow: hidden;
    border-radius: 50%;
}

.icon {
    &Container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $avatar-size;
        height: $avatar-size;
        overflow: hidden;
        text-align: center;
        border-radius: 50%;
    }
    &User {
        position: relative;
        display: block;
        width: $avatar-size;
        height: $avatar-size;
        padding: 5px;
        background: $c-white;
        fill: $c-black;
    }
    &Dropdown {
        width: 34px;
        height: 34px;
        fill: $c-white;
        &.active {
            transform: rotate(180deg);
        }
    }
}

.loginButton {
    margin-left: $s-m;
}

.dropdown {
    text-align: center;
    &Menu {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    &Item {
        width: 100%;
        height: $dropdown-item-height;
    }
}

.link {
    @extend %contentSubtitleL;

    position: relative;
    display: block;
    line-height: $dropdown-item-height;
    color: $dropdown-color;

    &::before {
        @include fill;

        z-index: $layer-negative-z-index;
        content: '';
        background: $c-mine-shaft;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }

    &:hover,
    &:active {
        text-decoration: none;
    }

    &:visited {
        color: $dropdown-color;
    }
}

.username {
    @extend %contentSubtitleL;

    margin: 0 5px;
    text-transform: uppercase;
}

.separator {
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
    background: $c-mine-shaft;
}

.premiumLink {
    margin-right: $s-m;

    &:hover {
        text-decoration: none;
    }
}
</style>
