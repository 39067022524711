<script>
import DataLayer from '@services/data-layer/index'
import MixGridBlock from '@components/mix-grid-block'

export default {
    components: { MixGridBlock },
    data() {
        return {
            mixes: [],
        }
    },
    computed: {
        recentlyPlayedMixes() {
            return (
                this.$store.state.playback &&
                this.$store.state.playback.history &&
                this.$store.state.playback.history
                    .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
                    .slice(0, 6)
            )
        },
    },
    watch: {
        recentlyPlayedMixes: {
            handler() {
                this.getMixes()
            },
        },
    },
    mounted() {
        this.getMixes()
    },
    methods: {
        getMixes() {
            const promises =
                this.recentlyPlayedMixes &&
                this.recentlyPlayedMixes.length &&
                this.recentlyPlayedMixes.map(id => DataLayer.mixes.getById(id))
            promises &&
                promises.length &&
                Promise.all(promises).then(values => {
                    let mixes = []
                    values.map((res, i) => mixes.push(res.body))
                    this.mixes = mixes
                })
        },
    },
}
</script>

<template>
    <div
        v-if="mixes && mixes.length"
        :class="$style.section"
    >
        <BaseSection
            :show-all-link="{ name: 'browse/mixes/recently' }"
            title="Recently Played"
            show-all-title="Browse All"
            padded
        >
            <MixGridBlock :mixes="mixes" />
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.section {
    margin-top: $s-xxl;
}
</style>
