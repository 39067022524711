<script>
import DataLayer from '@services/data-layer/index'
import PlaylistsGridBlock from '@components/playlists/playlists-grid-block'

export default {
    components: { PlaylistsGridBlock },
    data() {
        return {
            featuredCharts: [],
        }
    },
    mounted() {
        DataLayer.playlists.getByType({ type: 'chart', offset: 0, limit: 6, featued: 1 }).then(charts => {
            const featured = charts.body.filter(playlist => playlist.revisions_id !== null)
            this.featuredCharts = featured
        })
    },
}
</script>

<template>
    <div
        v-if="featuredCharts && featuredCharts.length"
        :class="$style.section"
    >
        <BaseSection
            :show-all-link="{ name: 'browse/charts/index' }"
            title="Featured Charts"
            show-all-title="Browse All"
            padded
        >
            <PlaylistsGridBlock
                :playlists="featuredCharts"
                type="charts"
                min-tile-width="160px"
            />
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.section {
    margin-top: $s-xxl;
}
</style>
