<script>
import Logo from '@components/logo'
export default {
    components: { Logo },

    computed: {
        isMenuOpen() {
            return this.$store.state.appearance.isMainMenuOpen
        }
    },

    methods: {
        toggleMenu() {
            this.$store.dispatch('appearance/toggleMainMenu')
        },
        closeMainMenu() {
            this.$store.dispatch('appearance/closeMainMenu')
        }
    }
}
</script>

<template>
    <div :class="$style.menuBar">
        <div
            v-click-outside="closeMainMenu"
            :class="$style.menuButton"
            @click="toggleMenu"
        >
            <span :class="[$style.buttonLine, isMenuOpen ? $style.active : '']" />
        </div>

        <Logo :class="$style.logo" />
        <BaseLink
            :class="[$style.title, $style.baseLink]"
            :to="{ name: 'search' }"
            aria-label="Go to Search Page"
        >
            <BaseIcon
                :class="$style.searchIcon"
                name="search"
            />
        </BaseLink>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.menuBar {
    position: absolute;
    z-index: $layer-dropdown-z-index;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: $size-menu-bar-height;
    padding: gutter();
    text-align: center;
}

.buttonLine {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 2px;
    vertical-align: middle;
    background: $c-razzmatazz;

    &::before {
        position: absolute;
        top: -5px;
        left: 0;
        width: 16px;
        height: 2px;
        content: '';
        background: $c-razzmatazz;
    }

    &::after {
        position: absolute;
        top: 5px;
        left: 0;
        width: 16px;
        height: 2px;
        content: '';
        background: $c-razzmatazz;
    }

    &.active {
        background: transparent;

        &::before {
            top: 0;
            transform: rotate(45deg);
        }

        &::after {
            top: 0;
            transform: rotate(-45deg);
        }
    }
}

.title {
    margin: 0;
    font-size: 16px;
    font-weight: 900;
    line-height: $size-menu-bar-height - gutter() * 2;
    color: $c-white;
    vertical-align: middle;
}

.searchIcon {
    position: absolute;
    top: 50%;
    right: 30px;
    width: 20px;
    height: 20px;
    box-shadow: 0 12px 24px 0 rgba(16, 17, 24, 0.27);
    fill: $c-grey-abbey;
    transform: translateY(-50%);
}

.logo {
    margin-bottom: 0;
}

@media (min-width: $desktop) {
    .menuBar {
        display: none;
    }
}
</style>
