<script>
import { mapGetters } from 'vuex'
import SideBar from './../../partials/side-bar'
import MusicPlayer from './../../partials/music-player'
import MenuBar from './../../partials/menu-bar'
import BackArrow from './../../components/back-arrow'
import TopBar from './../../components/top-bar'
import SignupPromo from './../../components/signup-promo'

export default {
    components: { SideBar, MusicPlayer, MenuBar, BackArrow, TopBar, SignupPromo },
    props: {
        hideTopBar: {
            type: Boolean,
            default: false,
        },
        hiddenOverflow: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('auth', ['loggedIn']),
        scrollTop() {
            return this.$store.state.appearance.scroll
        },
        backgroundOpacity() {
            if (!this.scrollTop) return 0
            return (this.scrollTop < 90 && this.scrollTop / 100) || 0.9
        },
        isMenuOpen() {
            return this.$store.state.appearance.isMainMenuOpen
        },
    },
    mounted() {
        this.$store.commit('appearance/SET_SCROLL_CHILD', this.$refs.container)
        window.addEventListener('scroll', this.handleWindowScroll, { passive: true })
        this.$refs.main.addEventListener('scroll', this.handleMainScroll, { passive: true })
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleWindowScroll)
        this.$refs.main.removeEventListener('scroll', this.handleMainScroll)
    },
    methods: {
        handleWindowScroll() {
            this.$store.commit('appearance/SET_SCROLL', window.pageYOffset)
        },
        handleMainScroll() {
            this.$store.commit('appearance/SET_SCROLL', this.$refs.main.scrollTop)
        },
    },
}
</script>

<template>
    <div>
        <MenuBar
            v-if="!hideTopBar"
            class="menu-bar"
        />
        <div :class="['wrapper', { 'stop-scroll': isMenuOpen }]">
            <SideBar />
            <div
                ref="main"
                :class="['main', { 'hide-top': hideTopBar }]"
            >
                <div
                    v-if="!hideTopBar"
                    class="top-bar"
                >
                    <BaseSection padded>
                        <div class="top-bar-inner">
                            <BackArrow :class="['arrow', { 'fixed' : !loggedIn }]" />
                            <div :class="['user-login', {'fixed' : !loggedIn }]">
                                <TopBar class="user-login-inner">
                                    <div
                                        class="background"
                                        :style="`opacity: ${backgroundOpacity}`"
                                    />
                                </TopBar>
                            </div>
                        </div>
                    </BaseSection>
                </div>
                <div
                    ref="container"
                    :class="['container', { 'hidden-overflow' : hiddenOverflow }]"
                >
                    <slot />
                </div>
            </div>
        </div>
        <SignupPromo v-if="!loggedIn" />
        <MusicPlayer />
    </div>
</template>

<style lang="scss" scoped>
@use '@design' as *;

.menu-bar {
    position: sticky;
    top: 0;
    background: $c-black;
}

.wrapper {
    &.stop-scroll {
        overflow: hidden;
    }
}

.main {
    position: relative;
    padding-top: $size-menu-bar-height;
    overflow: hidden;

    &.hide-top {
        padding-top: 0;
    }
}

.top-bar {
    position: absolute;
    z-index: $layer-dropdown-z-index;
    display: none;
    width: 100%;
    margin-top: gutter();
}

.top-bar-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
}

.user-login {
    display: none;
    text-align: right;

    &.fixed {
        position: fixed;
        top: gutter();
        left: $size-side-bar;
        width: calc(100% - #{$size-side-bar});
    }
}

.arrow {
    display: none;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    width: 100%;
    height: 40px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &::before {
        position: absolute;
        top: -$s-xl;
        left: 0;
        width: 100%;
        height: 40px + $s-xl * 2;
        content: '';
        background: $c-grey-woodsmoke;
    }
}

.container {
    padding-bottom: $s-xxxl * 2;
}

@include desktop {
    .wrapper {
        display: grid;
        grid-template-columns: $size-large-side-bar calc(100vw - #{$size-large-side-bar});

        &.stop-scroll {
            overflow: unset;
        }
    }

    .main {
        height: calc(100vh - #{$size-music-player});
        padding-top: 0;
        padding-bottom: $size-music-player * 2;
        margin-bottom: $size-music-player;
        overflow: auto;
        overflow-x: hidden;
    }

    .top-bar {
        display: block;
    }

    .arrow {
        display: block;
    }

    .user-login {
        display: block;

        &.fixed {
            left: $size-large-side-bar;
            width: calc(100% - #{$size-large-side-bar});
            padding-right: $size-scrollbar-width;
        }
    }

    .user-login-inner {
        margin-top: 0;
    }

    .container {
        padding-top: $s-main-desktop-top;

        &.hidden-overflow {
            overflow: hidden;
        }
    }
}
</style>
