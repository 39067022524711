export default {
    inserted: el => {
        function loadImage() {
            const imageElement = Array.from(el.children).find(el => el.nodeName === 'IMG')

            if (imageElement) {
                const onLoadHandler = () => {
                    el.classList.add('loaded')
                    imageElement.removeEventListener('load', onLoadHandler, { passive: true })
                }

                const onErrorHandler = () => {
                    imageElement.removeEventListener('error', onErrorHandler, { passive: true })
                }

                imageElement.src = imageElement.dataset.src
                imageElement.addEventListener('load', onLoadHandler, { passive: true })
                imageElement.addEventListener('error', onErrorHandler, { passive: true })
            }
        }

        function handleIntersect(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadImage()
                    observer.disconnect()
                }
            })
        }

        function createObserver() {
            const options = {
                root: null,
                threshold: 0,
            }

            const observer = new IntersectionObserver(handleIntersect, options)

            observer.observe(el) // target element to watch
        }

        if (!window.IntersectionObserver) {
            loadImage()
        } else {
            createObserver()
        }
    },
}
