import Emitter from 'component-emitter'
import throttle from 'lodash/throttle'
const soundManager = require('soundmanager2/script/soundmanager2-nodebug-jsmin.js').soundManager

export default function playerService() {
    const events = Emitter({})

    let sound = null

    const statusObj = {
        status: 'stopped',
        currentTime: -1,
        duration: null,
        type: null,
    }

    const settingsObj = {
        volume: 25,
    }

    let prevVolume = 25

    const on = (event, arg) => {
        events.on(event, arg)
    }
    const off = event => {
        events.off(event)
    }

    const emitTimeUpdate = () => {
        events.emit('timeUpdate', statusObj)
    }
    const emitStatusUpdate = () => {
        events.emit('statusUpdate', statusObj)
    }
    const emitSettingsUpdate = () => {
        events.emit('settingsUpdate', settingsObj)
    }

    const play = (file, time = 0, type) => {
        if (!file) return false

        if (sound !== null) {
            soundManager.destroySound(sound)
            statusObj.currentTime = 0
        }

        statusObj.status = 'loading'
        sound = 'sound'

        soundManager.createSound({
            id: sound,
            url: file,
            volume: settingsObj.volume,
        })

        emitStatusUpdate()

        const debouncedFc = throttle(function () {
            if (!sound || statusObj.status === 'paused') return false

            statusObj.currentTime = this.position
            statusObj.duration = this.duration
            statusObj.status = 'playing'
            statusObj.type = type
            emitTimeUpdate()
        }, 500)

        return new Promise((resolve, reject) => {
            const returnVal = soundManager.play(sound, {
                whileplaying: debouncedFc,
                onload(success) {
                    if (!success) {
                        stop()
                        reject(new Error('check for errors'))
                    } else {
                        resolve(returnVal)
                    }
                    emitStatusUpdate()
                },
            })
        })
    }

    const pause = () => {
        if (statusObj.status === 'paused') {
            statusObj.status = 'playing'
            soundManager.resume('sound')
        } else {
            soundManager.pause('sound')

            setTimeout(() => {
                statusObj.status = 'paused'
                emitStatusUpdate()
            })
        }
    }

    const stop = () => {
        if (sound !== null) {
            soundManager.pause('sound')
            soundManager.destroySound(sound)
            sound = null
        }

        setTimeout(() => {
            statusObj.currentTime = -1
            statusObj.status = 'stopped'
            emitStatusUpdate()
        })
    }

    const setVolume = value => {
        settingsObj.volume = value
        soundManager.setVolume(settingsObj.volume)
        emitSettingsUpdate()
    }

    const seekTime = timeMs => {
        if (statusObj.type === 'mix' && soundManager.setPosition('sound', timeMs)) {
            statusObj.currentTime = timeMs
            emitTimeUpdate()
        }
    }

    const seekForward = (span = 10000) => {
        let time = statusObj.currentTime + span
        seekTime(time)
    }

    const seekBackwards = (span = 10000) => {
        let time = statusObj.currentTime - span
        seekTime(time)
    }

    const volumeUp = () => {
        let volume = settingsObj.volume
        volume = volume + 10
        volume = volume < 100 ? volume : 100
        setVolume(volume)
    }

    const volumeDown = () => {
        let volume = settingsObj.volume
        volume = volume - 10
        volume = volume > 0 ? volume : 0
        setVolume(volume)
    }

    const toggleMute = () => {
        let volume = settingsObj.volume
        if (volume !== 0) {
            prevVolume = volume
            volume = 0
        } else {
            volume = prevVolume
        }
        setVolume(volume)
    }

    return {
        on,
        off,
        play,
        pause,
        stop,
        setVolume,
        seekTime,
        seekForward,
        seekBackwards,
        volumeUp,
        volumeDown,
        toggleMute,
    }
}
