/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready(arg) {
            console.log(
                'App is being served from cache by a service worker.\n' +
                    'For more details, visit https://goo.gl/AFskqB'
            )
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updated() {
            console.log('New content is available; page will be updated upon a refresh or next visit.')
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        },
        registered(reg) {
            const timeout = 5 * 60 * 1000 // check for new SW every 5 minutes
            const updateFc = () => {
                reg.update()
                setTimeout(updateFc, timeout)
            }

            setTimeout(updateFc, timeout)
        },
    })
}
