const paymentServices = [
    {
        alias: 'stripe',
        title: 'Credit Card',
        description: 'Visa, Mastercard & AmEx',
        icon: 'credit-card',
    },
    {
        alias: 'paypal',
        title: 'PayPal',
        description: '',
        icon: 'paypal',
    },
]

const donationProducts = [
    {
        amount: 20,
        stripe: {
            url: 'https://donate.stripe.com/price_0PQxbGyeBuFmPyetkRk5CaO7',
        },
        paypal: {
            url: 'https://www.paypal.com/donate/?hosted_button_id=VVB3YVZVKUKQ4',
        }
    },
    {
        amount: 50,
        stripe: {
            url: 'https://donate.stripe.com/price_0PQxbDyeBuFmPyetdWUlBLHg',
        },
        paypal: {
            url: 'https://www.paypal.com/donate/?hosted_button_id=KT8ZDDM33Q43Y',
        }
    },
    {
        amount: 100,
        stripe: {
            url: 'https://donate.stripe.com/price_0PQxb9yeBuFmPyetgBe8dkcc',
        },
        paypal: {
            url: 'https://www.paypal.com/donate/?hosted_button_id=EQRPKHZESLUNN',
        }
    },
]

const onDemandQualityOptions = {
    low: {
        title: 'Normal',
        subtitle: '',
        value: '96',
        needsSub: false
    },
    high: {
        title: 'High - FRISKY Premium only',
        subtitle: 'Great sound quality',
        value: '128',
        needsSub: true
    },
    best: {
        title: 'Best - FRISKY Premium only',
        subtitle: 'High-Resolution audio quality',
        value: '320',
        needsSub: true
    },
}

// This is the number of days to allow for a trial period
const trialDays = 30

// This is the number of months to pause a subscription for
const subscriptionPauseDurationInMonths = 3

export {
    paymentServices,
    donationProducts,
    trialDays,
    subscriptionPauseDurationInMonths,
    onDemandQualityOptions,
}
