<script>
import SubscriptionTile from './../components/subscription-tile.vue';

export default {

    components: { SubscriptionTile },
    props: {
        plans: {
            type: Array,
            default: () => [],
        },
        currentSubscription: {
            type: Object,
            default: () => {},
        },
        isLoggedIn: {
            type: Boolean,
            default: false,
        },
        isEntitledFreeTrial: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onAction(plan) {
            this.$emit('onAction', plan);
        },
    }
}
</script>


<template>
    <div
        v-if="plans.length"
        :class="$style.plansBlock"
    >
        <SubscriptionTile
            v-for="plan in plans"
            :key="`plan-tile-${plan.id}`"
            :current-subscription="currentSubscription"
            :plan="plan"
            :is-logged-in="isLoggedIn"
            :is-entitled-free-trial="isEntitledFreeTrial"
            @onAction="onAction"
        />
    </div>
</template>

<style lang="scss" module>
@use '@design' as *;

.plansBlock {
    display: flex;
    flex-direction: column;
    grid-gap: $s-m;
    align-items: center;
    margin-top: $s-m;
}

@include tablet {
    .plansBlock {
        flex-direction: row;
    }
}
</style>
