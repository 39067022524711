<script>
export default {
    name: 'BaseContent',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
}
</script>

<template>
    <div :class="$style.container">
        <div
            v-if="title"
            :class="$style.mainHeading"
        >{{ title }}</div>
        <slot />
    </div>
</template>

<style lang="scss" module>
@import '@design';

.container {
    position: relative;
    z-index: $layer-page-z-index;
}

.mainHeading {
    @include h3;

    margin-top: $s-h;
    margin-bottom: $s-xxl;
    letter-spacing: 0.25px;
    &::after {
        display: block;
        min-height: 1px;
        margin-top: $s-xl;
        content: '';
        background: $c-dusty-gray;
        opacity: 0.1;
    }

    @include desktop {
        @include container;
        @include h1;

        margin-bottom: $s-xxl;
    }

    @include sidePadding;
}
</style>
