export default class Birthday {
    day
    month
    year

    constructor() {
        this.day = null
        this.month = null
        this.year = null
    }
}
