const Promise = require('bluebird')

export default function showScheduleCreator({ resources, storage, api }) {
    let dataPromise = null

    const getPromise = function () {
        return dataPromise || resources.shows.getPromise()
    }

    let isComplete = false
    return {
        getPromise,
        fetch(startDate, endDate, channelId) {
            if (dataPromise) {
                return dataPromise
            }

            dataPromise = api.showSchedule.get(startDate, endDate, channelId).then(array => {
                storage.showSchedule.join(array)
                dataPromise = null
                return storage.showSchedule.get().array
            })

            return dataPromise
        },
        promise: null,
        update() {},
        isComplete() {
            return isComplete
        },
        grab() {
            const storageSchedule = storage.showSchedule
                .get()
                .array.sort((a, b) => {
                    return +a.scheduled_start_time - +b.scheduled_start_time
                })
                .map(el => {
                    return {
                        ...el,
                        show: el.show_id ? resources.shows.get(el.show_id).value : null,
                        artist: el.artist_id ? resources.artists.get(el.artist_id).value : null,
                    }
                })

            isComplete = resources.episodes.isExtendedList(storageSchedule)
            return storageSchedule
        },
        get(startDate, endDate, channelId = null) {
            return {
                value: this.grab(),
                promise: (this.fetch(startDate, endDate, channelId) || new Promise(resolve => resolve())).then(() => {
                    return this.grab()
                }),
            }
        },
    }
}
