import Vue from 'vue'
import App from './app'
import VueCarousel from 'vue-carousel'
import router from '@router'
import store from '@state/store'
import { sync } from 'vuex-router-sync'
import '@components/_globals'
import messageService from '@services/message-service'

import storage from '@services/data-layer/storage'
import './registerServiceWorker'

import clickOutside from '@directives/clickOutside'
import analyticsService from './services/analytics-service'

window.storage = storage
// Don't warn about using the dev version of Vue in development
Vue.config.productionTip = process.env.NODE_ENV === 'production'

Vue.prototype.$dataLayer = store.state.services.dataLayer
Vue.prototype.$queueService = store.state.services.queueService
Vue.prototype.$playerService = store.state.services.playerService
Vue.prototype.$keyControlsService = store.state.services.keyControlsService

// Messages Service
Vue.prototype.$m = messageService
window.$m = messageService

// events from queue service are available here
Vue.prototype.$queueService.on('statusUpdate', val => store.commit('playback/SET_STATUS', val))
Vue.prototype.$queueService.on('dataUpdate', val => {
    store.commit('playback/SET_DATA', val)
})
Vue.prototype.$queueService.on('errorUpdate', ({ message, type }) =>
    store.dispatch('appearance/handleNotification', { message, type })
)
Vue.prototype.$playerService.setVolume(store.state.settings.volume)
Vue.prototype.$playerService.on('settingsUpdate', val => store.commit('settings/SET_SETTINGS', val))

// Events from favorite mixes service are available here
Vue.prototype.$dataLayer.favMixes.on('updateFavMixes', val => store.commit('favorites/UPDATE_FAVORITE_MIXES', val))
Vue.prototype.$dataLayer.favArtists.on('updateFavArtists', val =>
    store.commit('favorites/UPDATE_FAVORITE_ARTISTS', val)
)
Vue.prototype.$dataLayer.favShows.on('updateFavShows', val => store.commit('favorites/UPDATE_FAVORITE_SHOWS', val))

Vue.use(VueCarousel)

// Analytics Service
Vue.prototype.$analytics = analyticsService

// VUE Directives
Vue.directive('click-outside', clickOutside)

sync(store, router)
const app = new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')

// If running inside Cypress
if (window.Cypress) {
    // Attach the app to the window, which can be useful
    // for manually setting state in Cypress commands
    // such as `cy.logIn()`
    window.__app__ = app
}
