<script>
export default {
    components: {  },
    props: {},
    data() {
        return {}
    },
    computed: {},
    mounted() {},
}
</script>

<template>
    <div>
        <div class="top-bar">
            <slot name="top-bar"/>
        </div>
        <div class="boxed-container">
            <div class="wrapper">
                <aside class="sidebar">
                    <slot name="sidebar"/>
                </aside>
                <main class="main">
                    <slot name="main"/>
                </main>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@design';

$sidebar-top: $size-menu-bar-height + $s-m;

.top-bar {
    position: sticky;
    top: 0;
    z-index: $layer-overlay-z-index;
}

.wrapper {
    gap: $s-l;
}

.sidebar {
    width: 100%;
    padding: $s-m;
    margin-top: $s-m;
    background-color: $c-black;
    border-radius: $s-s;
}

.main {
    flex: 1;
    padding: $s-m;
    margin-bottom: $s-h;
}

.boxed-container {
    width: $largescreen;
    max-width: 100%;
    padding: 0 $s-s;
    margin: 0 auto;
}

@include tablet {
    .wrapper {
        display: flex;
        justify-content: space-between;
    }

    .sidebar {
        position: sticky;
        top: $sidebar-top;
        width: 300px;
        height: calc(100vh - $sidebar-top);
        padding: $s-xxl $s-xxl;
        border-radius: $s-s $s-s 0 0;
    }
}
</style>
