<script>
import DataLayer from '../../../services/data-layer/index.js'
import { centsToDollars } from './../../../utils/format-price'
import Logo from './../../../components/logo'
import formatDate from './../../../utils/format-date-full'
import { trialDays } from '../../../configuration.js'
import analyticsService from './../../../services/analytics-service'

const subscriptionService = DataLayer.subscriptions

export default {
    name: 'PremiumSelectPayment',

    components: { Logo },

    data() {
        return {
            paymentMethods: [
                {
                    alias: 'stripe',
                    title: 'Credit Card',
                    description: 'Visa, Mastercard & AmEx',
                    icon: 'credit-card',
                },
                {
                    alias: 'paypal',
                    title: 'PayPal',
                    description: '',
                    icon: 'paypal',
                },
            ],
            selectedMethod: '',
        }
    },

    computed: {
        nextBillingDateValue() {
            if (!this.selectedPlan?.durationMonths) return
            const date = new Date()
            let nextBillingDate = null
            if (this.entitledFreeTrial) nextBillingDate = date.setDate(date.getDate() + trialDays)
            else nextBillingDate = date.setMonth(date.getMonth() + this.selectedPlan.durationMonths)
            return formatDate(new Date(nextBillingDate))
        },
        entitledFreeTrial() {
            return !this.$store.getters['premium/hasEverBeenPremium'] && this.selectedPlan?.durationMonths === 1
        },
        selectedPlan() {
            return this.$store.getters['premium/selectedPlan']
        },
        isStandalone() {
            return this.$store.state.premium.isStandalone
        },
    },

    mounted() {
        this.selectPlan()
    },

    methods: {
        centsToDollars,

        selectPlan() {
            this.$store.commit('premium/SET_SELECTED_PLAN_ID', this.$route.params.planId)
        },

        isUserLoggedIn() {
            return this.$store.getters['auth/loggedIn']
        },

        async proceedCheckout(method) {
            analyticsService.premiumUpsellPaymentSelected(this.planId, method.alias)
            if (!this.isUserLoggedIn()){
                return this.$router.push({
                    name: 'login',
                    query: { redirectUrl: this.$route.path },
                })
            }
            this.selectedMethod = method
            const checkoutData = this.getCheckoutData(this.selectedPlan)
            const res = await subscriptionService.checkout(checkoutData)
            const checkout = res.body

            this.redirect(checkout.redirectUrl)
        },

        getCheckoutData(plan) {
            const subscriptionsProductsId = plan.id
            const paymentProcessor = this.selectedMethod.alias
            return { subscriptionsProductsId, paymentProcessor }
        },

        redirect(url) {
            window.location = url
        },
    },
}
</script>

<template>
    <div :class="$style.selectPayment">
        <BaseContent>
            <BaseSection padded>
                <Logo
                    :class="$style.logo"
                    :is-linked-to-home="false"
                    size="m"
                />
            </BaseSection>
            <BaseSection
                v-if="selectedPlan"
                padded
            >
                <div :class="$style.selectedPlan">
                    <h2 :class="$style.selectedPlanHeader">Your plan</h2>
                    <p :class="$style.selectedPlanTitle">{{ selectedPlan.title }}</p>
                    <hr :class="$style.separator" >
                    <div :class="$style.price">
                        <span>{{ entitledFreeTrial ? 'Pay today' : 'Price' }}</span>
                        <span>{{ centsToDollars(entitledFreeTrial ? 0 : selectedPlan.price) }}</span>
                    </div>
                    <div :class="$style.nextBillingDate">
                        <span>Next billing day</span>
                        <span>{{ nextBillingDateValue }}</span>
                    </div>
                    <div :class="$style.changePlanContainer">
                        <router-link
                            :class="$style.changePlan"
                            :to="{ name: 'premium/view-plans', query: { ...this.$route.query } }"
                        >Change Plan</router-link>
                    </div>
                </div>
            </BaseSection>

            <BaseSection padded>
                <h1 :class="$style.mainHeading">Select a Payment Method</h1>
                <div :class="$style.methods">
                    <div
                        v-for="method of paymentMethods"
                        :key="'payment-method' + method.alias"
                        :class="[$style.method]"
                        :title="method.title"
                        :aria-label="method.title"
                        @click="proceedCheckout(method)"
                    >
                        <div :class="$style.methodDetails">
                            <div>
                                <p :class="$style.methodTitle">{{ method.title }}</p>
                                <div>
                                    <p
                                        v-if="method.description"
                                        :class="$style.methodDescription"
                                    >
                                        {{ method.description }}
                                    </p>
                                </div>
                            </div>
                            <BaseIcon
                                :class="[$style.icon, $style.iconPayment]"
                                :name="method.icon"
                            />
                        </div>
                    </div>
                </div>
            </BaseSection>

            <transition name="fade">
                <BaseSection v-show="selectedMethod" />
            </transition>
        </BaseContent>
    </div>
</template>

<style lang="scss" module>
@use '@design' as *;

.selectPayment {
    text-align: center;
}

.logo {
    text-align: left;
}

.selectedPlan {
    width: 400px;
    max-width: 100%;
    padding: $s-m;
    margin: 0 auto;
    color: $c-white;
    background: $c-frisky-channel-grad;
    border-radius: 20px;
}

.selectedPlanHeader {
    @extend %h5;
}

.selectedPlanTitle {
    color: $c-white;
    text-align: left;
}

.separator {
    opacity: 0.5;
}

.price {
    display: flex;
    justify-content: space-between;
    color: $c-white;

    @extend %p;
}

.nextBillingDate {
    display: flex;
    justify-content: space-between;
    color: $c-white;

    @extend %p;
}

.changePlanContainer {
    margin-top: $s-m;
    text-align: right;
}

.changePlan {
    color: $c-white;
    opacity: 0.6;

    @extend %contentSubtitleM;
}

.mainHeading {
    @extend %h6;
}

.methods {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.methodDetails {
    display: flex;
    gap: $s-m;
    align-items: center;
    justify-content: space-between;
    text-align: left;
}

.methodTitle {
    margin: 0;
    font-weight: normal;

    @extend %contentTitleM;
}

.methodDescription {
    margin: 0;

    @extend %contentSubtitleM;
}

.method {
    position: relative;
    width: 500px;
    max-width: 100%;
    padding: $s-m $s-xxl;
    cursor: pointer;
    background: $c-black-grey-gradient;
    border-radius: $button-border-radius;

    + .method {
        margin-top: $s-m;
    }

    &:hover {
        background: $c-grey-mine-shaft;
    }
}

.methodButton {
    margin-top: $s-xxl;
}

.icon {
    &Payment {
        flex-shrink: 0;
        width: 35px;
        height: 35px;
        fill: $c-white;
    }
}

@include desktop {
    .selectPayment {
        margin-top: $s-xxl;
    }
}
</style>
