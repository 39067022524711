<script>
import DataLayer from '@services/data-layer/index'
import PlaylistsGridBlock from '@components/playlists/playlists-grid-block'

export default {
    components: { PlaylistsGridBlock },
    data() {
        return {
            playlists: null,
        }
    },
    computed: {
        currentUserId() {
            return this.$store.state.auth.currentUser.members_id.id
        },
        link() {
            return this.playlists.length > 6 ? 'browse/playlists' : false
        },
    },
    mounted() {
        this.getRecommendationPlaylists()
    },
    methods: {
        async getRecommendationPlaylists() {
            const playlist = await DataLayer.playlists.getMemberRecommendations(this.currentUserId)
            this.playlists = playlist.body
        },
    },
}
</script>

<template>
    <div
        v-if="playlists && playlists.length"
        :class="$style.section"
    >
        <BaseSection
            :show-all-link="link"
            title="Made for You"
            subtitle="Discover new artists and mixes based on your listening history"
            show-all-title="Browse All"
            padded
        >
            <PlaylistsGridBlock
                :playlists="playlists"
                type="playlists"
                min-tile-width="160px"
            />
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.section {
    margin-top: $s-xxl;
}
</style>
