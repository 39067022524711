<script>
import DataLayer from '@services/data-layer/index'

import ShowScheduleBlock from '@components/show-schedule-block'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import startOfToday from 'date-fns/startOfToday'

export default {
    components: { ShowScheduleBlock },
    data() {
        return {
            dates: [],
            activeDate: {},
            schedule: Array.from(Array(6), (e, i) => ({
                id: i,
                show: {
                    id: i,
                    title: '',
                    url: '',
                    type: 'show',
                    image: {},
                    genre: [],
                    artist_id: [],
                    artist: [],
                },
            })),
        }
    },
    computed: {
        channel() {
            return this.$store.state.channels.activeObject || {}
        },
        activeDateLabel() {
            return this.activeDate.label || ''
        },
        dateLabels() {
            return this.dates.map(el => el.label)
        },
    },
    watch: {
        activeDate: {
            handler(val) {
                if (!val.date) {
                    return false
                }
                this.getChannelSchedule(val.date)
            },
        },
    },
    mounted() {
        const date = startOfToday()

        for (let i = 0; i < 7; i++) {
            const newDate = addDays(date, i)
            this.dates.push({
                label: format(newDate, 'd MMM yyyy'),
                date: newDate,
            })
        }

        this.activeDate = this.dates[0]
    },
    methods: {
        handleDateChange(date) {
            this.activeDate = this.dates.find(el => el.label === date)
        },
        getChannelSchedule(date) {
            DataLayer.episodes.getByDate(this.$route.params.channelId, date, addDays(date, 1)).then(episodes => {
                this.schedule = episodes.body
            })
        },
    },
}
</script>

<template>
    <BaseContent>
        <BaseSection
            title="Schedule"
            padded
        >
            <div slot="right">
                <BaseCustomSelect
                    :options="dateLabels"
                    :active="activeDateLabel"
                    prefix="Date:"
                    @change="handleDateChange"
                />
            </div>
            <ShowScheduleBlock
                :resources="schedule"
                :error="{ paragraph: 'There are no shows scheduled for this day' }"
            />
        </BaseSection>
    </BaseContent>
</template>
