export const sortByProperty = ({ array = [], property = null, direction = 'asc' }) => {
    if (!array.length) return []
    if (!property) return array
    return array.sort((a, b) => {
        if (direction === 'asc') {
            return a[property] - b[property]
        } else {
            return b[property] - a[property]
        }
    })
}
