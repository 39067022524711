export const state = {
    activeObject: null,
}

export const getters = {}

export const mutations = {
    SET_ACTIVE(state, show) {
        state.activeObject = show
    },
}

export const actions = {}
