<script>
import DataLayer from '@services/data-layer/index'
import ShowService from './../../services/show-service'
import Layout from '@layouts/main'
import Overview from '@components/show/overview'
import About from '@components/show/about'

export default {
    page() {
        const title = this.show && this.show.title ? `Show - ${this.show.title}` : `Show`
        const description = (this.show && this.show.summary) || `Show`
        const image = this.show && this.show.album_art && this.show.album_art.url
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },
                { property: 'og:image', vmid: 'og:image', content: image },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
                { property: 'twitter:image', vmid: 'twitter:image', content: image },
            ],
        }
    },
    components: { Layout, Overview, About },
    props: {
        mainComponent: {
            type: String,
            default: 'Overview',
        },
    },
    data() {
        return {
            latestMixes: [],
            popularMixes: [],
            artists: [],
            // show: {}
        }
    },
    computed: {
        tabs() {
            const id = this.$route.params.id
            return [
                {
                    to: { name: 'show', params: { id } },
                    label: 'Overview',
                },
                {
                    to: { name: 'show/about', params: { id } },
                    label: 'About',
                },
            ]
        },
        show() {
            return this.$store.state.shows.activeObject
        },
    },
    mounted() {
        const id = this.$route.params.id
        Promise.all([
            DataLayer.shows.getById(id),
            DataLayer.mixes.getPopularByShow(id),
            DataLayer.mixes.getLatestByShow(id),
        ]).then(([show, popularMixes, latestMixes]) => {
            this.$store.commit('shows/SET_ACTIVE', show.body)
            this.artists = [show.body.artist]
            this.popularMixes = popularMixes.body
            this.latestMixes = latestMixes.body
            this.checkForAdditionalReach(id)
        })
    },
    methods: {
        showService: ShowService,
        checkForAdditionalReach(id) {
            if (this.showService().hasAdditionalReach(id)) this.$store.dispatch('shows/increaseReach')
        },
    },
}
</script>

<template>
    <Layout>
        <BaseHero
            :resource="show"
            :mix="latestMixes[0]"
            resource-type="show"
        />
        <BaseTabPanel
            :tabs="tabs"
            :class="$style.tabs"
            is-unpadded
            address-navigation
        />
        <BaseContent>
            <component
                :is="mainComponent"
                :show="show"
                :artists="artists"
                :popular-mixes="popularMixes"
                :latest-mixes="latestMixes"
            />
        </BaseContent>
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.tabs {
    @include tablet {
        padding-bottom: $s-xxl;
    }
}
</style>
