
<script>
export default {

}
</script>

<template>
    <div class="card">
        <div class="cardContent">
            <div
                v-if="$slots['header']"
                class="cardHeader"
            >
                <slot name="header"/>
            </div>
            <div class="body">
                <slot />
            </div>
            <div
                v-if="$slots['footer']"
                class="cardFooter"
            >
                <slot name="footer"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@design';

.card {
    display: grid;
    padding: $s-m;
    background-color: $c-shark-black;
    border-radius: $s-xs;
}

.cardContent {
    position: relative;
    width: 100%;
    height: 100%;
}

.body {
    margin-bottom: $s-xxl;
}

.cardFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

@include tablet {
    .card {
        padding: $s-l $s-xxl;
    }
}
</style>
