<script>
export default {
    props: {
        title: {
            type: [String, Boolean],
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
        padded: {
            type: Boolean,
            default: false,
        },
        short: {
            type: Boolean,
            default: false,
        },
        showAllLink: {
            type: [Object, Boolean],
            default: () => null,
        },
        showAllTitle: {
            type: String,
            default: 'show all',
        },
    },
}
</script>

<template>
    <section
        :class="[
            $style.section,
            padded ? $style.sectionPadded : $style.sectionNotPadded,
            short ? $style.sectionShort : '',
        ]"
    >
        <div
            v-if="title || subtitle"
            :class="$style.titleRow"
        >
            <h5 :class="$style.title">{{ title }}</h5>
            <slot name="right" />
            <router-link
                v-if="showAllLink"
                :to="showAllLink"
                :class="$style.link"
            >
                {{ showAllTitle }} <BaseIcon
                    :class="[$style.icon, $style.iconArrowRight]"
                    name="arrow-long-right"
                />
            </router-link>
            <div
                v-if="subtitle"
                :class="$style.subtitle"
            >{{ subtitle }}</div>
        </div>
        <slot />
    </section>
</template>

<style lang="scss" module>
@import '@design';

.section {
    margin-top: $size-grid-padding * 3;

    &Short {
        margin-top: 0;
    }

    @include tablet {
        margin-top: 0;

        @include container;
    }

    &Padded {
        @include sidePadding;
    }
}
.section + .section {
    margin-top: $size-grid-padding * 3;

    @include desktop {
        margin-top: $s-h;
    }
}

.title {
    margin: 0;

    @include h6;

    @include desktop {
        @include h5;
    }
}

.subtitle {
    @extend %contentSubtitleS;

    min-width: 100%;
    margin: 0;
}

.titleRow {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: $size-large-grid-padding;

    .sectionNotPadded & {
        @include sidePadding;
    }

    .link {
        @extend %contentTextButton;

        .iconArrowRight {
            width: 14px;
            height: 11px;
            margin-left: $s-s;
            vertical-align: middle;
            fill: $c-highlight;
        }
    }
}
</style>
