<script>
import ArtistTile from '@components/artist/artist-tile'

export default {
    components: {
        ArtistTile,
    },
    props: {
        artists: {
            type: Array,
            default: () => [],
        },
        extended: {
            type: Boolean,
            default: false,
        },
        hasTypeTitle: {
            type: Boolean,
            default: false,
        },
        error: {
            type: [Object, Boolean],
            default: false,
        },
    },
}
</script>

<template>
    <div :class="$style.container">
        <div :class="$style.inner">
            <ArtistTile
                v-for="artist in artists"
                :key="artist.id"
                :artist="artist"
                :extended="extended"
                :has-type-title="hasTypeTitle"
                :class="$style.tile"
            />
        </div>
        <BaseEmptyList
            v-if="artists.length === 0 && error"
            :error="error"
        />
    </div>
</template>

<style lang="scss" module>
@import '@design';
.container {
    max-width: 100%;
    max-width: none;

    @include desktop {
        max-width: none;
    }
}
.inner {
    display: flex;
    flex-wrap: wrap;
    padding-right: 0;
    padding-bottom: $size-large-grid-padding;
    padding-left: 0;
}
</style>
