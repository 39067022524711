<script>
import DataLayer from '@services/data-layer/index'
import getAndUpdate from '@utils/get-and-update'
import svgSprite from '@components/svg-sprite'
import NotificationDialog from '@components/notification-dialog'
import { getCookie, deleteCookie } from '@utils/cookies'
import localSave from '@utils/local-save'
import socialImage from '@assets/images/frisky-social-share-image.png'
import analyticsService from './services/analytics-service'
import settingsService from './services/settings-service'

const CHECK_VERSION_INTERVAL_DURATION = 1000 * 60 * 60 // 1 hour

export default {
    components: {
        svgSprite,
        NotificationDialog,
    },

    page: {
        // All subcomponent titles will be injected into this template.
        titleTemplate(title) {
            title = typeof title === 'function' ? title(this.$store) : title
            return title ? `FRISKY | ${title}` : 'FRISKY'
        },
        meta: [
            { property: 'og:title', vmid: 'og:title', content: `FRISKY` },
            { property: 'og:type', vmid: 'og:type', content: 'website' },
            { property: 'og:url', vmid: 'og:url', content: `${window.location.href}` },
            { property: 'og:image', vmid: 'og:image', content: socialImage },
            { property: 'fb:app_id', vmid: 'fb:app_id', content: '126928420652882' },

            { property: 'twitter:card', vmid: 'twitter:card', content: 'sumary' },
            { property: 'twitter:url', vmid: 'twitter:url', content: `${window.location.href}` },
            { property: 'twitter:site', vmid: 'twitter:site', content: '@friskyradio' },
            { property: 'twitter:creator', vmid: 'twitter:creator', content: '@friskyradio' },
            { property: 'twitter:image', vmid: 'twitter:image', content: socialImage },
        ],
    },

    data() {
        return {
            lastKey: null,
            socialImage,
            checkVersionInterval: null,
        }
    },

    computed: {
        loggedIn() {
            return this.$store.getters['auth/loggedIn']
        },
        isPremium() {
            return this.$store.getters['premium/isPremium']
        },
    },

    watch: {
        loggedIn: {
            handler() {
                this.updateFavorites()
            },
            immediate: true,
        },
        routeKey(to, from) {
            this.lastKey = to
            this.updateFavorites()
        },
    },

    async mounted() {
        this.lastKey = this.$route.fullPath
        this.checkAuthentication()
        getAndUpdate(input => this.$store.commit('channels/SET_CHANNELS', input), this.$dataLayer.channels.get())
        this.initCodeVersionCheck()
        this.initKeyControls()
        await this.initPremium()
        this.initSettins()
    },

    beforeDestroy() {
        window.removeEventListener('keydown', this.$keyControlsService.reactToKey)
        clearInterval(this.checkVersionInterval)
    },

    methods: {
        updateFavorites() {
            this.loggedIn && this.$dataLayer.favMixes.init()
            this.loggedIn && this.$dataLayer.favArtists.init()
            this.loggedIn && this.$dataLayer.favShows.init()
        },

        initCodeVersionCheck() {
            this.checkCodeVersion()
            this.checkVersionInterval = setInterval(() => {
                this.checkCodeVersion()
            }, CHECK_VERSION_INTERVAL_DURATION)
        },

        initKeyControls() {
            window.addEventListener('keydown', this.$keyControlsService.reactToKey)
        },

        checkAuthentication() {
            const oauthSession = getCookie('oauthSession') || ''
            if (oauthSession) {
                const user = JSON.parse(oauthSession)
                this.$store.dispatch('auth/setUser', user)
                this.$store.dispatch('member/init', null, { root: true })
                deleteCookie({ name: 'oauthSession', domain: process.env.VUE_APP_COOKIES_DOMAIN })
                this.redirectHandler()
            }
        },

        redirectHandler() {
            const redirectURL = localSave.get('redirectURL') || '/'
            if (redirectURL !== '/') window.location.replace(redirectURL)
        },

        checkCodeVersion() {
            const localCodeVersion = localSave.get('localCodeVersion')
            let actualCodeVersion = ''
            DataLayer.versions.get().then(res => {
                actualCodeVersion = res.body.clientVersion
                localSave.save('actualCodeVersion', actualCodeVersion)
                if (!localCodeVersion) {
                    localSave.save('localCodeVersion', actualCodeVersion)
                } else if (localCodeVersion !== actualCodeVersion) {
                    this.notifyForNewVersion(localCodeVersion, res.body)
                }
            })
        },

        notifyForNewVersion(oldVersion, newVersion) {
            this.$store.dispatch('appearance/handleNotification', {
                message:
                    newVersion.severity === 'critical'
                        ? this.$m('CRITICAL_UPDATE_AVAILABLE')
                        : this.$m('UPDATE_AVAILABLE'),
                type: 'warning',
                hasReload: true,
                isPersistentNotification: true,
                forceUpdate: newVersion.severity === 'critical',
            })
        },

        async handlePremiumLoad() {
            if(!this.loggedIn) return
            if (!localStorage.getItem('hasAppLoaded') && !this.isPremium) {
                localStorage.setItem('hasAppLoaded', true)
                if (this.$route.name.includes('premium')) return
                analyticsService.premiumUpsellInitiated('non-premium-log-in')
                this.$router.push({ path: '/premium' })
            }
        },

        async initPremium() {
            const premiumService = DataLayer.subscriptions
            const promises = [premiumService.getProducts()]
            const memberId = this.$store.state.auth.currentUser?.members_id?.id
            if (this.loggedIn) promises.push(premiumService.getSubscriptionsByMemberId(memberId))
            const [plans, subscriptions = null] = await Promise.all(promises)
            this.$store.commit('premium/SET_PLANS', plans.body)
            if (subscriptions) {
                this.$store.commit('premium/SET_SUBSCRIPTIONS', subscriptions.body)
                const activeSubscription = this.$store.getters['premium/activeSubscription']
                if(activeSubscription) {
                    const transaction = await premiumService.getCheckoutById(activeSubscription.subscriptionsCheckoutId.id)
                    this.$store.commit('premium/SET_ACTIVE_TRANSACTION', transaction.body)
                }
            }
            this.handlePremiumLoad()
        },

        async initSettins() {
            if(!this.loggedIn) return
            if(!this.isPremium) {
                await settingsService.setCurrentOnDemandQuality('low')
                this.$store.commit('settings/SET_SETTINGS', { currentOnDemandQualityKey: 'low' })
            } else {
                const currentOnDemandQualityKey = await settingsService.getCurrentOnDemandQualityKey()
                if(!currentOnDemandQualityKey || currentOnDemandQualityKey === 'low') {
                    await settingsService.setCurrentOnDemandQuality('high')
                    this.$store.commit('settings/SET_SETTINGS', { currentOnDemandQualityKey: 'high' })
                }
            }
        }
    },
}
</script>

<template>
    <div id="app">
        <svg-sprite />
        <router-view />
        <NotificationDialog v-if="$store.state.appearance.isNotificationOpen" />
    </div>
</template>

<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern
@use '@design' as *;

@import '@design/animations';
@import '@design/normalize';
@import '~nprogress/nprogress.css';
@import url('https://use.typekit.net/gom4uqy.css');

@include init;

*,
*::before,
*::after {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: $size-scrollbar-width;
}

::-webkit-scrollbar-track {
    opacity: 0;
}

::-webkit-scrollbar-thumb {
    min-height: $s-xxl;
    background-color: $c-tuna;
    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 16px;
}

html {
    scroll-behavior: smooth;
}

body {
    width: 100vw;
    height: 100vh;
    background: $c-body-bg;

    &:has([is-nav-open="true"]) {
        overflow: hidden;
    }
}

option {
    color: $c-white;
    background: $c-grey-woodsmoke;
}

@include desktop {
    body {
        &:has([is-nav-open="true"]) {
            overflow: unset;
        }
    }
}

// ===
// Vendor
// ===

#nprogress .bar {
    height: 5px;
    background: $c-link-text;
    will-change: transform;
}

#nprogress .peg {
    box-shadow: 0 0 10px $c-link-text, 0 0 5px $c-link-text;
}
</style>
