<script>
import lazyType from '@utils/lazy-type'

export default {
    data() {
        return {
            searchKeyword: '',
            lazyTypeDelay: 500,
        }
    },
    computed: {
        routeKeyword() {
            return this.$route && this.$route.params && this.$route.params.keyword
        },
        fromRoute() {
            return this.$store.state.search.previousRoute
        },
    },
    mounted() {
        if (this.routeKeyword) {
            this.searchKeyword = this.routeKeyword.replace(/_/g, ' ')
            this.$store.commit('search/UPDATE_KEYWORD', this.searchKeyword)
        }
        this.$refs.searchKeyword.focus()
        !this.$store.state.route.from.fullPath.includes('search') &&
            this.$store.commit('search/UPDATE_PREVIOUS_ROUTE', this.$store.state.route.from)
    },
    methods: {
        lazyType,
        close() {
            this.searchKeyword && this.searchKeyword.length
                ? (this.searchKeyword = '')
                : this.$router.push({ path: this.fromRoute.path, query: { back: true } })
        },
        updateKeyword() {
            this.$store.commit('search/UPDATE_KEYWORD', this.searchKeyword.replace(/_/g, ' '))
        },
    },
}
</script>

<template>
    <div :class="$style.searchBar">
        <BaseIcon
            :class="[$style.icon, $style.iconSearch]"
            name="search"
        />
        <input
            ref="searchKeyword"
            v-model="searchKeyword"
            :class="$style.searchInput"
            type="text"
            placeholder="Start Typing..."
            @keyup.passive="lazyType(updateKeyword, lazyTypeDelay)"
        >
        <a @click="close">
            <BaseIcon
                :class="[$style.icon, $style.iconClose]"
                name="x"
            />
        </a>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.searchBar {
    display: inline-grid;
    grid-template-columns: 24px auto 24px;
    align-items: center;
    width: 100%;
    height: $size-search-bar-height;
    padding: 0 $s-xl;
    background-color: $c-searchbar-bg;
}
.icon {
    width: 24px;
    height: 24px;
    fill: $c-white;

    &Close {
        cursor: pointer;
    }

    &Search {
        opacity: 0;

        @include desktop {
            opacity: 1;
        }
    }
}
.searchInput {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    color: $c-white;
    text-align: center;
    letter-spacing: -0.83px;
    background: none;
    border: none;
    outline: none;

    @media (min-width: $desktop) {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -1px;
    }

    &::placeholder {
        color: transparentize($color: $c-white, $amount: 0.2);
    }
}
</style>
