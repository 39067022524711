<script>
import MixLargeTile from '@components/mix-large-tile'
import ShowTableBlock from '@components/show-table-block'
import ArtistBlock from '@components/artist/artist-block'

export default {
    components: { MixLargeTile, ShowTableBlock, ArtistBlock },
    props: {
        nowPlaying: {
            type: Object,
            default: () => {},
        },
        channel: {
            type: Object,
            default: () => {},
        },
        trendingMixes: {
            type: Array,
            default: () => [],
        },
        trendingArtists: {
            type: Array,
            default: () => [],
        },
    },
}
</script>

<template>
    <BaseContent>
        <BaseSection
            padded
            title="Now Playing"
        >
            <MixLargeTile
                v-if="nowPlaying"
                :mix="nowPlaying.mixes"
                :channel="channel"
            >
                <BaseButtonPlay
                    slot="button"
                    :resource="channel"
                    type="secondary"
                />
            </MixLargeTile>
        </BaseSection>

        <BaseSection
            v-if="trendingMixes && trendingMixes.length"
            padded
            title="Trending Shows"
        >
            <ShowTableBlock :mixes="trendingMixes" />
        </BaseSection>

        <BaseSection
            v-if="trendingArtists && trendingArtists.length"
            title="Trending DJs"
        >
            <ArtistBlock :artists="trendingArtists" />
        </BaseSection>
    </BaseContent>
</template>
