import storageCreator from '@services/data-layer/storage'
import favoritesCreator from '@services/data-layer/favorites'
import apiCreator from '@services/data-layer/api'
import scheduleCreator from '@services/data-layer/schedule'
import showScheduleCreator from '@services/data-layer/show-schedule'
import createBaseResourceCreator from '@services/data-layer/create-base-resource'
import createGettableListCreator from '@services/data-layer/create-gettable-list'
import retryPromiseCreator from '@utils/retry-promise'
import nowPlayingCreator from '@services/data-layer/now-playing'
import genresCreator from './data-layer/genres'
import channelsCreator from './data-layer/channels'
import searchCreator from './data-layer/search'

import './data-layer/artists'
import './data-layer/auth'
import './data-layer/bfhs'
import './data-layer/channels.1'
import './data-layer/episodes'
import './data-layer/members'
import './data-layer/mixes'
import './data-layer/models'
import './data-layer/playlists'
import './data-layer/shows'
import './data-layer/station-playlists'
import './data-layer/subscriptions'
import './data-layer/uploader'
import './data-layer/versions'

const Promise = require('bluebird')

const defaultStorage = storageCreator()
const defaultApi = apiCreator(defaultStorage)

export default function dataLayer(
    { api = defaultApi, storage = defaultStorage } = {
        api: defaultApi,
        storage: defaultStorage,
    }
) {
    const resources = {}
    const createBaseResource = createBaseResourceCreator({ resources, api, storage })
    const createGettableList = createGettableListCreator({ resources })

    const shows = (resources.shows = createBaseResource('shows', 'Shows'))

    const episodes = (resources.episodes = createBaseResource('episodes', 'Episodes'))

    const mixes = (resources.mixes = createBaseResource('mixes', 'Mixes'))

    const artists = (resources.artists = (function () {
        const artistsCore = createBaseResource('artists', 'Artists')
        const latestMixesList = createGettableList(api.mixes.getArtistLatest, 12, 0)
        const trendingMixesList = createGettableList(api.mixes.getArtistTrending, 6, 0)

        function artistExtend(id) {
            return {
                ...artistsCore.grab(id),
                latestMixes: latestMixesList.getExtended(id),
                trendingMixes: trendingMixesList.getExtended(id),
            }
        }

        let dataPromise = []

        const retryPromise = retryPromiseCreator({
            conditionFc: id => !(latestMixesList.isComplete(id) && trendingMixesList.isComplete(id)),
        })

        function getPromiseExtended(id) {
            dataPromise = [
                artistsCore.getPromise(),
                mixes.getPromise(),
                latestMixesList.promise[id],
                trendingMixesList.promise[id],
                retryPromise(id),
            ].filter(el => el !== null)

            return dataPromise
        }

        return {
            ...artistsCore,
            latestMixesList,
            trendingMixesList,
            getExtendedById(id) {
                artistsCore.getById(id)
                trendingMixesList.get(id)
                latestMixesList.get(id)

                const handlePromises = () => {
                    const value = artistExtend(id)
                    const promises = getPromiseExtended(id)
                    return {
                        value,
                        promise: promises.length > 0 ? Promise.any(promises).then(handlePromises) : null,
                    }
                }
                return handlePromises()
            },
        }
    })())

    const schedule = scheduleCreator({ resources, storage, api })

    const showSchedule = showScheduleCreator({ resources, storage, api })

    const nowPlaying = nowPlayingCreator({ storage, api, resources })

    const channels = channelsCreator({ resources, api, storage, nowPlaying, showSchedule, schedule })

    const genres = genresCreator({ api, resources })

    const favMixes = favoritesCreator({ api, storage, resources })
    const favArtists = favoritesCreator({ type: 'artists', api, storage, resources })
    const favShows = favoritesCreator({ type: 'shows', api, storage, resources })
    const search = searchCreator({ storage })

    function getAll() {
        return {
            channels: storage.channels.get(),
            schedule: storage.schedule.get(),
            shows: storage.shows.get(),
        }
    }

    return {
        api,
        channels,
        schedule,
        showSchedule,
        artists,
        mixes,
        shows,
        episodes,
        getAll,
        nowPlaying,
        genres,
        favMixes,
        favArtists,
        favShows,
        search,
    }
}
