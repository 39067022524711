<script>
import DataLayer from '@services/data-layer/index'
import ArtistBlock from '@components/artist/artist-block'

export default {
    components: { ArtistBlock },
    data() {
        return {
            artists: [],
        }
    },
    mounted() {
        DataLayer.artists.getTrending(7).then(artists => {
            this.artists = artists.body
        })
    },
}
</script>

<template>
    <div
        v-if="artists && artists.length"
        :class="$style.section"
    >
        <BaseSection
            title="Trending DJs"
            padded
        >
            <ArtistBlock :artists="artists" />
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.section {
    margin-top: $s-xxl;
}
</style>
