<script>
import DataLayer from '@services/data-layer/index'
import { mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'

export default {
    page() {
        const title = 'Account - Change Password'
        const description = 'Account - Change Password'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    mixins: [validationMixin],
    data() {
        return {
            user: null,
            password: '',
            newPassword: '',
            newPasswordConfirmation: '',
            token: '',
        }
    },
    computed: {
        member() {
            return this.$store.state.member.currentMember
        },
        isPassSet() {
            return this.$store.state.member.passState === 'set'
        },
    },
    mounted() {
        this.user = { ...this.$store.state.member.currentMember }
    },
    validations: {
        password: {
            required,
        },
        newPassword: {
            required,
        },
        newPasswordConfirmation: {
            required,
            sameAsPassword: sameAs(function () {
                return this.newPassword
            }),
        },
    },
    methods: {
        ...mapActions('auth', ['logIn', 'logOut']),
        checkPassword() {
            if (!this.isPassSet) {
                this.changePassword()
                return
            }
            this.$v.$touch()
            if (
                !this.$v.password.required ||
                !this.$v.newPassword.required ||
                !this.$v.newPasswordConfirmation.required
            ) {
                return
            }
            DataLayer.auth
                .login({
                    email: this.member.email,
                    password: this.password,
                })
                .then(token => {
                    this.token = token
                    this.changePassword()
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.$store.dispatch('appearance/handleNotification', {
                            message: this.$m('WRONG_PASSWORD'),
                            type: 'danger',
                        })
                    }
                })
        },
        changePassword() {
            this.$v.$touch()
            if (!this.$v.newPassword.required || !this.$v.newPasswordConfirmation.required) {
                return
            }
            if (!this.$v.newPasswordConfirmation.sameAsPassword) {
                this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('PASSWORD_MATCH'),
                    type: 'danger',
                })
                return
            }
            this.user.password = this.newPassword
            DataLayer.members
                .edit({ id: this.user.id, data: this.user })
                .then(user => {
                    this.$store.commit('member/SET_CURRENT_MEMBER', user.body)
                    this.$store.commit('member/SET_PASS_STATE', 'set')
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m(this.isPassSet ? 'PASSWORD_UPDATED' : 'PASSWORD_SET'),
                        type: 'success',
                    })
                    this.redirectToAccountPage()
                })
                .catch(_error => {
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m('SOMETHING_WENT_WRONG'),
                        type: 'danger',
                    })
                })
        },
        redirectToAccountPage() {
            this.$router.push('/account/overview')
        },
    },
}
</script>

<template>
    <div>
        <div :class="$style.formContainer">
            <h6 :class="[$style.title, !isPassSet && $style.unpadded]">
                {{ isPassSet ? 'Change Password' : 'Set Password' }}
            </h6>
            <label
                v-if="!isPassSet"
                :class="$style.subtitle"
            >Setting a password will allow you to login using your email.</label>
            <form
                :class="$style.form"
                @submit.prevent="checkPassword"
            >
                <div
                    v-if="isPassSet"
                    :class="$style.fieldContainer"
                >
                    <label :class="$style.label">Current Password</label>
                    <BaseInput
                        v-model="password"
                        :class="[$style.field, $v.password.$error && $style.error]"
                        type="password"
                        autocomplete="password"
                        placeholder="enter current password"
                        @blur.native="$v.password.$touch()"
                    />
                </div>
                <div :class="$style.fieldContainer">
                    <label :class="$style.label">New Password</label>
                    <BaseInput
                        v-model="newPassword"
                        :class="[$style.field, $v.newPassword.$error && $style.error]"
                        type="password"
                        autocomplete=""
                        placeholder="enter new password"
                        @blur.native="$v.newPassword.$touch()"
                    />
                </div>
                <div :class="$style.fieldContainer">
                    <label :class="$style.label">Confirm New Password</label>
                    <BaseInput
                        v-model="newPasswordConfirmation"
                        :class="[$style.field, $v.newPasswordConfirmation.$error && $style.error]"
                        type="password"
                        autocomplete=""
                        placeholder="enter new password again"
                        @blur.native="$v.newPasswordConfirmation.$touch()"
                    />
                </div>
                <div :class="$style.buttonsContainer">
                    <div :class="$style.spacer" />
                    <div>
                        <BaseButton
                            :class="$style.button"
                            :modifiers="{ fill: true }"
                            button-type="highlight"
                            type="submit"
                            @click="() => {}"
                        >{{ isPassSet ? 'CHANGE PASSWORD' : 'SET PASSWORD' }}</BaseButton>
                        <BaseButton
                            :class="$style.button"
                            :modifiers="{ fill: true }"
                            button-type="nadir"
                            type="button"
                            @click="redirectToAccountPage"
                        >CANCEL</BaseButton>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.title {
    &.unpadded {
        margin-bottom: 0;
    }
}

.subtitle {
    display: block;
    margin-bottom: $s-xxl;

    @extend %contentSubtitleM;
}

.formContainer {
    max-width: 648px;

    .button {
        margin: $s-s 0 0;
    }
}

.label {
    @extend %contentSubtitleM;
}

.field {
    &.error {
        border-color: $c-punch-red;
        border-style: solid;
        border-width: 2px;
    }
}

@include tablet {
    .fieldContainer {
        display: grid;
        grid-template-columns: 2fr 5fr;
        align-items: center;
        margin-bottom: $s-s;
        .field {
            margin-bottom: 0;
        }
    }
    .buttonsContainer {
        display: grid;
        grid-template-columns: 2fr 5fr;
    }
}
</style>
