var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.container},[_c('div',{class:[_vm.$style.row, _vm.displayMinimalVersion && _vm.$style.minimal]},[(!_vm.displayMinimalVersion)?_c('BaseTabPanel',{class:_vm.$style.tabNavigation,attrs:{"tabs":_vm.tabs,"address-navigation":"","is-underlined":"","is-unpadded":""}},[(_vm.$refs.carousel && _vm.realCarouselPageCount > 1)?_c('div',{class:[_vm.$style.carouselNavigation]},[_c('span',{class:[_vm.$style.carouselNavIcon, { [_vm.$style.faded]: _vm.$refs.carousel.currentPage === 0 }],on:{"click":function($event){$event.preventDefault();return _vm.prevSlide()}}},[_c('BaseIcon',{class:[_vm.$style.icon, _vm.$style.iconArrowLeft],attrs:{"name":"arrow-long-left"}})],1),_c('span',{class:[
                        _vm.$style.carouselNavIcon,
                        { [_vm.$style.faded]: _vm.$refs.carousel.currentPage === _vm.totalCarouselPages - 1 },
                    ],on:{"click":function($event){$event.preventDefault();return _vm.nextSlide()}}},[_c('BaseIcon',{class:[_vm.$style.icon, _vm.$style.iconArrowRight],attrs:{"name":"arrow-long-right"}})],1)]):_vm._e()]):_vm._e(),_c('carousel',{ref:"carousel",class:[_vm.$style.carousel, 'carousel', _vm.displayMinimalVersion && _vm.$style.minimal],attrs:{"per-page-custom":_vm.carouselGrid,"pagination-enabled":false,"navigation-enabled":false,"resistance-coef":50,"scroll-per-page":false,"min-swipe-distance":100,"space-padding":12},on:{"pageChange":_vm.currentCarPage}},_vm._l((_vm.computedChannels),function(channel,index){return _c('slide',{key:channel.id,class:_vm.$style.slide},[_c('ChannelDetailTile',{class:[
                        _vm.$style.item,
                        {
                            [_vm.$style.faded]:
                                _vm.status === 'playing' && _vm.resource.id !== channel.id && _vm.resource.id !== _vm.mix.id,
                        },
                        _vm.displayMinimalVersion && _vm.$style.minimal,
                    ],attrs:{"channel":channel,"index":index,"playlist":_vm.playlists[channel.id],"display-minimal-version":_vm.displayMinimalVersion,"active-slide":_vm.activeSlide}})],1)}),1),(_vm.computedChannels.length === 0 && _vm.isFavorites && !_vm.displayMinimalVersion)?_c('BaseSection',{attrs:{"padded":""}},[_c('div',{class:_vm.$style.message},[_c('h4',{class:_vm.$style.messageTitle},[_vm._v(" "+_vm._s(_vm.$m('NO_FAVORITE_CHANNEL'))+" ")]),_c('p',{class:_vm.$style.messageParagraph},[_vm._v(" "+_vm._s(_vm.$m('DISCOVER_NEW_CHANNELS'))+" ")]),_c('BaseLink',{attrs:{"to":{ name: 'channel-index' }}},[_c('BaseButton',{attrs:{"icon":"signal"}},[_vm._v(" Discover New Channels ")])],1)],1)]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }