<script>
import DataLayer from '@services/data-layer/index'
import MixGridBlock from '@components/mix-grid-block'

export default {
    name: 'BrowseRecentlyPlayedMixes',
    components: { MixGridBlock },
    data() {
        return {
            mixes: [],
            LIMIT: 24,
            offset: 0,
            isFull: false,
        }
    },
    page() {
        const title = 'Recently Played Mixes'
        const description = 'Recently Played Mixes'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    computed: {
        recentlyPlayedMixes() {
            return (
                this.$store.state.playback &&
                this.$store.state.playback.history &&
                this.$store.state.playback.history.reduce(
                    (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
                    []
                )
            )
        },
    },
    watch: {
        recentlyPlayedMixes: {
            handler() {
                this.getMixes()
            },
        },
    },
    mounted() {
        this.getMixes()
    },
    methods: {
        getMixes() {
            const promises =
                this.recentlyPlayedMixes &&
                this.recentlyPlayedMixes.length &&
                this.recentlyPlayedMixes.map(id => DataLayer.mixes.getById(id))
            promises &&
                promises.length &&
                Promise.all(promises).then(values => {
                    let mixes = []
                    values.map((res, i) => mixes.push(res.body))
                    this.mixes = mixes
                })
        },
    },
}
</script>

<template>
    <BaseSection
        v-if="mixes && mixes.length"
        padded
    >
        <MixGridBlock :mixes="mixes" />
    </BaseSection>
</template>

<style lang="scss" module>
@import '@design';
</style>
