import localSave from '@utils/local-save'
import Emitter from 'component-emitter'
import DataLayer from './index'

const types = {
    mixes: {
        type: 'mixes',
        capitalized: 'Mixes',
        singular: 'mix',
    },
    artists: {
        type: 'artists',
        capitalized: 'Artists',
        singular: 'artist',
    },
    shows: {
        type: 'shows',
        capitalized: 'Shows',
        singular: 'show',
    },
}

export default function favoritesCreator({ type = 'mixes', api, storage, resources = {} }) {
    // mixes, Mixes, mix,
    // artists, Artists, artist
    const calls = types[type]

    let events = Emitter({})

    const on = function (event, arg) {
        events.on(event, arg)
    }

    const getCurrentUserId = function () {
        return localSave.get('auth.currentUser').members_id.id
    }

    const generateObject = function (resourceId, userId = getCurrentUserId()) {
        const returnObj = {
            members_id: {
                id: userId,
                model: 'Members',
                link: `v3/members/${userId}`,
            },
        }

        returnObj[`${calls.type}_id`] = {
            id: resourceId,
            model: calls.capitalized,
            link: `v3/${calls.type}/${resourceId}`,
        }
        return returnObj
    }

    const errorHandler = function (error) {
        const status = (error && error.response && error.response.status) || null
        const message = status === '409' ? window.$m('FAV_ALREADY_ADDED', { '{type}': calls.singular }) : null
        message && events.emit('displayError', { message, type: 'warning' })
    }

    let initPromise = null

    const init = function () {
        initPromise = new Promise(resolve =>
            api[`fav${calls.capitalized}`].get(getCurrentUserId()).then(array => {
                let data = array.data || []
                if (data && data.length > 0) {
                    data.map(el => {
                        el.id = el[`${calls.type}_id`].id
                    })
                }
                storage[`fav${calls.capitalized}`].update(data)
                events.emit(`updateFav${calls.capitalized}`, [...data])
                resolve(data)
            })
        )
        return initPromise
    }

    const add = function (resourceId) {
        const content = generateObject(resourceId)
        return api[`fav${calls.capitalized}`].post(getCurrentUserId(), content).then(
            _success => {
                addToList(resourceId)
            },
            error => {
                errorHandler(error)
            }
        )
    }

    const remove = function (resourceId) {
        let data = []
        return api[`fav${calls.capitalized}`].delete(getCurrentUserId(), resourceId).then(
            _success => {
                storage[`fav${calls.capitalized}`].remove(resourceId)
                data = [...storage[`fav${calls.capitalized}`].get().array]
                events.emit(`updateFav${calls.capitalized}`, data)
            },
            error => {
                errorHandler(error)
            }
        )
    }

    const addToList = function (resourceId) {
        let data = []
        let content = generateObject(resourceId)
        content.id = resourceId
        storage[`fav${calls.capitalized}`].join([content], true)
        data = [...storage[`fav${calls.capitalized}`].get().array]
        events.emit(`updateFav${calls.capitalized}`, data)
    }

    const get = async function (limit = 30) {
        await initPromise
        const favoriteArray = [...storage[`fav${calls.capitalized}`].get().array.slice(0, limit)].map(el => {
            // eslint-disable-next-line
            let { members_id, ...favoriteWithoutMemberId } = el
            return favoriteWithoutMemberId
        })
        return DataLayer.$wrap(() =>
            Promise.resolve({
                meta: {
                    model: `Favorite${calls.capitalized}`,
                },
                data: favoriteArray,
            })
        )()
    }

    return {
        on,
        init,
        add,
        remove,
        get,
    }
}
