const SocialPrefixes = {
    FACEBOOK: 'https://facebook.com/',
    TWIITER: 'https://twitter.com/',
    INSTAGRAM: 'https://instagram.com/',
    TIKTOK: 'https://www.tiktok.com/@',
}

Object.freeze(SocialPrefixes)

export default SocialPrefixes
