import makeAsyncQueue from '@utils/make-async-queue'

const Promise = require('bluebird')

export const extendableAttributes = [
    {
        expandedAttr: 'show',
        attr: 'show_id',
    },
    {
        expandedAttr: 'episode',
        attr: 'episode_id',
    },
    {
        expandedAttr: 'artist',
        attr: 'artist_id',
    },
    {
        expandedAttr: 'mix',
        attr: 'mixes_id',
    },
]

export default function createBaseResourceCreator({ storage, api, resources }) {
    return function (name, modelName) {
        const resourceQueue = []
        const fetchDelayQueue = {}
        function resourceQueueFind(id) {
            return resourceQueue.findIndex(el => el.id === id)
        }

        const fetchResource = makeAsyncQueue(idObj => {
            const promiseObj = {
                id: idObj.id,
                promise: api[name].get(idObj).then(
                    value => {
                        resourceQueue.splice(resourceQueueFind(idObj.id), 1)
                        return {
                            value: grab(idObj.id),
                            promise: null,
                        }
                    },
                    () => {
                        resourceQueue.splice(resourceQueueFind(idObj.id), 1)
                    }
                ),
            }

            resourceQueue.push(promiseObj)
            return promiseObj.promise
        })

        function isExtended(item) {
            return extendableAttributes.reduce(
                (acc, value) => acc && (!item[value.attr] || !!item[value.expandedAttr]),
                true
            )
        }

        function isExtendedList(list) {
            return list.reduce((acc, item) => acc && isExtended(item), true)
        }

        function extendResource(item) {
            return (
                item && {
                    ...item,
                    show: item['show_id'] ? resources.shows.grabFlat(item['show_id'].id) : null,
                    artist: item['artist_id'] ? resources.artists.grabFlat(item['artist_id'].id) : null,
                    episode: item['episode_id'] ? resources.episodes.grabFlat(item['episode_id'].id) : null,
                }
            )
        }

        function grab(id) {
            return extendResource(storage[name].get(id))
        }

        const getPromise = () => (resourceQueue.length > 0 ? Promise.any(resourceQueue.map(el => el.promise)) : null)

        const returnObj = {
            get(idObj) {
                const item = this.grab(idObj.id)
                if (item && !fetchDelayQueue[idObj.link]) {
                    fetchDelayQueue[idObj.link] = true
                    setTimeout(() => {
                        fetchDelayQueue[idObj.link] = false
                    }, process.env.VUE_APP_MODEL_REFRESH_TIME)

                    return {
                        value: item,
                        promise: fetchResource(idObj.id, idObj),
                    }
                }
                return {
                    value: item,
                    promise: item ? null : fetchResource(idObj.id, idObj),
                }
            },
            getById(id) {
                const idObj = {
                    id,
                    model: modelName,
                    link: `v3/${name}/${id}`,
                }
                return this.get(idObj)
            },
            getRelated(item) {
                const returnObj = {}
                if (item['show_id']) {
                    returnObj.show = resources.shows.get(item['show_id']).value
                }
                if (item['episode_id']) {
                    returnObj.episode = resources.episodes.get(item['episode_id']).value
                }
                if (item['artist_id']) {
                    returnObj.artist = resources.artists.get(item['artist_id']).value
                }
                if (item['mix_id']) {
                    returnObj.mix = resources.mixes.get(item['mix_id']).value
                }
                return returnObj
            },
            grab,
            grabFlat(id) {
                return storage[name].get(id)
            },
            getExtended() {},
            getPromise,
            isExtended,
            isExtendedList,
        }

        returnObj.trending = (function () {
            let isComplete = false
            const trendingStorage = {}

            const getPromise = function (channelId) {
                let promise = trendingStorage[channelId] && trendingStorage[channelId].dataPromise
                if (!promise) {
                    promise = resources.shows.getPromise()
                }
                if (!promise) {
                    promise = resources.artists.getPromise()
                }
                return promise
            }

            return {
                getPromise,
                isComplete() {
                    return isComplete
                },
                fetch(channelId) {
                    const found = trendingStorage[channelId]
                    if (found) {
                        return found.dataPromise
                    }

                    const dataPromise = new Promise(resolve => {
                        api[name].getTrending(channelId).then(arr => {
                            storage[name].join(arr)
                            arr.forEach(item => {
                                returnObj.getRelated(item)
                            })
                            trendingStorage[channelId].array = arr
                            trendingStorage[channelId].dataPromise = null
                            resolve(arr.map(({ id }) => returnObj.grab(id)))
                        })
                    })
                    trendingStorage[channelId] = {
                        id: channelId,
                        dataPromise,
                        array: [],
                    }
                    return dataPromise
                },
                grab(channelId) {
                    const trendingItems = (trendingStorage[channelId] && trendingStorage[channelId].array) || []

                    const trendingMapped = trendingItems.map(el => {
                        return returnObj.grab(el.id)
                    })

                    isComplete = returnObj.isExtendedList(trendingMapped)
                    return trendingMapped
                },

                get(channelId) {
                    if (!channelId) return false

                    return {
                        value: this.grab(),
                        promise: (this.fetch(channelId) || new Promise(resolve => resolve())).then(() => {
                            return this.grab()
                        }),
                    }
                },
            }
        })()

        return returnObj
    }
}
