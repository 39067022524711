<script>
import formatDate from '@utils/format-date-full'
import MixOptions from '@components/mix/mix-options'

export default {
    components: { MixOptions },
    props: {
        mix: {
            type: Object,
            default: () => {},
        },
        channel: {
            type: Object,
            default: () => {},
            required: false,
        },
        extended: {
            type: Boolean,
            default: false,
        },
        hasTypeTitle: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        airDate() {
            if (this.mix.episode) {
                const date = new Date(this.mix.episode.air_start)
                return formatDate(date)
            }
            return ''
        },
        playableMix() {
            return this.channel ? false : this.mix
        },
    },
}
</script>

<template>
    <div v-if="mix">
        <div :class="[$style.container, extended && $style.containerExtended]">
            <div :class="$style.imageWrap">
                <div :class="[$style.image, { [$style.classics]: channel && channel.id === 'classics' }]">
                    <BaseResourceCover
                        :image-resource="mix.episode"
                        :media-resource="mix"
                        :class="$style.image"
                        media-type="mix"
                    >
                        <div slot="secondaryActions">
                            <MixOptions :mix="mix" />
                        </div>
                    </BaseResourceCover>
                </div>
            </div>
            <div :class="$style.main">
                <BaseLink
                    v-if="mix.show && mix.show.title"
                    :to="{ name: 'show', params: { id: mix.show.id } }"
                >
                    <div :class="$style.title">
                        {{ mix.show && mix.show.title }}
                    </div>
                </BaseLink>
                <BasePlaceholder
                    v-else
                    :class="$style.title"
                    :text="mix.show && mix.show.title"
                    type="show"
                    tag="div"
                />
                <BaseLink :to="{ name: 'mix', params: { id: mix.id } }">
                    <BasePlaceholder
                        :class="$style.date"
                        :text="airDate"
                        :size="8"
                        class="date"
                        tag="div"
                    />
                </BaseLink>
                <div
                    :class="$style.subtitle"
                    class="subtitle"
                >
                    <BaseLink
                        v-if="mix.artist"
                        :to="{ path: `/artist/${mix.artist.url}/${mix.artist.id}` }"
                    >
                        {{ mix.artist.title }}
                    </BaseLink>
                    <BasePlaceholder
                        v-else
                        :text="mix.artist && mix.artist.title"
                        type="artist"
                    />
                </div>
                <div :class="$style.inner">
                    <BaseFavourites
                        v-if="mix"
                        :class="$style.innerItem"
                        :mix="mix"
                    />
                </div>
                <span :class="$style.playButton">
                    <slot name="button">
                        <BaseButton
                            :class="$style.mixPlayButton"
                            button-type="secondary"
                            @click="$queueService.playMix(mix)"
                        >
                            <span
                                :class="$style.buttonTitle"
                                title="PLAY"
                            >PLAY</span>
                        </BaseButton>
                    </slot>
                </span>
            </div>
            <div
                v-if="hasTypeTitle"
                :class="$style.typeTitle"
            >Mix</div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.imageWrap {
    position: relative;
    grid-area: image;
    width: span(4 of 12 narrow);
    max-width: 200px;
    margin-bottom: auto;

    &::after {
        display: block;
        width: 100%;
        padding-bottom: 100%;
        content: '';
    }

    @include tablet {
        width: span(2 of 12 narrow);
    }
}

.main {
    display: flex;
    flex-direction: column;
    grid-area: main;
    margin-left: gutter(of 12 narrow);
}

.inner {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    gap: $s-s;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $s-xl;
    .innerItem ~ .innerItem {
        margin-right: 0;
        margin-left: 0;
    }
}

.container {
    display: flex;

    &Extended {
        display: block;
        text-align: center;

        .main {
            width: 100%;
            margin: $s-xl 0 0;
        }
        .imageWrap {
            width: 168px;
            margin: 0 auto;
        }
        .inner {
            margin-right: auto;
            margin-left: auto;
        }

        @include tablet {
            display: grid;
            grid-template-areas: 'image main' 'typeTitle null';
            grid-template-columns: max-content max-content;
            grid-gap: $s-xl;
            align-items: center;
            .main {
                margin-top: unset;
                text-align: left;
            }
            .mixPlayButton {
                margin-bottom: 0;
            }
        }
    }

    @include tablet {
        flex-wrap: none;
    }
}

.image {
    @include fill;
    &.classics {
        filter: grayscale(100%);
    }
}

.title {
    @extend %contentTitleL;

    margin-top: $s-xxs;
    margin-bottom: $s-s;
}

.date {
    @extend %contentTitleM;

    margin-bottom: $s-xxs;
}

.playButton {
    display: none;

    @include desktop {
        display: flex;
        flex-direction: column;
    }
}

.subtitle {
    @extend %contentSubtitleM;

    margin-bottom: $s-m;
}

.typeTitle {
    @extend %contentTitleM;

    text-align: center;
}
</style>
