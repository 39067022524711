<script>
import DataLayer from '@services/data-layer/index'
import Top from '@components/search/top'
import Mixes from '@components/search/mixes'
import Genres from '@components/search/genres'
import Artists from '@components/search/artists'
import Shows from '@components/search/shows'
import Tracklist from '@components/search/tracklist'
import uniqueFromArray from '@utils/unique-from-array'

export default {
    components: { Top, Mixes, Genres, Artists, Shows, Tracklist },
    props: {
        mainComponent: {
            type: String,
            default: 'Top',
        },
    },
    data() {
        return {
            searchResults: {},
            bestMatchType: '',
            bestMatchModel: {},
            searchComplete: null,
        }
    },
    computed: {
        hasLength() {
            const results = this.searchResults || {}
            const length =
                results &&
                Object.keys(results).reduce((total, key) => {
                    return total + results[key].length
                }, 0)
            return !!length
        },
        keyword() {
            return this.$store.state.search.keyword.replace(/ /g, '_')
        },
        tabs() {
            return [
                {
                    to: {
                        name: 'search/results/top',
                        params: { keyword: this.keyword },
                    },
                    label: 'Top Results',
                },
                {
                    to: {
                        name: 'search/results/mixes',
                        params: { keyword: this.keyword },
                    },
                    label: 'Mixes',
                },
                {
                    to: {
                        name: 'search/results/genres',
                        params: { keyword: this.keyword },
                    },
                    label: 'Genres',
                },
                {
                    to: {
                        name: 'search/results/artists',
                        params: { keyword: this.keyword },
                    },
                    label: 'Artists',
                },
                {
                    to: {
                        name: 'search/results/shows',
                        params: { keyword: this.keyword },
                    },
                    label: 'Shows',
                },
                {
                    to: {
                        name: 'search/results/tracklist',
                        params: { keyword: this.keyword },
                    },
                    label: 'Tracklist',
                },
            ]
        },
    },
    watch: {
        keyword() {
            this.keyword && this.elasticSearch()
        },
    },
    mounted() {
        this.keyword && this.elasticSearch()
    },
    methods: {
        elasticSearch() {
            this.searchComplete = false
            DataLayer.elastic.search(this.keyword.replace(/_/g, ' ') || '').then(data => {
                this.searchResults = {
                    Artists: (data.body && data.body.Artists) || [],
                    Shows: (data.body && data.body.Shows) || [],
                    Episodes: (data.body && data.body.Episodes) || [],
                    Mixes: (data.body && data.body.Mixes) || [],
                }
                this.bestMatchType = data.headers['x-search-best-match'] || ''
                this.bestMatchModel =
                    this.bestMatchType && this.bestMatchType === 'Genres'
                        ? this.searchResults.Mixes
                        : this.bestMatchType && this.searchResults[this.bestMatchType]
                            ? (this.bestMatchModel = this.searchResults[this.bestMatchType][0])
                            : undefined
                this.searchComplete = true
            })
        },
        searchJoin(limit, offset, resource = 'Mixes') {
            // Artists, Mixes, Shows, Episodes

            return DataLayer.elastic.search(this.keyword.replace(/_/g, ' ') || '', limit, offset).then(data => {
                const resourceLength = this.searchResults[resource].length
                this.searchResults.Artists.push(
                    ...((data.body && uniqueFromArray(data.body.Artists, this.searchResults.Artists)) || [])
                )
                this.searchResults.Shows.push(
                    ...((data.body && uniqueFromArray(data.body.Shows, this.searchResults.Shows)) || [])
                )
                this.searchResults.Episodes.push(
                    ...((data.body && uniqueFromArray(data.body.Episodes, this.searchResults.Episodes)) || [])
                )
                this.searchResults.Mixes.push(...((data.body && data.body.Mixes) || []))

                if (resourceLength === this.searchResults[resource].length) {
                    return resourceLength + 1 // causes infinity scrolling to stop
                } else {
                    return this.searchResults.Mixes.length
                }
            })
        },
    },
}
</script>

<template>
    <div
        v-if="keyword && keyword.length > 0 && hasLength && searchComplete"
        :class="$style.baseSection"
    >
        <BaseTabPanel
            :tabs="tabs"
            :class="$style.searchTabPanel"
            is-underlined
            is-unpadded
            address-navigation
        />
        <component
            :is="mainComponent"
            :class="$style.tabArea"
            :search-results="searchResults"
            :search-join="searchJoin"
            :best-match-type="bestMatchType"
            :best-match-model="bestMatchModel"
        />
    </div>

    <BaseSection
        v-else-if="keyword && keyword.length && !hasLength && searchComplete"
        padded
    >
        <div :class="$style.notFound">
            <BaseIcon
                :class="[$style.icon, $style.notFoundIcon]"
                name="flag"
            />
            <div :class="$style.notFoundHead">No results found for "{{ keyword.replace(/_/g, ' ') }}"</div>
            <div :class="$style.notFoundSub">
                <span>Please make sure your words are spelled correctly or use less words</span>
            </div>
        </div>
    </BaseSection>
</template>

<style lang="scss" module>
@import '@design';

.icon {
    fill: $c-white;
}

.notFound {
    @include center;
    @include sidePadding;

    width: 100%;
    text-align: center;

    @include desktop {
        width: auto;
        max-width: 570px;
    }

    &Icon {
        width: 64px;
        height: 64px;
        margin-bottom: $spacing-small;
    }

    &Head {
        @extend %h4;

        margin-bottom: $s-xs;
    }

    &Sub {
        @extend %contentTinyHeading;
    }
}
.baseSection {
    @include mobileOnly {
        padding: 0;
    }
}
.searchTabPanel {
    padding-top: $s-s;
}

.tabArea {
    padding-top: $s-xxl;
}
</style>
