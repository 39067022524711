import Emitter from 'component-emitter'
import getAndUpdateRecursive from '@utils/get-and-update-recursive'
import messages from '@data/messages'
import Queue from '@data/models/Queue'
import analyticsService from './analytics-service'
import settingsService from './settings-service'
import { onDemandQualityOptions  as qualityOptions } from './../configuration'

const DEFAULT_ON_DEMAND_QUALITY = '96'

export default ({ dataLayer, playerService }) => {
    const events = Emitter({})

    // minimum we need to know --- current resource -- for easier timekeeping
    const data = {
        resource: null,
        mix: null,
        show: null,
        queue: new Queue(),
        activePlayingId: null,
    }

    const playback = {
        duration: null,
        airStart: null,
        airEnd: null,
    }

    let statusObj = {}

    const on = (event, arg) => events.on(event, arg)
    const off = event => events.on(event)

    const emitDataUpdate = () => events.emit('dataUpdate', data)

    const emitErrorUpdate = ({ message, type }) => events.emit('errorUpdate', { message, type })

    // currently forwarding values from playerService - determine if we transform time in some way for radio playback ()
    const emitPlayerUpdate = val => {
        statusObj = val
        events.emit('statusUpdate', val)
    }

    const timeUpdateHandler = val => {
        emitPlayerUpdate(val)
        val.currentTime >= val.duration - 1000 && playNext()
    }

    playerService.on('statusUpdate', emitPlayerUpdate)
    playerService.on('timeUpdate', emitPlayerUpdate)

    function _trackPremiumUpsell() {
        analyticsService.premiumUpsellInitiated()
    }

    const playMix = async (mix, startTime = 0, shouldClearQueue = true, type = 'mix') => {
        const currentOnDemandQualityKey = await settingsService.getCurrentOnDemandQualityKey()
        const userQuality = qualityOptions[currentOnDemandQualityKey].value || DEFAULT_ON_DEMAND_QUALITY

        if (data.mix && data.mix.id !== mix.id) playerService.stop()

        shouldClearQueue && clearQueue()

        return new Promise((resolve, reject) => {
            if (dataLayer.api.token.get()) {
                data.mix = data.resource = mix
                data.resource.type = type
                data.activePlayingId = data.mix.id
                playback.duration = mix.duration

                playerService.off('timeUpdate')

                playerService.on('timeUpdate', val => {
                    timeUpdateHandler(val)
                })

                emitDataUpdate()

                dataLayer.api
                    .get(`v3/mixes/${mix.id}/urls?quality=${userQuality}`)
                    .then(({ data }) => {
                        playerService.play(data.url, startTime, 'mix')
                        analyticsService.playMedia({ event: 'Mix Play', mix: data.resource.title })
                        resolve(true)
                    })
                    .catch(e => {
                        if (e.response && e.response.status === 403) {
                            data.mix = data.resource = null
                            playback.duration = -1
                            clearQueue()

                            emitDataUpdate()
                            _trackPremiumUpsell({})
                            emitErrorUpdate({ message: messages.PLAYER_PREMIUM_ERROR, type: 'warning' })
                        }
                    })
            } else {
                reject(new Error(messages.PLAYER_PREMIUM_ERROR))
            }
        })
    }

    const playRadioChannel = channelId => {
        let playbackInitiated = false
        if (!channelId) return false
        clearQueue()
        data.activePlayingId = channelId
        let currentRetries = 0

        const auth = JSON.parse(localStorage.getItem('auth.currentUser'))
        const token = auth && auth.token ? auth.token : ''

        const channelRequest = channelId => dataLayer.channels.getExtended(channelId)

        const checkStreamError = async (station, selectMount, currentToken) => {
            const { mount, server } = await selectMount
            currentToken = currentToken ? `token=${currentToken}&` : ''
            try {
                await dataLayer.api.get(
                    `/v3/subscriptions/validate-streaming?${currentToken}station=${station}&mount=${mount}`
                )
                updateChannelInfo()
                analyticsService.playMedia({ event: 'Radio Play', channel: data.resource.title })
                return server
            } catch (e) {
                handleError(e)
            }
        }

        const busyServerHandler = () => {
            emitErrorUpdate({ message: messages.SERVER_BUSY_TRY_AGAIN, type: 'warning' })
        }

        const selectStreamServer = async servers => {
            const currentOnDemandQualityKey = await settingsService.getCurrentOnDemandQualityKey()
            const userQuality = qualityOptions[currentOnDemandQualityKey].value || DEFAULT_ON_DEMAND_QUALITY
            const requestQuality = Number(userQuality) >= 320 ? 'high' : userQuality >= 128 ?  'mid' : 'low'
            servers = JSON.parse(JSON.stringify(servers))
            if (!servers) return
            const MAX_RETRIES = 4
            const options = Object.keys(servers).filter(key => key.includes(requestQuality))
            if (currentRetries > MAX_RETRIES) return busyServerHandler()
            const mount = options[currentRetries % options.length]
            const selectedServer = servers[mount].urls[0]
            currentRetries++
            return { server: selectedServer, mount }
        }

        const playRequest = async () => {
            if (playbackInitiated) return
            const channelDetails = await channelRequest(channelId)
            const server = await checkStreamError(
                channelId,
                selectStreamServer(channelDetails.value.servers.items),
                token
            )
            await playerService.play(`${server}${token ? '?token=' + token : ''}`, 0, 'radio')
            updateChannelInfo()
            playbackInitiated = true
        }

        const handleError = error => {
            data.resource = null
            playback.duration = -1
            clearQueue()
            emitDataUpdate()

            switch (error.response.status) {
            case 401:
                emitErrorUpdate({ message: messages.PLAYER_PREMIUM_ERROR, type: 'warning' })
                break

            case 403:
                emitErrorUpdate({ message: messages.PLAYER_LOGIN_SIGNUP, type: 'warning' })
                break

            case 503:
                playRequest()
                break

            default:
                emitErrorUpdate({ message: messages.SOMETHING_WENT_WRONG, type: 'warning' })
                break
            }
        }

        const handleDataUpdate = async val => {
            if (data.activePlayingId !== channelId) return false
            if (!(val && val.servers)) return false
            data.resource = val
            const nowPlaying = (val.nowPlaying && val.nowPlaying[0]) || {}
            data.show = nowPlaying.show
            data.mix = nowPlaying.mix
            playback.duration = nowPlaying.duration
            playback.airStart = nowPlaying['air_time']
            playback.airEnd = nowPlaying['air_end']
            emitDataUpdate()
            return true
        }

        const updateChannelInfo = () => getAndUpdateRecursive(handleDataUpdate, channelRequest(channelId))

        playerService.off('timeUpdate')

        playerService.on('timeUpdate', val => emitPlayerUpdate(val))

        return playRequest()
    }

    const playMixMultiple = ({ mixes, position = 0, id = null }) => {
        if (data.queue && data.queue.playlistId === id && data.queue.position === position) return pause()

        createQueue({ mixes, position, id })
        playMix(mixes[position], 0, false, 'playlist')
    }

    const playPrevious = () => {
        if (!data.queue || !data.queue.mixes.length || data.queue.position <= 0) return

        data.queue.position--
        playMix(data.queue.mixes[data.queue.position], 0, false, 'playlist')
    }

    const playNext = () => {
        if (!data.queue || !data.queue.mixes.length || data.queue.position >= data.queue.mixes.length - 1) return

        data.queue.position++
        playMix(data.queue.mixes[data.queue.position], 0, false, 'playlist')
    }

    const addToQueue = ({ mixes, queuePosition, playlistId = null }) => {
        const queue = data.queue ? [...data.queue] : new Queue()
        queue.playlistId = playlistId
        queue.mixes.insert(queuePosition, [...mixes])
        data.queue = queue
        localStorage.setItem('activeQueue', JSON.stringify(queue))
    }

    const createQueue = ({ mixes, position, id }) => {
        const queue = new Queue(mixes, position, id)
        data.queue = queue
        localStorage.setItem('activeQueue', JSON.stringify(queue))
    }

    const clearQueue = () => {
        const queue = new Queue()
        data.queue = queue
        localStorage.setItem('activeQueue', JSON.stringify(queue))
    }

    const pause = () => {
        if (data.resource && data.resource.type === 'channel') {
            if (statusObj.status === 'stopped') {
                playRadioChannel(data.resource.id)
            } else {
                playerService.stop()
            }
        } else {
            playerService.pause()
        }
    }

    const setVolume = volume => {}

    const seekTime = time => {}

    const setQueuePosition = position => {
        data.queue.position = position
    }

    const remove = queuePosition => {}

    const setModifier = modifierObject => {}

    return {
        playMix,
        playRadioChannel,
        playMixMultiple,
        playPrevious,
        playNext,
        addToQueue,
        clearQueue,
        setQueuePosition,
        pause,
        setVolume,
        seekTime,
        remove,
        setModifier,
        on,
        off,
    }
}
