<script>
import DataLayer from '@services/data-layer/index'
import ChannelDetailBlock from '@components/channel/channel-detail-block'

export default {
    name: 'HomepageRadio',
    components: { ChannelDetailBlock },
    data() {
        return {
            channels: [],
            playlists: [],
        }
    },
    mounted() {
        const channelsOrder = ['chill', 'classics', 'deep', 'frisky']

        DataLayer.channels.get().then(response => {
            this.channels = Object.values(response.body).sort(
                (a, b) => channelsOrder.indexOf(a.id) - channelsOrder.indexOf(b.id)
            )
        })
        DataLayer.stationsPlaylists.subscribe(resposne => {
            this.playlists = resposne.body.reduce((playlists, item) => {
                if (!playlists[item.station]) playlists[item.station] = []
                playlists[item.station].push(item)
                return playlists
            }, {})
        })
    },
}
</script>

<template>
    <div
        v-if="channels && channels.length"
        :class="$style.section"
    >
        <BaseSection
            title="Radio"
            padded
        >
            <ChannelDetailBlock
                :channels="channels"
                :playlists="playlists"
                display-minimal-version
            />
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.section {
    margin-top: $s-xxl;
}
</style>
