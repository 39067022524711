export const state = {
    mixesList: [],
    artistsList: [],
    showsList: [],
}

export const getters = {}

export const mutations = {
    UPDATE_FAVORITE_MIXES(state, data) {
        if (data) {
            state.mixesList = data
        }
    },
    UPDATE_FAVORITE_ARTISTS(state, data) {
        if (data) {
            state.artistsList = data
        }
    },
    UPDATE_FAVORITE_SHOWS(state, data) {
        if (data) {
            state.showsList = data
        }
    },
}

export const actions = {}
