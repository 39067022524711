

<script>
import { centsToDollars } from '../../../../utils/format-price'
import formatDate from '../../../../utils/format-date-full'
import DataLayer from './../../../../services/data-layer/index'
import analyticsService from './../../../../services/analytics-service'

const premiumService = DataLayer.subscriptions

const STATUS_MAP = {
    active: 'Active',
    canceled: 'Canceled',
    paused: 'Paused',
}

export default {
    data() {
        return {
            statusMap: STATUS_MAP,
            hasResumeSucceeded: false,
            hasResumeFailed: false,
        }
    },
    computed: {
        currentSubscription() {
            return this.$store.getters['premium/currentSubscription']
        },
        activeSubscription() {
            return this.$store.getters['premium/activeSubscription']
        },
        paymentMethod() {
            return this.$store.getters['premium/paymentMethod']
        },
        memberId() {
            return this.$store.getters['member/currentMember'].id
        },
        hasPaidViaMobile() {
            return this.$store.getters['premium/hasPaidViaMobile']
        },
    },
    methods: {
        centsToDollars,
        formatDate,
        async handleSubscriptionUpdate() {
            const subscriptions = await premiumService.getSubscriptionsByMemberId(this.memberId)
            this.$store.commit('premium/SET_SUBSCRIPTIONS', subscriptions.body)
            const activeSubscription = this.$store.getters['premium/activeSubscription']
            if(activeSubscription) {
                const transaction = await premiumService.getCheckoutById(activeSubscription.subscriptionsCheckoutId.id)
                this.$store.commit('premium/SET_ACTIVE_TRANSACTION', transaction.body)
            }else {
                this.$store.commit('premium/SET_ACTIVE_TRANSACTION', null)
            }
        },
        resumePausedSubscription() {
            premiumService.resumeSubscription(this.currentSubscription?.id)
                .then(async () => {
                    this.hasResumeSucceeded = true
                    await this.handleSubscriptionUpdate()
                })
                .catch(() => {
                    this.hasResumeFailed = true
                })
        },
        onPremiumUpgradeButtonClick() {
            analyticsService.premiumUpsellInitiated('account-premium-upgrade-button')
        }
    },
}
</script>

<template>
    <BaseCard
        v-if="currentSubscription && currentSubscription.plan"
        class="card"
    >
        <h3 class="title">FRISKY {{ currentSubscription.plan.title }}<span v-if="currentSubscription?.status !== 'active'"> - {{ statusMap[currentSubscription.status] }}</span></h3>
        <div
            v-if="currentSubscription.plan.price > 0"
        >
            <p class="details">
                Unlimited, ad-free streaming
                <br>
                On-demand listening
                <br>
                Premium hi-res audio quality
                <br>
                Premium-exclusive mixes
                <br>
                Listen on mobile and external players
            </p>
            <div
                v-if="currentSubscription.status === 'active'"
                class="additionalDetails"
            >
                <h4 class="paymentHeading">Payment</h4>
                <div class="nextBill">
                    <div v-if="!hasPaidViaMobile">
                        Your next bill is for {{ centsToDollars(currentSubscription.plan.price) }} on <strong>{{ formatDate(new Date(currentSubscription.endTime)) }}</strong>.
                    </div>
                    <br>
                    <div v-if="paymentMethod && paymentMethod !== 'manual'">
                        <div
                            v-if="paymentMethod === 'stripe'"
                            class="paymentMethod"
                        >
                            <BaseIcon
                                class="paymentMethodIcon"
                                name="credit-card"
                            /> using Credit Card
                        </div>
                        <div
                            v-else-if="paymentMethod === 'paypal'"
                            class="paymentMethod"
                        >
                            <BaseIcon
                                class="paymentMethodIcon"
                                name="paypal"
                            /> using Paypal
                        </div>
                        <div
                            v-if="hasPaidViaMobile"
                            class="paymentMethod"
                        >
                            Via the FRISKY mobile app. <br>
                            To manage your subscription go to Settings on your mobile device.<br><br>
                        </div>

                    </div>
                </div>
            </div>
            <br>
            <div
                v-if="currentSubscription.status === 'canceled'"
                class="additionalDetails"
            >
                <div class="nextBill">
                    Your plan is canceled. You will lose access to premium features on <strong>{{ formatDate(new Date(currentSubscription.endTime)) }}</strong>.
                </div>
            </div>
            <div
                v-if="currentSubscription.status === 'paused'"
                class="additionalDetails"
            >
                <div class="nextBill">
                    Your plan has been paused. Your subcription will resume on <strong>{{ formatDate(new Date(currentSubscription.renewTime)) }}</strong>.
                </div>
            </div>

        </div>
        <div
            v-else
            class="details"
        >
            Radio stream radio channels
            <br>
            Occasional ads
            <br>
        </div>
        <div
            v-if="!hasPaidViaMobile"
            slot="footer"
            class="actions"
        >
            <router-link
                v-if="currentSubscription.status !== 'paused'"
                :to="{ path: '/account/premium', hash: '#select-plan' }"
            >
                <BaseButton
                    button-type="highlight"
                    size="large"
                    @click="onPremiumUpgradeButtonClick"
                >{{ currentSubscription.plan.price > 0 ? 'CHANGE PLAN' : 'UPGRADE' }}</BaseButton>
            </router-link>
            <BaseButton
                v-else
                button-type="highlight"
                size="large"
                @click="resumePausedSubscription"
            >RESUME PREMIUM</BaseButton>
        </div>
        <BaseModal
            :show="hasResumeSucceeded"
            size="large"
            @close="hasResumeSucceeded = false"
        >
            <h4>Welcome back to FRISKY Premium!</h4>
            <p>Your Premium has been started and your payment method has been charged.</p>
        </BaseModal>
        <BaseModal
            :show="hasResumeFailed"
            size="large"
            @close="hasResumeFailed = false"
        >
            <h3>FAILED</h3>
            <p>Please try again later!</p>
        </BaseModal>
    </BaseCard>
</template>

<style lang="scss" scoped>
@import '@design';

.card {
    min-height: 500px;
}

.title {
    @extend %h4;

    margin: 0;
    font-weight: 400;
}

.details {
    margin-top: $s-m;
    line-height: 2;
    color: $c-text-faded;
}

.paymentHeading {
    @extend %h6;

    margin: $s-xl 0 0;
}

.nextBill {
    @extend %contentSubtitleL;

    margin-top: $s-xs;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: $s-l;
    align-items: center;
    margin-top: $s-xl;
}

.paymentMethod {
    display: flex;
    gap: $s-xs;
    align-items: center;
    margin-top: $s-xs;
}

.paymentMethodIcon {
    width: 30px;
    height: 30px;
    fill: $c-white;
}

@include desktop {
    .card {
        min-height: 404px;
    }

    .actions {
        flex-direction: row;
    }
}
</style>
