<script>
import { centsToDollars } from './../../../../utils/format-price'
import { trialDays } from './../../../../configuration'

export default {
    name: 'SubscriptionTile',

    props: {
        plan: {
            type: Object,
            default: () => {},
        },
        currentSubscription: {
            type: Object,
            default: () => {},
        },
        isLoggedIn: {
            type: Boolean,
            default: false,
        },
        isEntitledFreeTrial: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            trialDays,
        }
    },

    computed: {
        isCurrentPlan() {
            if (!this.currentSubscription) return false
            return this.plan?.id === this.currentSubscription?.subscriptionsProductsId.id
        },
        isAnUpgrade() {
            if (!this.currentSubscription) return false
            return this.plan?.id > this.currentSubscription?.subscriptionsProductsId.id
        },
    },

    methods: {
        centsToDollars,
        actionText(durationTrialMonths) {
            if (!this.isLoggedIn) return 'GET STARTED'
            if (this.isCurrentPlan) return 'YOUR ACTIVE PLAN'
            if (this.isEntitledFreeTrial && durationTrialMonths) return 'START FREE TRIAL'
            else return 'UPGRADE'
        },
    },
}
</script>

<template>
    <div :class="$style.tile">
        <BaseSection>
            <h2 :class="$style.title">{{ plan.title }}</h2>
            <h3 :class="$style.price">{{ centsToDollars(plan.price / plan.durationMonths) }} / month</h3>
            <p
                v-if="plan.durationMonths === 12"
                :class="$style.subtitle"
            >
                {{ centsToDollars(plan.price) }} billed once a year.
            </p>
            <ul :class="$style.salesPoints">
                <li
                    v-if="plan.durationMonths === 12"
                    :class="[$style.salesPoint, $style.highlight]"
                >Best value!</li>
                <li :class="$style.salesPoint">High quality audio streaming</li>
                <li :class="$style.salesPoint">Ad-free</li>
                <li :class="$style.salesPoint">On-demand streaming</li>
                <li :class="$style.salesPoint">
                    Offline listening (downloads) via the FRISKY iOS or Android app
                </li>
                <li :class="$style.salesPoint">Early access to mixes before they air on the free audio streams</li>
                <li :class="$style.salesPoint">Support for external players like SONOS, Winamp, VLC, etc.</li>
                <br v-if="!isLoggedIn && plan.durationMonths === 1">
                <li
                    v-if="!isLoggedIn && plan.durationMonths === 1"
                    :class="$style.salesPoint"
                >{{ trialDays }}-day free trial (if you haven't yet tried Premium). Cancel anytime.</li>
            </ul>
            <div :class="$style.action">
                <BaseButton
                    :button-type="plan.current ? 'tag' : 'highlight'"
                    :modifiers="{ fill: true, noMargins: true }"
                    :disabled="isCurrentPlan"
                    :aria-disabled="plan.current"
                    @click="$emit('onAction', plan)"
                >{{ actionText(plan.durationTrialMonths) }}</BaseButton>
            </div>
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@use '@design' as *;

.tile {
    position: relative;
    width: 330px;
    min-height: 425px;
    padding: 0 $s-xl;
    text-align: left;
    background: $c-black-grey-gradient;
    border-radius: 6px;
    box-shadow: 0 12px 24px 0 rgba(16, 17, 24, 0.27);

    &:nth-of-type(1) {
        background: $c-alt-bg;
    }
}

.title {
    margin: 0 0 $s-s;
    font-size: 28px;
    font-weight: 400;
    line-height: 24px;
}

.price {
    margin: 0 0 $s-xxs;
    font-size: 20px;
    font-weight: 300;
    line-height: 1;
}

.subtitle {
    margin: 0;
    font-size: 9px;
    line-height: 1;
}

.action {
    position: absolute;
    right: 0;
    bottom: $s-xl;
    left: 0;
    width: 100%;
    padding: 0 $s-l;
    margin: 0 auto;
}

.salesPoints {
    @extend %contentSubtitleM;

    padding: 0;
    margin-top: $s-xl;
    list-style: none;
}

.salesPoint {
    font-size: 11px;
    font-weight: 300;
    line-height: 1.2;

    &.highlight {
        font-weight: 400;
        color: $c-white;
    }
}

.salesPoint ~ .salesPoint {
    margin-top: $s-s;
}

@include tablet {
    .tile {
        padding-top: $s-xl;
    }

    .salesPoints {
        margin-top: $s-xxl;
    }
}
</style>
