<script>
import DataLayer from '@services/data-layer/index'
import Layout from '@layouts/main'

import Overview from '@views/channels/channel.overview'
import Schedule from '@views/channels/channel.schedule'
import History from '@views/channels/channel.history'

export default {
    components: { Layout, Overview, Schedule, History },
    props: {
        mainComponent: {
            type: String,
            default: 'Overview',
        },
    },
    page() {
        const title = this.channel.title ? `Radio ${this.mainComponent} - ${this.channel.title}` : `Radio - Channel`
        const description = this.channel.title
            ? `Radio ${this.mainComponent} - ${this.channel.title}`
            : `Radio - Channel`
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    data() {
        return {
            channel: {},
            nowPlaying: { id: 0 },
            trendingMixes: [],
            trendingArtists: [],
        }
    },
    computed: {
        tabs() {
            const channelId = this.$route.params.channelId
            return channelId === 'classics'
                ? [
                    {
                        to: { name: 'channel', params: { channelId } },
                        label: 'Overview',
                    },
                    {
                        to: { name: 'channel/history', params: { channelId } },
                        label: 'History',
                    },
                ]
                : [
                    {
                        to: { name: 'channel', params: { channelId } },
                        label: 'Overview',
                    },
                    {
                        to: { name: 'channel/schedule', params: { channelId } },
                        label: 'Show Schedule',
                    },
                    {
                        to: { name: 'channel/history', params: { channelId } },
                        label: 'History',
                    },
                ]
        },
        resource() {
            return {
                ...this.channel,
                type: 'channel',
                nowPlaying: this.nowPlaying,
            }
        },
    },
    mounted() {
        Promise.all([
            DataLayer.channels.get(),
            DataLayer.mixes.getPopularByChannel(this.$route.params.channelId),
            DataLayer.artists.getPopularByChannel(this.$route.params.channelId),
        ]).then(([channels, trendingMixes, trendingArtists]) => {
            this.channel = Object.values(channels.body).find(channel => channel.id === this.$route.params.channelId)
            this.trendingMixes = trendingMixes.body
            this.trendingArtists = trendingArtists.body
        })

        DataLayer.stationsPlaylists.subscribe(response => {
            this.nowPlaying = response.body.filter(item => item.station === this.$route.params.channelId)[0]
        })
    },
}
</script>

<template>
    <Layout>
        <BaseHero
            :resource="resource"
            keep-alive
        />
        <BaseTabPanel
            :tabs="tabs"
            address-navigation
        />
        <component
            :is="mainComponent"
            :channel="resource"
            :now-playing="nowPlaying"
            :trending-mixes="trendingMixes"
            :trending-artists="trendingArtists"
        />
    </Layout>
</template>
