<script>
import Layout from '@layouts/login'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import localSave from '@utils/local-save'

export default {
    page() {
        const title = 'Request Password Reset'
        const description = 'Request Password Reset'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    components: { Layout },
    mixins: [validationMixin],
    data() {
        return {
            email: '',
            checking: false,
        }
    },
    validations: {
        email: {
            required,
            email,
        },
    },
    methods: {
        requestPasswordReset() {
            if (!this.$v.$invalid) {
                this.checking = true
                return this.$dataLayer.api
                    .resetPasswordRequest({ email: this.email })
                    .then(res => this._successHandler(res))
                    .catch(error => this._errorHandler(error))
            } else {
                this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('EMAIL_REQUIRED'),
                    type: 'warning',
                })
                this.$v.$reset()
            }
        },
        _successHandler(_res) {
            this.$store.dispatch('appearance/handleNotification', {
                message: this.$m('EMAIL_RESET_LINK_SENT'),
                type: 'success',
            })
            this._redirectHandler()
        },
        _errorHandler(error) {
            if (error.response.status === 404) {
                this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('EMAIL_NOT_FOUND'),
                    type: 'danger',
                })
                this._clearFields()
            } else {
                this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('SOMETHING_WENT_WRONG'),
                    type: 'danger',
                })
            }
            this.checking = false
        },
        _redirectHandler() {
            const redirectURL = localSave.get('redirectURL') || '/'
            this.$router.push({ path: redirectURL })
        },
        _clearFields() {
            this.email = ''
            this.$v.$reset()
        },
    },
}
</script>

<template>
    <Layout>
        <BaseContent>
            <BaseSection padded>
                <div :class="$style.formContainer">
                    <h4 :class="$style.title">Password Reset</h4>
                    <h6 :class="$style.subtitle">
                        Enter your email that you used to register with FRISKY. We'll send you an email with a link to
                        reset your password.
                    </h6>
                    <form
                        :class="$style.form"
                        @submit.prevent="requestPasswordReset"
                    >
                        <div :class="$style.fieldContainer">
                            <BaseInput
                                v-model="email"
                                :class="[$style.field, $v.email.$error && $style.error]"
                                name="email"
                                autocomplete="email"
                                placeholder="email*"
                                @blur.native="$v.email.$touch()"
                            />
                            <label
                                v-if="$v.email.$error"
                                :class="[$style.label, $style.error]"
                            >This field is required and must be an email</label>
                        </div>
                        <BaseButton
                            :class="$style.button"
                            :disabled="checking"
                            :modifiers="{ fill: true }"
                            button-type="highlight"
                            type="submit"
                            @click="() => {}"
                        >Send</BaseButton>
                    </form>
                </div>
            </BaseSection>
        </BaseContent>
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.formContainer {
    position: relative;
    margin-bottom: $s-h;
    .field {
        &.error {
            margin-bottom: $s-xl;
            border-color: $c-punch-red;
            border-width: 2px;
        }
    }
}
.fieldContainer {
    position: relative;
    width: 100%;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.label {
    @extend %contentSubtitleM;

    display: block;
    font-weight: 300;
    text-align: left;

    &.error {
        position: absolute;
        bottom: $s-xs;
        color: $c-punch-red;
    }
}
.title {
    text-align: left;
}
.subtitle {
    @extend %contentTinyHeading;

    font-weight: 400;
    text-align: left;
}

.button {
    margin-top: $s-xl;
}
</style>
