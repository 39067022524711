<script>
import DataLayer from '@services/data-layer/index'
import MixGridBlock from '@components/mix-grid-block'

export default {
    name: 'BrowseLatestMixes',
    components: { MixGridBlock },
    data() {
        return {
            mixes: [],
            LIMIT: 30,
            offset: 0,
            isFull: false,
            isLoading: false,
        }
    },
    page() {
        const title = 'Latest Mixes'
        const description = 'Latest Mixes'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    computed: {
        scroll() {
            return this.$store.state.appearance.scroll
        },
        scrollChild() {
            return this.$store.state.appearance.scrollChild
        },
    },
    watch: {
        scroll() {
            if (!this.isFull && !this.isLoading) {
                let bottomOfWindow =
                    this.scroll + window.innerHeight >=
                    this.$store.state.appearance.scrollChild.offsetHeight - window.innerHeight / 3
                if (bottomOfWindow) {
                    this.offset += this.LIMIT
                    this.loadMixes(this.offset)
                }
            }
        },
    },
    mounted() {
        this.loadMixes(this.offset)
    },
    methods: {
        loadMixes(offset) {
            this.isLoading = true
            DataLayer.mixes.getLatest(this.LIMIT, offset).then(mixes => {
                if (!mixes.body.length) {
                    this.isFull = true
                } else {
                    this.mixes.push(...mixes.body)
                }
                this.isLoading = false
            })
        },
    },
}
</script>

<template>
    <BaseSection
        v-if="mixes && mixes.length"
        padded
    >
        <MixGridBlock :mixes="mixes" />
    </BaseSection>
</template>

<style lang="scss" module>
@import '@design';
</style>
