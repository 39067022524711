<script>
export default {
    props: {
        timeFraction: {
            type: Number,
            default: 0,
        },
        duration: {
            type: Number,
            default: 100,
        },
    },
    data() {
        return {
            bars: Array.from({ length: 140 }, () => Math.min(1, Math.random() + 0.1)),
        }
    },
    computed: {
        invertedFraction() {
            return 1 - this.timeFraction
        },
    },
    methods: {
        seekTime({ offsetX, srcElement }) {
            this.$playerService.seekTime((offsetX / srcElement.offsetWidth) * this.duration)
        },
    },
}
</script>

<template>
    <div :class="$style.container">
        <div
            :class="$style.waveform"
            @click.stop.prevent="seekTime"
        >
            <div :class="$style.waveformInner">
                <div
                    v-for="(i, index) in bars"
                    :key="index"
                    :class="[$style.bar, $style.barBg]"
                    :style="{ transform: `scaleY(${i})` }"
                />
            </div>

            <div
                :class="$style.waveformInner"
                :style="{ transform: `translateX(${invertedFraction * -100}%)` }"
            >
                <div
                    :class="[$style.waveformInner, $style.waveformInnerActive]"
                    :style="{ transform: `translateX(${invertedFraction * 100}%)` }"
                >
                    <div
                        v-for="(i, index) in bars"
                        :key="index"
                        :class="$style.bar"
                        :style="{ transform: `scaleY(${i})` }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.waveform {
    @include fill;

    display: flex;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;
    background-color: $c-shark;

    @include desktop {
        background: none;
    }

    &Inner {
        @include fill;

        display: flex;
        justify-content: space-between;
        overflow: hidden;
        pointer-events: none;
        backface-visibility: hidden;

        &Active {
            background-color: $c-white;

            @include desktop {
                background: none;
            }
        }
    }
}

.bar {
    display: none;
    width: 2px;
    height: 100%;
    background: $c-highlight;

    @include desktop {
        display: block;
    }
}
.barBg {
    background-color: $c-shark;
}
</style>
