const Promise = require('bluebird')

export default function retryPromiseCreator({
    conditionFc = () => true, // function
    time = 100, // ms to retry first
    timeCap = 60 * 5 * 1000, // cap time for retries
    successFc = () => {}, // function to call in event of success
    failureFc = () => {},
    maxRepeats = 10,
}) {
    let repeat = 0
    let usedPromise = null
    return function (arg) {
        if (!conditionFc(arg)) {
            repeat = 0
            successFc(arg)
            return null
        }

        if (repeat > maxRepeats) {
            if (repeat === maxRepeats + 1) failureFc()
            repeat++
            return null
        }

        usedPromise = new Promise(resolve => {
            repeat++
            setTimeout(() => resolve(), Math.min(timeCap, time * Math.pow(2, repeat)))
        })
        return usedPromise
    }
}
