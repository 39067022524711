<script>
import Layout from '@layouts/main'

export default {
    page() {
        const title = '404 - Error Page'
        const description = `The page you're trying to view, doesn't exist`
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    components: { Layout },
    props: {
        resource: {
            type: String,
            default: '',
        },
    },
}
</script>

<template>
    <Layout>
        <h1 :class="$style.title">
            404
            <span v-if="resource">
                {{ resource }}
            </span>
            Not Found
        </h1>
    </Layout>
</template>

<style lang="scss" module>
.title {
    text-align: center;
}
</style>
