import localSave from '@utils/local-save'
import DataLayer from '../../services/data-layer/index'

export const state = {
    // need to check if we have user logged in first
    currentMember: {},
    passState: null,
    isLoading: true,
}

export const mutations = {
    SET_CURRENT_MEMBER(state, newValue) {
        state.currentMember = newValue
        localSave.save('user.currentMember', newValue)
    },
    SET_PASS_STATE(state, val) {
        state.passState = val
    },
    SET_LOADING(state, val) {
        state.isLoading = val
    }
}
export const actions = {
    async init({ rootState, state, commit, rootGetters }) {
        if (rootState.auth.currentUser && rootState.auth.currentUser.members_id) {
            if(localSave.get('user.currentMember')) commit('SET_CURRENT_MEMBER', localSave.get('user.currentMember'))
            const membersId = rootState.auth.currentUser.members_id // for now fetch regularly then replace by the api
            DataLayer.members.getById({ id: membersId.id, includePasswordState: true }).then(({ body, headers }) => {
                commit('SET_CURRENT_MEMBER', body)
                commit('SET_PASS_STATE', headers['x-password-state'])
                commit('SET_LOADING', false)
            })
        }
    },
    destroy({ commit }) {
        commit('SET_CURRENT_MEMBER', null)
        commit('SET_PASS_STATE', null)
    },
    updateUser({ commit }, data) {
        commit('SET_CURRENT_MEMBER', data)
    }
}

export const getters = {
    premium(state) {
        return state.currentMember && state.currentMember.role === 7
    },
    superAdmin(state) {
        return state.currentMember && state.currentMember.role === 1
    },
    email(state) {
        return state.currentMember && state.currentMember.email
    },
    currentMember(state) {
        return state.currentMember
    }
}
