<script>
import SocialPrefixes from '@enums/social-prefixes'
export default {
    props: {
        artist: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            prefixes: SocialPrefixes,
        }
    },
}
</script>

<template>
    <BaseContent>
        <BaseSection
            padded
            title="Biography"
        >
            <div v-if="artist.biography && artist.biography.length">
                <p
                    :class="$style.info"
                    v-html="artist.biography"
                />
            </div>
            <div v-else>
                <p>This artist does not have a bio yet.</p>
            </div>
            <div :class="$style.socialMedia">
                <BaseLink
                    v-if="artist.facebook_username && artist.facebook_username.length"
                    :href="`${prefixes.FACEBOOK}${artist.facebook_username}`"
                    :class="$style.iconContainer"
                >
                    <BaseIcon
                        :class="$style.icon"
                        name="facebook"
                    />
                </BaseLink>
                <BaseLink
                    v-if="artist.instagram_username && artist.instagram_username.length"
                    :href="`${prefixes.INSTAGRAM}${artist.instagram_username}`"
                    :class="$style.iconContainer"
                >
                    <BaseIcon
                        :class="$style.icon"
                        name="instagram"
                    />
                </BaseLink>
                <BaseLink
                    v-if="artist.twitter_username && artist.twitter_username.length"
                    :href="`${prefixes.TWIITER}${artist.twitter_username}`"
                    :class="$style.iconContainer"
                >
                    <BaseIcon
                        :class="$style.icon"
                        name="twitter"
                    />
                </BaseLink>
                <BaseLink
                    v-if="artist.tiktok_username && artist.tiktok_username.length"
                    :href="`${prefixes.TIKTOK}${artist.tiktok_username}`"
                    :class="$style.iconContainer"
                >
                    <BaseIcon
                        :class="$style.icon"
                        name="tiktok"
                    />
                </BaseLink>
                <BaseLink
                    v-if="artist.website_url && artist.website_url.length"
                    :href="artist.website_url"
                    :class="$style.iconContainer"
                >
                    <BaseIcon
                        :class="$style.icon"
                        name="earth"
                    />
                </BaseLink>
            </div>
        </BaseSection>
    </BaseContent>
</template>

<style lang="scss" module>
@import '@design';

.info {
    columns: 320px 2;
    column-gap: $s-m * 2;
    white-space: pre-line;
}

.socialMedia {
    padding-top: $s-xl;
}

.iconContainer {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    padding: $s-xs;
    overflow: hidden;
    border: 1px solid $c-white;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 transparentize($c-black, $amount: 0.5);
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $layer-negative-z-index;
        width: 100%;
        height: 100%;
        content: '';
        background: $c-button-bg;
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    &:hover {
        border-color: transparent;
        &::before {
            opacity: 1;
        }
    }
}

.iconContainer ~ .iconContainer {
    margin-left: $s-m;
}

.icon {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    fill: $c-white;
}
</style>
