export default class Queue {
    mixes
    position
    playlistId

    constructor(mixes = [], position = -1, playlistId = null) {
        this.mixes = mixes
        this.position = position
        this.playlistId = playlistId
    }
}
