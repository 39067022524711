<script>
import { centsToDollars } from '../../../utils/format-price'
import formatDate  from './../../../utils/format-date-full'
import CurrentPlanCard from './partials/current-plan-card.vue'
import DataLayer from './../../../services/data-layer/index'
import analyticsService from './../../../services/analytics-service'

const premiumService = DataLayer.subscriptions

export default {
    page() {
        const title = 'Account - Subscription'
        const description = 'Account - Subscription'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    components: { CurrentPlanCard },
    data() {
        return {
            isCancelDialogOpen: false,
            isPauseSuccessDialogOpen: false,
            isPauseErrorDialogOpen: false,
            isCancelSuccessDialogOpen: false,
            isCancelErrorDialogOpen: false,
            isCancelConfirmationDialogOpen: false,
        }
    },
    computed: {
        memberId() {
            return this.$store.getters['member/currentMember'].id
        },
        products() {
            let products = [...this.$store.state.premium.plans]

            // if the current subscription is canceled, don't show the free plan
            if(this.currentSubscription.status === 'canceled') products = products.filter(product => product.price)

            // if current subscription is active or free, don't show plans with the same price
            else products = products.filter(product => product.price !== this.currentSubscription.plan.price)

            return products.reverse()
        },
        currentSubscription() {
            return this.$store.getters['premium/currentSubscription']
        },
        isUserQualifiedForPause() {
            return this.$store.getters['premium/isUserQualifiedForPause']
        },
        currentSubscriptionPrice() {
            return this.centsToDollars(this.currentSubscription?.plan?.price)
        },
        hasPaidViaMobile() {
            return this.$store.getters['premium/hasPaidViaMobile']
        },
    },
    methods: {
        centsToDollars,
        formatDate,
        attemtCancelSubscription() {
            this.isCancelDialogOpen = true
        },
        async handleSubscriptionUpdate() {
            const subscriptions = await premiumService.getSubscriptionsByMemberId(this.memberId)
            this.$store.commit('premium/SET_SUBSCRIPTIONS', subscriptions.body)
            const activeSubscription = this.$store.getters['premium/activeSubscription']

            if(activeSubscription) {
                const transaction = await premiumService.getCheckoutById(activeSubscription.subscriptionsCheckoutId.id)
                this.$store.commit('premium/SET_ACTIVE_TRANSACTION', transaction.body)
            }
            else this.$store.commit('premium/SET_ACTIVE_TRANSACTION', null)
        },
        cancelSubscription() {
            premiumService.cancelSubscription(this.currentSubscription.id)
                .then(async () => {
                    await this.handleSubscriptionUpdate()
                    this.isCancelConfirmationDialogOpen = false
                    this.isCancelDialogOpen = false
                    this.isCancelSuccessDialogOpen = true
                })
                .catch(() => {
                    this.isCancelConfirmationDialogOpen = false
                    this.isCancelDialogOpen = false
                    this.isCancelErrorDialogOpen = true
                })
        },
        pauseSubscription() {
            premiumService.pauseSubscription(this.currentSubscription.id)
                .then(async () => {
                    this.isCancelDialogOpen = false
                    this.isPauseSuccessDialogOpen = true
                    await this.handleSubscriptionUpdate()
                })
                .catch(() => {
                    this.isCancelDialogOpen = false
                    this.isPauseErrorDialogOpen = true
                })
        },
        confirmCancelSubscription() {
            this.isCancelDialogOpen = false
            this.isCancelConfirmationDialogOpen = true
        },
        onSelectPlanClick(planId) {
            analyticsService.premiumUpsellPlanSelected(planId)
        },
    },
}
</script>

<template>
    <div>
        <h1 class="title">FRISKY Premium</h1>
        <section class="section">
            <h2 class="sectionHeader">Your Plan</h2>
            <CurrentPlanCard />
        </section>
        <section
            class="section select-plan-section"
        >
            <div
                id="select-plan"
                class="off-canvas-ref"
            />
            <h2 class="sectionHeader">Available Plans</h2>
            <BaseCard
                v-for="product of products"
                :key="'product-id' + product.id"
                class="product"
            >
                <h3 class="productTitle">FRISKY {{ product.title }}</h3>
                <div
                    v-if="product.price > 0"
                >
                    <p class="productDetails">
                        <strong
                            v-if="product.durationMonths === 12"
                            class="highlight"
                        >Best Value!<br></strong>
                        Unlimited, ad-free streaming
                        <br>
                        On-demand listening
                        <br>
                        Premium hi-res audio quality
                        <br>
                        Premium-exclusive mixes
                        <br>
                        Listen on mobile and external players
                    </p>
                </div>
                <div
                    v-else
                >
                    <p class="productDetails">
                        Switching to Free will cancel your Premium plan
                        <br>
                        Enjoy music in low-resolution audio with ads
                    </p>
                </div>
                <div
                    slot="footer"
                    class="productActions"
                >
                    <div class="price">
                        {{ product.price === 0 ? 'FREE' : (centsToDollars(product.price / product.durationMonths) + ' / month') }}
                        <div
                            v-if="product.durationMonths === 12"
                            class="priceSubtitle"
                        >
                            {{ centsToDollars(product.price) }} billed once a year
                        </div>
                    </div>
                    <div v-if="!hasPaidViaMobile">
                        <router-link :to="{ path: `/premium/select-payment/${product.id}` }">
                            <BaseButton
                                v-if="product.price > 0"
                                button-type="highlight"
                                size="large"
                                @click="onSelectPlanClick(product.id)"
                            >{{ product.price > currentSubscription.plan.price ? 'UPGRADE' : 'SELECT PLAN' }}</BaseButton>
                        </router-link>
                        <BaseButton
                            v-if="product.price === 0 && currentSubscription.plan.price > 0"
                            button-type="highlight"
                            size="large"
                            @click="attemtCancelSubscription"
                        >
                            CANCEL PREMIUM
                        </BaseButton>
                    </div>
                </div>
            </BaseCard>
        </section>
        <BaseModal
            :show="isCancelDialogOpen"
            size="large"
            @close="isCancelDialogOpen = false"
        >
            <div class="cancelModalContainer">
                <div
                    v-if="!isUserQualifiedForPause"
                    class="cancelCase"
                >
                    Are you sure you want to cancel your Premium subscription?
                    <br>
                    <div class="actionButtons">
                        <BaseButton
                            button-type="highlight"
                            size="large"
                            @click="isCancelDialogOpen = false"
                        >
                            KEEP MY SUBSCRIPTION
                        </BaseButton>
                        <a @click="cancelSubscription">Yes, cancel my Premium Subscription</a>
                    </div>
                </div>
                <div
                    v-else
                    class="cancelCase"
                >
                    <h3>Too Much Music? Take a Break</h3>
                    <p>You can pause your Premium membership for 3 months so you can pick up right where you left off. You will not be charged until you return.</p>
                    <div class="actionButtons">
                        <BaseButton
                            button-type="highlight"
                            size="large"
                            @click="pauseSubscription"
                        >
                            PAUSE MY SUBSCRIPTION
                        </BaseButton>
                        <br>
                        <a @click="confirmCancelSubscription">No Thanks, I wish to cancel.</a>
                    </div>
                </div>
            </div>
        </BaseModal>
        <BaseModal
            :show="isCancelConfirmationDialogOpen"
            size="large"
            @close="isCancelConfirmationDialogOpen = false"
        >
            <h3>Are you sure you want to cancel your Premium subscription?</h3>
            <p>You will lose access to premium features on {{ formatDate(new Date(currentSubscription.endTime)) }}</p>
            <br>
            <div class="actionButtons">
                <BaseButton
                    button-type="highlight"
                    size="large"
                    @click="isCancelConfirmationDialogOpen = false"
                >
                    KEEP MY SUBSCRIPTION
                </BaseButton>
                <a @click="cancelSubscription">Cancel my subscription</a>
            </div>
        </BaseModal>
        <BaseModal
            :show="isPauseSuccessDialogOpen"
            size="large"
            @close="isPauseSuccessDialogOpen = false"
        >
            <h4>Thanks for pausing your subscription.</h4>
            <p>You will no longer be charged. Your Premium membership will resume and you will be billed {{ currentSubscriptionPrice }} on {{ formatDate(new Date(currentSubscription.renewTime)) }}</p>
        </BaseModal>
        <BaseModal
            :show="isPauseErrorDialogOpen"
            size="large"
            @close="isPauseErrorDialogOpen = false"
        >
            <h3>Something went wrong!</h3>
            <BaseButton
                button-type="highlight"
                size="large"
                @click="pauseSubscription"
            >
                RETRY
            </BaseButton>
        </BaseModal>
        <BaseModal
            :show="isCancelSuccessDialogOpen"
            size="large"
            @close="isCancelSuccessDialogOpen = false"
        >
            <h4>We're sorry to see you go!</h4>
            <p>Your subscription has been canceled and you will no longer be charged. Your Premium membership will remain active until {{ formatDate(new Date(currentSubscription.endTime)) }}</p>
        </BaseModal>
        <BaseModal
            :show="isCancelErrorDialogOpen"
            size="large"
            @close="isCancelErrorDialogOpen = false"
        >
            <h3>Something went wrong!</h3>
            <p>Your Premium membership has not been canceled. Please try again.</p>
            <BaseButton
                button-type="highlight"
                size="large"
                @click="cancelSubscription"
            >
                RETRY
            </BaseButton>
        </BaseModal>
    </div>
</template>

<style lang="scss" scoped>
@use '@design' as *;

.title {
    text-align: center;
}

.section {
    margin-top: $s-xl;

    &~.section {
        margin-top: $s-h;
    }
}

.select-plan-section {
    position: relative;
    min-height: 100vh;
}

.off-canvas-ref {
    position: absolute;
    top: -#{$size-menu-bar-height + $s-m};
}

.sectionHeader {
    @extend %h5;
}

.product {
    min-height: 467px;

    &.hidden {
        display: none;
    }
}

.product~.product {
    margin-top: $s-xl;
}

.productTitle {
    @extend %h4;

    margin: 0;
    font-weight: 400;
}

.productDetails {
    margin-top: $s-m;
    line-height: 2;
    color: $c-text-faded;

    .highlight {
        color: $c-white;
    }
}

.productActions {
    display: flex;
    flex-direction: column;
    gap: $s-l;
    align-items: center;
    justify-content: space-between;
    margin-top: $s-xl;
}

.price {
    font-size: 2rem;
    font-weight: 100;
    text-align: center;
}

.priceSubtitle {
    font-size: .75rem;
}

.cancelModalContainer {
    padding: $s-xl 0;
}

.cancelCase {
    display: flex;
    flex-flow: column;
    gap: $s-xxl;
}

.actionButtons {
    display: flex;
    flex-direction: column;
    gap: $s-xl;
    align-items: center;
    cursor: pointer;
}

@include tablet {
    .title {
        text-align: left;
    }
}

@include desktop {
    .productActions {
        flex-direction: row;
    }

    .price {
        text-align: left;
    }

    .actionButtons {
        flex-direction: row;
    }

}
</style>
