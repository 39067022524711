<script>
import DataLayer from '@services/data-layer/index'
import ShowScheduleMixBlock from '@components/show-schedule-mix-block'

export default {
    components: { ShowScheduleMixBlock },
    data() {
        return {
            stationsPlaylist: Array.from(Array(10), (e, i) => ({
                id: i,
                mixes: {
                    id: i,
                    show: {
                        id: '',
                        title: '',
                        url: '',
                        type: '',
                        image: {},
                        genre: [],
                        artist_id: {},
                        artist: {},
                    },
                },
            })),
        }
    },
    mounted() {
        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)

        DataLayer.stationsPlaylists.get(this.$route.params.channelId, yesterday, 60, 0).then(stationsPlaylist => {
            const now = new Date()
            this.stationsPlaylist = stationsPlaylist.body
                .filter(record => new Date(record.scheduled_start_time) < now)
                .reverse()
        })
    },
}
</script>

<template>
    <BaseContent>
        <BaseSection
            title="History"
            padded
        >
            <ShowScheduleMixBlock :resources="stationsPlaylist" />
        </BaseSection>
    </BaseContent>
</template>
