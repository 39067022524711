<script>
import analyticsService from '../../../services/analytics-service'

export default {
    name: 'DonationComplete',

    computed: {
        isSuccessful() {
            return this.$route.query['state'] === 'success'
        },
    },

    mounted() {
        this.trackDonationComplete()
    },

    methods: {
        trackDonationComplete() {
            analyticsService.donationUpsellCompleted({
                isSuccessful: this.isSuccessful,
            })
        },
    },
}
</script>

<template>
    <div :class="$style.welcome">
        <BaseSection
            v-if="isSuccessful"
            padded
        >
            <h1 :class="$style.mainHeader">Thank you for donating to <strong>FRISKY</strong></h1>
            <h6 :class="$style.description">Your contribution will help us stay on the air!</h6>
            <div :class="$style.buttons">
                <router-link
                    :class="$style.welcomeButton"
                    :to="{ name: 'home' }"
                >
                    <BaseButton
                        button-type="secondary"
                        @click="() => {}"
                    > BROWSE FRISKY </BaseButton>
                </router-link>
            </div>
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@use '@design' as *;

.mainHeader {
    margin-bottom: 100px;
    font-weight: 600;
    line-height: 1.25;
}

.description {
    margin-bottom: 50px;
    font-weight: 300;
    line-height: 1.5;

    @extend %heading;
}

@include desktop {
    .mainHeader {
        margin-bottom: 150px;
        font-size: $s-xxxl;
    }

    .description {
        margin-bottom: 100px;
    }
}
</style>
