<script>
import formatDate  from './../../../utils/format-date-full'
import CurrentPlanCard from './partials/current-plan-card'

export default {
    page() {
        const title = 'Account - Overview'
        const description = 'Account - Overview'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    components: { CurrentPlanCard },
    data() {
        return {
            hasResumeSucceeded: false,
            hasResumeFailed: false,
        }
    },
    computed: {
        user() {
            return { ...this.$store.state.member.currentMember }
        },
        currentSubscription() {
            return this.$store.getters['premium/currentSubscription']
        },
    },
    methods: {
        formatDate,
    }
}
</script>

<template>
    <div>
        <h1 class="title">Account Overview</h1>
        <section class="section">
            <h2 class="sectionHeader">Profile</h2>
            <table class="profileDetails">
                <tr class="detailsItem">
                    <td>Email</td>
                    <td>{{ user.email }}</td>
                </tr>
                <tr class="detailsItem">
                    <td>Name</td>
                    <td>{{ user.firstName }} {{ user.lastName }}</td>
                </tr>
                <tr
                    v-if="user.birthday"
                    class="detailsItem"
                >
                    <td>Date of birth</td>
                    <td>{{ formatDate(new Date(user.birthday)) }}</td>
                </tr>
            </table>
            <br>
            <router-link :to="{ name: 'account/edit-profile' }">
                <BaseButton
                    button-type="highlight"
                    @click="() => {}"
                >
                    Edit Profile
                </BaseButton>
            </router-link>
        </section>
        <section class="section">
            <h2 class="sectionHeader">Your Plan</h2>
            <CurrentPlanCard />
        </section>
    </div>
</template>

<style lang="scss" scoped>
@import '@design';

.title {
    text-align: center;
}

.section {
    margin-top: $s-xl;

    &~.section {
        margin-top: $s-h;
    }
}

.sectionHeader {
    @extend %h5;
}

.detailsItem {
    :nth-child(odd) {
        padding-right: $s-m;
        white-space: nowrap;
    }

    :nth-child(even) {
        color: $c-text-faded;
    }
}

@include tablet {
    .title {
        text-align: left;
    }

    .detailsItem {
        :nth-child(odd) {
            padding-right: $s-xxxl;
        }
    }
}
</style>
