<script>
export default {}
</script>

<template>
    <div :class="$style.signupPromo">
        <div :class="$style.description">
            <div :class="$style.title">FREE FRISKY PREVIEW</div>
            <div :class="$style.subtitle">
                Sign Up for free and listen to unlimited radio. Or upgrade your account to listen on-demand and much
                more!
            </div>
        </div>
        <BaseLink
            :class="$style.link"
            name="signup"
        >
            <div :class="$style.buttonContainer">
                <BaseButton
                    :modifiers="{ noMargins: true }"
                    :class="$style.button"
                    size="xlarge"
                    button-type="black"
                    @click="() => {}"
                >SIGN UP FREE</BaseButton>
            </div>
        </BaseLink>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.signupPromo {
    position: fixed;
    bottom: $size-music-player;
    left: 0;
    z-index: $layer-overlay-z-index;
    width: 100%;
    min-height: $size-signup-promo;
    padding: $s-xl $s-xxl;
    text-align: center;
    background: $c-purple-red-gradient;
}
.buttonContainer {
    margin-top: $s-m;
}
.title {
    @extend %contentTitleL;

    margin-bottom: $s-s;
}
.subtitle {
    @extend %contentSubtitleL;

    font-weight: 100;
}

.link {
    cursor: pointer;
}

@include desktop {
    .signupPromo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: unset;
    }
    .buttonContainer {
        margin-top: 0;
    }
}
</style>
