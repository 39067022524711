<script>
import analyticsService from '../../../services/analytics-service'
import { mapGetters } from 'vuex'

export default {
    name: 'PremiumComplete',
    computed: {
        ...mapGetters('premium', ['activeSubscription', 'paymentMethod']),
        isSuccessful() {
            return this.$route.query['state'] === 'success'
        },
    },
    watch: {
        paymentMethod() {
            if (this.paymentMethod && this.activeSubscription) this.track()
        },
    },
    methods: {
        track() {
            analyticsService.premiumUpsellCompleted({
                isSuccessful: this.isSuccessful,
                planId: this.activeSubscription?.plan?.id,
                amount: this.activeSubscription?.plan?.price,
                method: this.paymentMethod,
            })
        },
    },
}
</script>

<template>
    <div :class="$style.welcome">
        <BaseSection padded>
            <h1 :class="$style.mainHeader">
                {{ isSuccessful ? 'All Set!' : 'Ooops! Something went wrong.' }}
            </h1>
            <h6 :class="$style.description">
                {{
                    isSuccessful
                        ? 'Thanks for upgrading to Premium!'
                        : 'Please try again.'
                }}
            </h6>
            <div :class="$style.buttons">
                <router-link
                    v-if="!isSuccessful"
                    :class="$style.welcomeButton"
                    :to="{ name: 'home' }"
                >
                    <BaseButton
                        button-type="secondary"
                        @click="() => {}"
                    >{{ 'BROWSE FRISKY' }}</BaseButton>
                </router-link>
                <router-link
                    :class="$style.welcomeButton"
                    :to="{ name: isSuccessful ? 'home' : 'premium/view-plans' }"
                >
                    <BaseButton
                        button-type="highlight"
                        @click="() => {}"
                    >
                        {{ isSuccessful ? 'BEGIN LISTENING' : 'TRY AGAIN' }}
                    </BaseButton>
                </router-link>
            </div>
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@use '@design' as *;

.mainHeader {
    @include h3;

    letter-spacing: -0.5px;
}

.description {
    margin-bottom: 50px;
    font-weight: 300;
    line-height: 1.5;

    @extend %heading;
}

@include desktop {
    .mainHeader {
        @include h1;

        margin-bottom: $s-xxl;
    }

    .welcomeButton ~ .welcomeButton {
        margin-left: $s-l;
    }

    .description {
        margin-bottom: 100px;
    }
}


</style>
