<script>
export default {
    name: 'Logo',
    props: {
        isLinkedToHome: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: 's', // 'xs', 's', 'm', 'l'
        },
        hasLabel: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        logoText() {
            return process.env.VUE_APP_LOGO_TEXT || ''
        },
    },
}
</script>
<template>
    <div :class="[$style.logoContainer, $style[size]]">
        <BaseLink
            v-if="isLinkedToHome"
            :to="{ path: '/' }"
            :class="[$style.logo, $style.baseLink]"
        >
            Frisky
            <span
                v-if="hasLabel && logoText"
                :class="$style.logoText"
            >{{ logoText }}</span>
        </BaseLink>
        <div
            v-else
            :class="$style.logo"
        >
            Frisky
            <span
                v-if="hasLabel && logoText"
                :class="$style.logoText"
            >{{ logoText }}</span>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.logo {
    &:hover,
    &:active {
        text-decoration: none;
    }

    &:visited {
        color: $c-white;
    }

    &Container {
        @extend %h1;

        display: inline-block;
        margin-top: 0;
        font-weight: 900;
        text-transform: uppercase;
        vertical-align: bottom;
        &.xs {
            font-size: 20px;
            line-height: 20px;
        }
        &.s {
            font-size: 30px;
            line-height: 30px;
        }
        &.m {
            font-size: 50px;
            line-height: 50px;
        }
        &.l {
            font-size: 80px;
            line-height: 80px;
        }
    }

    &Text {
        position: relative;
        top: -4px;
        display: inline-block;
        padding: 1px 3px 1px 2px;
        font-size: 9px;
        font-weight: 500;
        line-height: 1;
        color: $c-black;
        text-transform: capitalize;
        vertical-align: bottom;
        background: lighten($c-emperor, 15%);
        border-radius: 3px;
    }
}
</style>
