<script>
import Layout from '@layouts/main'
import ArtistBlock from '@components/artist/artist-block'

export default {
    page() {
        const title = 'Your Music - Artists'
        const description = 'Your Music - Artists'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    components: { Layout, ArtistBlock },
    data() {
        return {
            favPopulatedList: Array.from(Array(6), (e, i) => ({
                artists: {
                    id: Number.MAX_SAFE_INTEGER - i,
                },
            })),
            shouldTransition: false,
            maxArtists: 30,
            increaseArtists: 15,
            shouldLoadMore: false,
        }
    },
    computed: {
        favList() {
            return this.$store.state.favorites.artistsList
        },
        artistsList() {
            return this.favPopulatedList.map(el => el.artists)
        },
        scroll() {
            return this.$store.state.appearance.scroll
        },
        scrollChild() {
            return this.$store.state.appearance.scrollChild
        },
    },
    watch: {
        favList: {
            handler() {
                this.$dataLayer.favArtists
                    .get(this.maxArtists)
                    .then(({ body }) => {
                        this.favPopulatedList = body
                        this.shouldLoadMore = true
                    })
                    .then(() => {
                        this.shouldTransition = true
                    })
            },
            immediate: true,
        },
        scroll() {
            let bottomOfWindow =
                this.scroll + window.innerHeight >=
                this.$store.state.appearance.scrollChild.offsetHeight - window.innerHeight / 3

            if (bottomOfWindow && this.shouldLoadMore) {
                this.shouldLoadMore = false
                setTimeout(() => {
                    this.shouldLoadMore = true
                }, 200)
                this.loadMoreArtists()
            }
        },
    },
    methods: {
        loadMoreArtists() {
            this.maxArtists += this.increaseArtists
            this.$dataLayer.favArtists.get(this.maxArtists).then(({ body }) => {
                this.favPopulatedList = body
                this.shouldLoadMore = true
            })
        },
    },
}
</script>

<template>
    <Layout>
        <BaseHero
            :class="$style.mainHeading"
            text="Artists"
        />
        <BaseContent>
            <BaseSection padded>
                <ArtistBlock
                    :artists="artistsList"
                    :error="{
                        title: `You have not favorited any artists`,
                        paragraph: `When you add artists to your favorites, they will appear here`,
                    }"
                    :should-transition="shouldTransition"
                    wrapping
                    wrapping-mobile
                    is-indexed-list
                />
            </BaseSection>
        </BaseContent>
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.mainHeading h1 {
    margin-top: 0;
}
</style>
