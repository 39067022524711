<script>
import TracklistTile from '@components/tracklist-tile'
export default {
    components: { TracklistTile },
    props: {
        tracklist: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            error: {
                paragraph: 'Tracklist for this Mix is unavailable',
            },
        }
    },
}
</script>

<template>
    <div>
        <TracklistTile
            v-for="(track, index) in tracklist"
            :key="`${track.title} + ${track.artist} + ${index}`"
            :track="track"
            :index="index + 1"
        />
        <BaseEmptyList
            v-if="tracklist.length === 0"
            :error="error"
        />
    </div>
</template>
