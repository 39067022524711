import localSave from '@utils/local-save'

export const state = {
    volume: localSave.get('volume') !== null ? localSave.get('volume') : 25,
    favorites: localSave.get('favorites') || [],
    currentOnDemandQualityKey: localSave.get('currentOnDemandQualityKey') || 'low',
}

export const getters = {}

export const mutations = {
    SET_SETTINGS(state, { volume, favorites, currentOnDemandQualityKey }) {
        if (volume !== undefined) {
            state.volume = volume
            localSave.save('volume', state.volume)
        }

        if (favorites !== undefined) {
            state.favorites = favorites
            localSave.save('favorites', state.favorites)
        }

        if (currentOnDemandQualityKey !== undefined) {
            state.currentOnDemandQualityKey = currentOnDemandQualityKey
            localSave.save('currentOnDemandQualityKey', currentOnDemandQualityKey)
        }
    },
}

export const actions = {
    toggleToFavorites({ commit, state }, channelId) {
        const foundIndex = state.favorites.findIndex(el => el === channelId)
        if (foundIndex > -1) {
            const favorites = [...state.favorites]
            favorites.splice(foundIndex, 1)
            commit('SET_SETTINGS', { favorites })
        } else {
            commit('SET_SETTINGS', { favorites: [...state.favorites, channelId] })
        }
    },
}
