<script>
export default {
    name: 'PremiumWelcome',

    computed: {
        isStandalone() {
            return this.$store.state.premium.isStandalone
        },
        entitledFreeTrial() {
            return !this.$store.getters['premium/hasEverBeenPremium']
        },
    },
}
</script>

<template>
    <div :class="$style.welcome">
        <div :class="[$style.welcomeBackground, isStandalone ? $style.standalone : '']" />
        <BaseSection padded>
            <h1 :class="$style.mainHeader">Your Music. Your Way.</h1>
            <div :class="$style.description">
                <p>Upgrade to Premium and take your listening experience to the next level:</p>
                <ul :class="$style.salesPoints">
                    <li>High quality audio streaming</li>
                    <li>Ad-free</li>
                    <li>On-demand streaming</li>
                    <li>Offline listening (downloads) via the FRISKY iOS or Android app</li>
                    <li>Early access to new releases</li>
                    <li>Support for external players like SONOS, Winamp, VLC, etc.</li>
                </ul>
            </div>
            <div :class="$style.actionsContainer">
                <BaseLink
                    :class="$style.welcomeButton"
                    :to="$route.path + '/view-plans'"
                >
                    <BaseButton
                        button-type="highlight"
                        @click="() => {}"
                    > {{ entitledFreeTrial ? 'START FREE TRIAL' : 'UPGRADE NOW' }} </BaseButton>
                </BaseLink>
                <BaseLink
                    v-if="!isStandalone"
                    :to="{ name: 'home' }"
                    :class="$style.escapeUrl"
                ><div >Not now, thanks</div></BaseLink>
            </div>
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@use '@design' as *;

.welcome {
    margin-top: $s-xs;
}

.welcomeBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: url('/images/dj-vibing-purple-gradient.jpg');
    background-position: center;
    background-size: cover;
    opacity: 0.08;

    &.standalone {
        position: fixed;
    }
}

.mainHeader {
    @include h3;

    letter-spacing: -0.5px;
}

.subtitle {
    @extend %h5;
}

.description {
    margin-top: $s-xl;
}

.actionsContainer {
    position: relative;
    margin-top: $s-xxl;
}

.salesPoints {
    @extend %contentSubtitleL;

    padding: 0;
    margin-top: $s-xl;
    text-align: left;
    list-style: inside;
}

.escapeUrl {
    @extend %contentSubtitleM;

    text-align: center;
    text-decoration: underline;
}

@include tablet {
    .actionsContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .escapeUrl {
        text-align: left;
    }
}

@include desktop {
    .welcome {
        margin-top: $s-h;
    }

    .mainHeader {
        @include h1;

        margin-bottom: $s-xxl;
    }

    .actionsContainer {
        margin-top: $s-h;
    }
}
</style>
