// Requirements: https://docs.google.com/document/d/1P_jZWKSiEhK9Nfr1O7g-bwdB5tTOiw_iHF6iZvXIkUE/edit?usp=sharing

const GOOGLE_ANALYTICS_ID = process.env.VUE_APP_ANALYTICS_ID

/** This service is responsible for tracking user interactions with the app. It uses Google Analytics to track events such as page views, media playback, and user signups. We can switch strategy to use a different analytics provider by changing the implementation of _init() and _trackEvent() methods. */
class AnalyticsService {
    constructor() {
        if (AnalyticsService.instance) return AnalyticsService.instance

        AnalyticsService.instance = this
        this._init()
    }

    _init() {
        const script = document.createElement('script')
        script.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`
        script.async = true
        document.head.appendChild(script)

        script.onload = () => {
            window.dataLayer = window.dataLayer || []
            function gtag() {
                window.dataLayer.push(arguments)
            }
            gtag('js', new Date())
            gtag('config', GOOGLE_ANALYTICS_ID)
        }
    }

    _trackEvent(eventName, data) {
        if (typeof data !== 'object') return console.warn('Dev: Analytics data must be an object')

        window.dataLayer = window.dataLayer || []

        function gtag() {
            window.dataLayer.push(arguments)
        }

        const uppsellOfferId = localStorage.getItem('upsellOfferId') || null

        gtag('event', eventName, { ...data, upsell_offer_id: uppsellOfferId, page_location: window.location.pathname })
    }

    /**
     * When a user navigates to a new page.
     */
    trackPageView() {
        this._trackEvent('page_view', {
            page_title: document.title,
        })
    }

    /**
     * When a user clicks on a mix or channel to play it.
     * @param data {Object} - the data of the mix or channel
     */
    playMedia(data) {
        this._trackEvent('player', data)
    }

    /**
     * When a user clicks on any premium upsell CTA within the app. This could be the PLAY button to play a mix on-demand, or selecting the premium-only audio quality tiers in Settings.
     */
    premiumUpsellInitiated(type) {
        this._trackEvent('premium_upsell_initiated', {
            trigger_type: type
        })
    }

    /**
     * When a new user picks one of the plans.
     * @param planId {String} - the plan selected
     */
    premiumUpsellPlanSelected(planId) {
        this._trackEvent('premium_upsell_plans', {
            plan_id: planId
        })
    }

    /**
     * When a new user picks one of the plans.
     * @param planId {String} - the plan selected
     * @param method {String} - the payment method selected
     */
    premiumUpsellPaymentSelected(planId, method) {
        this._trackEvent('premium_upsell_payment', {
            plan_id: planId,
            payment_method: method
        })
    }

    /**
     * When a user successfully completes a purchase of a premium subscription.
     * @param isSuccessful {Boolean} - whether the purchase was successful
     * @param planId {String} - the plan selected
     * @param amount {Number} - the amount paid
     * @param method {String} - the payment method selected
     */
    premiumUpsellCompleted({ isSuccessful, planId = null, amount = null, method = null }) {
        this._trackEvent('premium_upsell_completed', {
            success: isSuccessful,
            plan_id: planId,
            payment_amount: amount,
            payment_method: method
        })
    }

    /**
     * When user selects the donation tab
     */
    donationUpsellInitiated() {
        this._trackEvent('donation_upsell_initiated')
    }

    /**
     * When user selects one of the donation amounts.
     * @param amount {Number} - the amount selected
     */
    donationUpsellAmountSelected(amount) {
        this._trackEvent('donation_upsell_amount', {
            donation_amount: amount,
        })
    }

    /**
     * When user selects one of the donation amounts.
     * @param amount {Number} - the amount selected
     * @param method {String} - the payment method selected
     */
    donationUpsellPaymentSelected(amount, method) {
        this._trackEvent('donation_upsell_payment', {
            donation_amount: amount,
            payment_method: method
        })
    }

    /**
     * When user successfully completes a donation.
     */
    donationUpsellCompleted({ isSuccessful }) {
        this._trackEvent('donation_upsell_completed', {
            success: isSuccessful
        })
    }
}

export default new AnalyticsService()
