import cloneDeep from 'lodash/cloneDeep'

export const state = {
    activeObject: null,
}

export const getters = {}

export const mutations = {
    SET_ACTIVE(state, genre) {
        state.activeObject = cloneDeep(genre)
    },
}

export const actions = {}
