import localSave from './../utils/local-save'
import { onDemandQualityOptions as options } from "./../configuration"

class SettingsService {
    static instance = null

    constructor() {
        if (SettingsService.instance) return SettingsService.instance
        SettingsService.instance = this
    }

    setCurrentOnDemandQuality(qualityKey) {
        return new Promise((resolve, reject) => {
            if (!options[qualityKey]) reject(new Error('Invalid quality option'))
            localSave.save('currentOnDemandQualityKey', qualityKey)
            resolve(options[qualityKey])
        })
    }

    getCurrentOnDemandQualityKey() {
        return new Promise((resolve, reject) => {
            const qualityKey = localSave.get('currentOnDemandQualityKey')
            if (!qualityKey) resolve(undefined)
            resolve(qualityKey)
        })
    }

    removeCurrentOnDemandQualityKey() {
        return new Promise((resolve, reject) => {
            localSave.delete('currentOnDemandQualityKey')
            resolve()
        })
    }
}

export default new SettingsService()
