<script>
import { mapGetters } from 'vuex'
import BackArrow from '@components/back-arrow'

export default {
    components: { BackArrow },
    computed: {
        ...mapGetters('auth', ['loggedIn']),
    },
}
</script>

<template>
    <div :class="$style.wrapper">
        <BackArrow :class="$style.arrow" />

        <div :class="$style.main">
            <div
                ref="container"
                :class="$style.container"
            >
                <slot />
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

$width: 500px;

.main {
    position: relative;
    width: $width;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
}

.arrow {
    position: absolute;
    top: $s-m;
    right: 0;
    left: 0;
    display: block;
    width: calc(#{$width - ($s-xl * 2)});
    margin: 0 auto;
    text-align: left;
}

@include tablet {
    .main {
        margin: $s-h auto 0;
    }
}
</style>
