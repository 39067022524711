<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        size: {
            type: String,
            default: 'medium', // small, medium, large, full
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>

<template>
    <div
        v-if="show"
        :class="['modal', size]"
    >
        <div
            class="background"
            @click="close"
        />
        <div class="content">
            <span
                class="close"
                @click="close"
            >&times;</span>
            <div class="header">
                <slot name="header"/>
            </div>
            <slot />
            <div class="footer">
                <slot name="footer"/>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@use '@design' as *;

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $layer-modal-z-index;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: auto;

    &.small {
        .content {
            width: 300px;
        }
    }

    &.medium {
        .content {
            width: 600px;
        }
    }

    &.large {
        .content {
            width: 800px;
        }
    }

    &.full {
        .content {
            width: 100vw;
            min-height: 100vh;
        }
    }
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
}

.content {
    position: relative;
    padding: $s-xl;
    background-color: $c-alt-bg;
    border: 1px solid $c-nevada-grey;
    border-radius: $size-input-border-radius;
    box-shadow: 0 2px 4px 0 $c-black;
}

.close {
    @extend %h5;

    position: absolute;
    top: $s-m;
    right: $s-m;
    color: $c-text;
}

.close:hover {
    color: $c-highlight;
    text-decoration: none;
    cursor: pointer;
}
</style>
