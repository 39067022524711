<script>
import DataLayer from '@services/data-layer/index'

import MixLargeTile from '@components/mix-large-tile'
import MixTableBlock from '@components/mix-table-block.v2'
import MixGridBlock from '@components/mix-grid-block'

export default {
    components: { MixLargeTile, MixTableBlock, MixGridBlock },
    props: {
        artists: {
            type: Array,
            default: () => [],
        },
        show: {
            type: Object,
            default: () => {},
        },
        popularMixes: {
            type: Array,
            default: () => [],
        },
        latestMixes: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            INITIAL_ALL_MIXES: 12,
            visibleAllMixes: 12,
            INITIAL_POPULAR_MIXES: 5,
            visiblePopularMixes: 5,
            shouldLoadMore: true,
        }
    },
    computed: {
        currentMix() {
            return this.latestMixes[0]
        },
        mixesRemain() {
            return this.latestMixes.length % this.INITIAL_ALL_MIXES === 0
        },
        scroll() {
            return this.$store.state.appearance.scroll
        },
        scrollChild() {
            return this.$store.state.appearance.scrollChild
        },
    },
    watch: {
        scroll() {
            let bottomOfWindow =
                this.scroll + window.innerHeight >=
                this.$store.state.appearance.scrollChild.offsetHeight - window.innerHeight / 3
            if (bottomOfWindow && this.shouldLoadMore) {
                this.shouldLoadMore = false
                setTimeout(() => {
                    this.shouldLoadMore = true
                }, 200)
                this.showMoreLatestMixes()
            }
        },
    },
    methods: {
        showMorePopularMixes() {
            this.visiblePopularMixes *= 2
        },
        showMoreLatestMixes() {
            if (!this.mixesRemain) {
                return false
            }
            DataLayer.mixes
                .getLatestByShow(this.show.id, this.INITIAL_ALL_MIXES, this.visibleAllMixes)
                .then(response => {
                    this.latestMixes.push(...response.body)
                    if (response.body.length === 0) {
                        this.INITIAL_ALL_MIXES -= 1
                    }
                    this.shouldLoadMore = true
                })
            this.visibleAllMixes += this.INITIAL_ALL_MIXES
        },
    },
}
</script>

<template>
    <BaseContent>
        <BaseSection
            padded
            title="Latest Mix"
        >
            <MixLargeTile
                v-if="currentMix"
                :mix="currentMix"
            >
                <BaseButtonPlay
                    slot="button"
                    :resource="currentMix"
                    button-type="secondary"
                />
            </MixLargeTile>
        </BaseSection>

        <BaseSection
            v-if="popularMixes.length"
            padded
            title="Popular Mixes"
        >
            <MixTableBlock
                :mixes="popularMixes.slice(0, visiblePopularMixes)"
                :genres="true"
                :buttons="{ playCount: true }"
                is-indexed-list
            />
            <div
                v-if="popularMixes.length > INITIAL_POPULAR_MIXES && popularMixes.length > visiblePopularMixes"
                :class="$style.loadMoreButton"
                @click="showMorePopularMixes"
            >
                <span>Load more</span>
                <BaseIcon
                    :class="[$style.icon, $style.iconArrowDown]"
                    name="arrow-long-down"
                /></div></BaseSection>

        <BaseSection
            v-if="latestMixes.length"
            padded
            title="All Mixes"
        >
            <MixGridBlock :mixes="latestMixes" />
            <div
                v-if="mixesRemain"
                :class="$style.loadMoreButton"
                @click="showMoreLatestMixes"
            >
                <span>Load more</span>
                <BaseIcon
                    :class="[$style.icon, $style.iconArrowDown]"
                    name="arrow-long-down"
                />
            </div>
        </BaseSection>
    </BaseContent>
</template>

<style lang="scss" module>
@import '@design';

.loadMoreButton {
    margin-top: 25px;
    text-align: right;
    cursor: pointer;

    @extend %contentTextButton;
}

.iconArrowDown {
    width: 36px;
    height: 16px;
    vertical-align: middle;
    fill: $c-highlight;
}

.allMixes .loadMoreButton {
    margin-top: 1px;
}
</style>
