import Api from './../api'
import DataLayer from './../data-layer/index'

DataLayer.$extend('members', {
    getById({ id, includePasswordState = false }) {
        return Api.members.get({ id, includepasswordstate: includePasswordState ? 1 : 0 })
    },

    add({ data }) {
        return Api.members.post({ data })
    },

    edit({ id, data }) {
        return Api.members.put({ id }, data)
    },

    getPermissionsByMemberId({ id }) {
        return Api.members.getPermissions({ id })
    }
})
