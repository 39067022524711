import Api from './../api'
import DataLayer from './../data-layer/index'
import { subscriptionPauseDurationInMonths } from './../../configuration'

DataLayer.$extend('subscriptions', {
    getProducts() {
        return Api.subscriptions.getProducts()
    },

    getSubscriptionsByMemberId(membersid) {
        return Api.subscriptions.getMembersProducts({ membersid })
    },

    getActiveSubscriptionsByMemberId(membersid) {
        return Api.subscriptions.getMembersProducts({ membersid, status: 'active' })
    },

    checkout(data) {
        return Api.subscriptions.checkout({}, data)
    },

    getCheckoutById(id) {
        return Api.subscriptions.getCheckout({ id })
    },

    cancelSubscription(id) {
        return Api.subscriptions.editMembersProduct({ id }, { status: 'canceled' })
    },

    pauseSubscription(id) {
        const currentTime = new Date()
        // 3 months from now
        const renewTime = new Date(currentTime.setMonth(currentTime.getMonth() + subscriptionPauseDurationInMonths))
        // 1 day from now - added for testing
        // const renewTime = new Date(currentTime.setDate(currentTime.getDate() + 1))
        return Api.subscriptions.editMembersProduct({ id }, { status: 'paused', renewTime })
    },

    resumeSubscription(id) {
        return Api.subscriptions.editMembersProduct({ id }, { status: 'active' })
    },
})
