<script>
import Layout from '@layouts/main'
import ShowGridBlock from '@components/show-grid-block'

export default {
    page() {
        const title = 'Your Music - Shows'
        const description = 'Your Music - Shows'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    components: { Layout, ShowGridBlock },
    data() {
        return {
            favPopulatedList: Array.from(Array(6), (e, i) => ({
                shows: {
                    id: Number.MAX_SAFE_INTEGER - i * 10,
                },
            })),
            shouldTransition: false,
            maxShows: 30,
            increaseShows: 15,
            shouldLoadMore: false,
        }
    },
    computed: {
        favList() {
            return this.$store.state.favorites.showList
        },
        showList() {
            return this.favPopulatedList.map(el => el.shows)
        },
        scroll() {
            return this.$store.state.appearance.scroll
        },
        scrollChild() {
            return this.$store.state.appearance.scrollChild
        },
    },
    watch: {
        favList: {
            handler() {
                this.$dataLayer.favShows
                    .get(this.maxShows)
                    .then(({ body }) => {
                        this.favPopulatedList = body
                        this.shouldLoadMore = true
                    })
                    .then(() => {
                        this.shouldTransition = true
                    })
            },
            immediate: true,
        },
        scroll() {
            let bottomOfWindow =
                this.scroll + window.innerHeight >=
                this.$store.state.appearance.scrollChild.offsetHeight - window.innerHeight / 3

            if (bottomOfWindow && this.shouldLoadMore) {
                this.shouldLoadMore = false
                setTimeout(() => {
                    this.shouldLoadMore = true
                }, 200)
                this.loadMoreShows()
            }
        },
    },
    methods: {
        loadMoreShows() {
            this.maxShows += this.increaseShows
            this.$dataLayer.favShows.get(this.maxShows).then(({ body }) => {
                this.favPopulatedList = body
                this.shouldLoadMore = true
            })
        },
    },
}
</script>

<template>
    <Layout>
        <BaseHero
            :class="$style.mainHeading"
            text="Favorite Shows"
        />
        <BaseContent>
            <BaseSection padded>
                <ShowGridBlock
                    :shows="showList"
                    :error="{
                        title: `You are not following any shows.`,
                        paragraph: `As you follow shows, they will appear here.`,
                    }"
                />
            </BaseSection>
        </BaseContent>
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.mainHeading h1 {
    margin-top: 0;
}
</style>
