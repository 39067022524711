<script>
import DataLayer from '@services/data-layer/index'
import BoxedWithSidebar from '@layouts/boxed-with-sidebar'
import Logo from './../../../components/logo'
import SimpleTopBar from './../../../partials/simple-top-bar'
import analyticsService from './../../../services/analytics-service'

export default {
    components: { BoxedWithSidebar, SimpleTopBar, Logo },
    data() {
        return {
            hasChanges: false,
            newImage: null,
            s3Folder: process.env.VUE_APP_BFH_IMAGES_FOLDER,
            isImageLoading: false,
        }
    },
    page() {
        const title = 'Account Management'
        const description = 'Account Management'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    computed: {
        isPassSet() {
            return this.$store.state.member.passState === 'set'
        },
        user() {
            return this.$store.getters['member/currentMember']
        },
        currentSubscription() {
            return this.$store.getters['premium/currentSubscription']
        }
    },
    methods: {
        uploadImage(e) {
            this.isImageLoading = true
            const files = e.target.files
            DataLayer.uploader
                .upload(files, this.s3Folder)
                .then(image => {
                    this.updateUserAvatar(image.body)
                })
                .catch(_error => {
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m('SOMETHING_WENT_WRONG'),
                        type: 'danger',
                    })
                })
        },
        updateUserAvatar(newImage) {
            if (!newImage) return
            const user = { ...this.user, avatar: newImage }
            DataLayer.members
                .edit({ id: this.user.id, data: user })
                .then(user => {
                    this.newImage = null
                    this.isImageLoading = false
                    this.$store.dispatch('member/updateUser', user.body)
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m('DETAILS_UPDATED'),
                        type: 'success',
                    })
                }).catch(_error => {
                    this.isImageLoading = false
                    this.$store.dispatch('appearance/handleNotification', {
                        message: this.$m('SOMETHING_WENT_WRONG'),
                        type: 'danger',
                    })
                })
        },
        onPremiumMenuItemClick() {
            analyticsService.premiumUpsellInitiated('account-premium-menu-item')
        }
    }
}
</script>

<template>
    <BoxedWithSidebar>
        <div slot="top-bar">
            <SimpleTopBar>
                <div class="boxed-container">
                    <Logo class="logo"/>
                </div>
            </SimpleTopBar>
        </div>
        <div
            slot="sidebar"
            class="sidebar-content"
        >
            <div
                v-if="user"
                class="info"
            >
                <div
                    class="avatar"
                    @click="$refs.file.click()"
                >
                    <div class="editAvatar">
                        <BaseIcon
                            class="iconEdit"
                            name="edit"
                        />
                    </div>
                    <div class="avatarContent">
                        <div
                            v-if="isImageLoading"
                            class="spinner"
                        >Loading..</div>
                        <BaseResourceImage
                            v-if="user.avatar"
                            class="image"
                            :resource="user"
                            :fill="false"
                            :centered="true"
                            type="avatar"
                            alt="user avatar"
                            :should-lazyload="false"
                        />
                        <BaseIcon
                            v-else
                            class="icon iconUser"
                            name="user"
                        />

                    </div>
                    <input
                        ref="file"
                        class="fileField"
                        type="file"
                        accept="image/*"
                        @change="uploadImage($event)"
                    >
                </div>
                <div>
                    <strong class="username">
                        {{ user.firstName || user.email }}
                    </strong>
                    <div
                        v-if="currentSubscription && currentSubscription.plan"
                        class="premiumStatus"
                    >
                        FRISKY {{ currentSubscription.plan.title }}
                    </div>
                </div>
            </div>
            <ul class="menu">
                <li class="menuItem">
                    <router-link
                        class="menuLink"
                        active-class="current"
                        :to="{ name: 'account/overview' }"
                    >Account Overview</router-link>
                </li>
                <li
                    class="menuItem"
                    @click="onPremiumMenuItemClick"
                >
                    <router-link
                        class="menuLink"
                        active-class="current"
                        :to="{ name: 'account/premium' }"
                    >FRISKY Premium</router-link>
                </li>
                <li class="menuItem">
                    <router-link
                        class="menuLink"
                        active-class="current"
                        :to="{ name: 'account/edit-profile' }"
                    >Edit Profile</router-link>
                </li>
                <li class="menuItem">
                    <router-link
                        class="menuLink"
                        active-class="current"
                        :to="{ name: 'account/change-password' }"
                    >Change Password</router-link>
                </li>
            </ul>
        </div>
        <div slot="main">
            <BaseSection
                padded
                short
            >
                <router-view/>
            </BaseSection>
        </div>
    </BoxedWithSidebar>
</template>

<style lang="scss" scoped>
@import '@design';

$photo-size: 100px;
$photo-size-small: 50px;

.boxed-container {
    width: $largescreen;
    max-width: 100%;
    padding: 0 $s-s;
    margin: 0 auto;
}

.logo {
    margin-bottom: 0;
}

.sidebar-content {
    display: flex;
    flex-direction: column;
    gap: $s-xxl;
    align-items: center;
    text-align: center;
}

.info {
    display: flex;
    gap: $s-m;
    align-items: center;
}

.avatar {
    position: relative;
    width: $photo-size-small;
    height: $photo-size-small;
    cursor: pointer;

    &Content {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 50%;
    }
}

.editAvatar {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: $layer-tooltip-z-index;
    width: 33%;
    height: 33%;
    overflow: hidden;
    border-radius: 50%;
}

.iconEdit {
    position: relative;
    width: 100%;
    height: 100%;
    padding: $s-xxs;
    background: $c-white;
    fill: $c-black;
}

.image {
    width: 100%;
    height: 100%;
}

.fileField {
    display: none;
}

.iconUser {
    display: block;
    width: 100%;
    height: 100%;
    padding: $s-s;
    margin: 0 0 $s-m 0;
    text-align: center;
    background: $c-white;
    fill: $c-black;
}


.premiumStatus {
    @extend %contentSubtitleM;

    margin-top: $s-xxs;
}

.menu {
    padding: 0;
    margin: 0;
    list-style: none;
}

.menuItem {
    @extend %contentTitleL;

    margin-bottom: $s-xs;
}

.menuLink {
    display: block;
    border-bottom: 1px solid transparent;

    &.current {
        color: $c-white;
        border-color: $c-highlight;
    }

    &:hover {
        color: $c-white;
        text-decoration: none;
    }
}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $layer-highlight-z-index;
    width: 100%;
    height: 100%;
    font-size: 10px;
    text-indent: -9999em;
    border: $photo-size * 0.1 solid transparentize($c-white, 0.8);
    border-left: $photo-size * 0.1 solid transparentize($c-white, 0.4);
    border-radius: 50%;
    transform: translateZ(0);
    animation: spin 1.1s infinite ease-in-out;

    &::after {
        width: 10em;
        height: 10em;
        border-radius: 50%;
    }
}

@include tablet {
    .info {
        flex-direction: column;
    }

    .avatar {
        width: $photo-size;
        height: $photo-size;

        &:hover {
            .editAvatar {
                opacity: 1;
            }
        }
    }

    .editAvatar {
        opacity: 0;
        transition: opacity .3s ease-in-out;
    }

    .iconEdit {
        padding: $s-xs;
    }

    .menu {
        width: 100%;
    }

    .menuItem {
        margin-bottom: $s-l;
    }

    .menuLink {
        padding-bottom: $s-xs;
        border-width: 2px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
