<script>
import { onDemandQualityOptions } from '../../../configuration';
import settingsService from '../../../services/settings-service';
import DataLayer from './../../../services/data-layer/index'

export default {
    page() {
        const title = 'Settings - Audio Quality'
        const description = 'Settings - Audio Quality'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    data() {
        return {
            options: onDemandQualityOptions,
            settingsService: settingsService,
            selectedOption: '',
        }
    },

    computed: {
        memberId() {
            return this.$store.getters['member/currentMember'].id
        },
        currentOnDemandQualityKey() {
            return this.$store.state.settings.currentOnDemandQualityKey
        },
        isPremium() {
            return this.$store.getters['premium/isPremium']
        },
    },

    mounted() {
        this.getSubscriptions()
    },

    methods: {
        handleQualitySelection(event, qualityKey) {
            if (!qualityKey) return
            if(!this.isPremium && this.options[qualityKey].needsSub) {
                event.preventDefault()
                return this.$store.dispatch('appearance/handleNotification', {
                    message: this.$m('PLAYER_PREMIUM_ERROR'),
                    type: 'warning',
                })
            }

            this.save(qualityKey)
        },
        async getSubscriptions() {
            const premiumService = DataLayer.subscriptions
            const subscriptions = await premiumService.getSubscriptionsByMemberId(this.memberId)
            this.$store.commit('premium/SET_SUBSCRIPTIONS', subscriptions.body)
            this.selectedOption = this.currentOnDemandQualityKey
        },
        async save(qualityKey) {
            const savedQuality = await this.settingsService.setCurrentOnDemandQuality(qualityKey)
            if (!savedQuality) return
            this.$store.commit('settings/SET_SETTINGS', { currentOnDemandQualityKey: qualityKey })

            this.selectedOption = this.currentOnDemandQualityKey

            this.$store.dispatch('appearance/handleNotification', {
                message: this.$m('AUDIO_QUALITY_UPDATED'),
                type: 'success',
            })
        },
    },
}
</script>

<template>
    <BaseSection padded>
        <h6 :class="[$style.subtitle, $style.subtitleInfo]">Audio Quality</h6>
        <div :class="$style.qualitySelection">
            <div
                v-for="(value, name) in options"
                :key="'audioQuality' + name"
                :class="[$style.quality]"
            >
                <input
                    :id="'audioQuality' + name"
                    v-model="selectedOption"
                    :class="$style.radio"
                    :value="name"
                    type="radio"
                    name="audioQuality"
                    @click="handleQualitySelection($event, name)"
                >
                <div>
                    <label
                        :class="[$style.QualityLabel, $style.label]"
                        :for="'audioQuality' + name"
                    >{{
                        value.title
                    }}</label>
                    <label
                        :class="[$style.QualityLabel, $style.sublabel]"
                        :for="'audioQuality' + name"
                    >{{
                        value.subtitle
                    }}</label>
                </div>
            </div>
        </div>
        <div :class="$style.note">Changes to audio quality settings will take effect from the next playback</div>
    </BaseSection>
</template>

<style lang="scss" module>
@import '@design';

.radio {
    @extend %radio;

    margin-right: $s-s;
    cursor: pointer;
}

.label {
    @extend %contentSubtitleM;

    cursor: pointer;
}

.sublabel {
    @extend %contentSubtitleS;

    display: block;
    cursor: pointer;
}

.subtitle {
    margin-top: 0;
    text-align: center;
}

.quality {
    display: flex;
    align-items: top;

    .label,
    .sublabel {
        color: lighten($c-text-faded, 20%);
    }

    ~ .quality {
        margin-top: $s-xxl;
    }

    &:hover {
        .label,
        .sublabel {
            color: $c-white;
        }
        .radio {
            background: transparentize($c-highlight, 0.5);
        }
    }
}

.note {
    @extend %contentSubtitleM;

    margin-top: $s-xxxl;
}

@include tablet {
    .subtitle {
        text-align: left;
    }
}
</style>
