<script>
import Layout from '@layouts/main'
import AudioQuality from './audio-quality'

export default {
    components: { Layout, AudioQuality },
    props: {
        mainComponent: {
            type: String,
            default: 'AudioQuality',
        },
    },
    data() {
        return {
            user: {},
        }
    },
    page() {
        const title = 'Settings'
        const description = 'Settings'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    computed: {
        isPassSet() {
            return this.$store.state.member.passState === 'set'
        },
        tabs() {
            return [
                {
                    to: { name: 'settings' },
                    label: 'Audio Quality',
                },
            ]
        },
    },
}
</script>

<template>
    <Layout>
        <BaseSection padded>
            <h1>Settings</h1>
        </BaseSection>
        <BaseTabPanel
            :tabs="tabs"
            :class="$style.tabs"
            is-underlined
            is-unpadded
            address-navigation
        />
        <BaseContent>
            <component :is="mainComponent" />
        </BaseContent>
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.tabs {
    margin-bottom: $s-xxl;
}
</style>
