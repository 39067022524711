<script>
import Layout from '@layouts/main'
import SearchBar from '@components/search/search-bar'

const RECENT_SEARCH_OPTIONS = ['artist', 'genre', 'mix', 'show']

export default {
    components: { Layout, SearchBar },
    page() {
        const title = 'Search'
        const description = 'Search'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    computed: {
        searchKeyword() {
            return this.$store.state.search.keyword
        },
    },
    watch: {
        searchKeyword() {
            if (this.searchKeyword) {
                this.$router.push({
                    name: this.$route.name.includes('search/results') ? this.$route.name : 'search/results/top',
                    params: { keyword: this.searchKeyword.replace(/ /g, '_') },
                })
            } else {
                this.$router.push({ name: 'search' })
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        if (RECENT_SEARCH_OPTIONS.indexOf(to.name) > -1 && !to.query.back) {
            const item = {
                id: `${to.name}-${to.params.id}`,
                type: to.name,
                itemId: to.params.id,
            }
            this.$dataLayer.search.addToRecent(item)
        }
        next()
    },
}
</script>

<template>
    <Layout hide-top-bar>
        <BaseContent :class="$style.searchArea">
            <SearchBar />
            <transition
                name="fade"
                mode="out-in"
            >
                <router-view />
            </transition>
        </BaseContent>
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.searchArea {
    min-height: calc(100vh - #{$size-search-bar-height + $size-music-player});

    @media (min-width: $desktop) {
        margin-top: -$s-xl;
    }
}
</style>
