<script>
import formatDate from '@utils/format-date-full'
import PlaylistOptions from '@components/playlists/playlist-options'

export default {
    name: 'PlaylistInfo',
    components: { PlaylistOptions },
    props: {
        playlist: {
            type: Object,
            default: () => null,
        },
        playlistMixes: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        publishedTime() {
            if (this.playlist && this.playlist.revisions_publish_time) {
                const date = new Date(this.playlist.revisions_publish_time)
                return formatDate(date)
            }
            return ''
        },
    },
}
</script>

<template>
    <div
        v-if="playlist"
        :class="$style.details"
    >
        <div :class="$style.detailsInner">
            <BaseResourceCover
                :image-resource="playlist"
                :class="$style.image"
                :media-resource="playlist"
                image-type="revisions_album_art"
                media-type="playlist"
            >
                <div slot="secondaryActions">
                    <PlaylistOptions
                        :playlist="playlist"
                        :has-view-playlist-option="false"
                    />
                </div>
            </BaseResourceCover>
            <div :class="$style.title">
                <h5
                    v-if="playlist"
                    :class="$style.titleInner"
                >{{ playlist.revisions_title }}</h5>
                <div v-else>
                    <BasePlaceholder :size="10" />
                    <BasePlaceholder :size="13" />
                </div>
            </div>
            <div :class="$style.description">
                <div v-if="playlist">{{ playlist.revisions_description }}</div>
                <div v-else>
                    <BasePlaceholder :size="15" />
                    <BasePlaceholder :size="17" />
                </div>
            </div>
            <div :class="$style.button">
                <div
                    v-if="playlist"
                    @click="$emit('playPlaylist', 0)"
                >
                    <BaseButtonPlay
                        :icon="true"
                        :playlist="playlist"
                        :class="$style.buttonInner"
                        :loading-playlist-mixes="true"
                        :resource="playlistMixes"
                    />
                </div>
                <div v-else>
                    <BasePlaceholder :size="7" />
                </div>
            </div>
            <div :class="$style.publishedTime">
                <div v-if="playlist && publishedTime">Last Updated: {{ publishedTime }}</div>
                <div v-else>
                    <BasePlaceholder :size="15" />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.details {
    &Inner {
        margin-bottom: $s-xl;

        @include widescreen {
            position: sticky;
            top: $s-xl + $s-xxxl;
            margin-bottom: 0;
        }
    }
}

.title {
    margin-top: $s-xxl;
    margin-bottom: $s-s;
    text-align: center;
    &Inner {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 800;
    }
}

.description {
    @extend %contentTinyHeading;

    margin-top: 0;
    margin-bottom: $s-l;
    text-align: center;
}

.publishedTime {
    @extend %contentTinyHeading;

    text-align: center;
}

.button {
    text-align: center;
    &Inner {
        max-width: 240px;
    }
}

.dropdown {
    &Menu {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    &Item {
        display: flex;
        align-items: center;
        height: 44px;
        padding: 0 $s-m;
    }
}

.separator {
    position: relative;
    display: block;
    width: 100%;
    height: 1px;
    background: $c-mine-shaft;
}
</style>
