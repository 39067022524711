<script>
export default {

}
</script>

<template>
    <div class="top-bar">
        <slot/>
    </div>
</template>


<style lang="scss" scoped>
    @import '@design';

    .top-bar {
        position: relative;
        z-index: $layer-overlay-z-index;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: $size-menu-bar-height;
        background-color: $c-black;
    }
</style>
