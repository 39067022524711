<script>
import ShowScheduleVerticalTile from '@components/show-schedule-vertical-tile'

export default {
    components: {
        ShowScheduleVerticalTile,
    },
    props: {
        playlist: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            visiblePlaylistItems: 3,
            morePlaylistItems: 5,
        }
    },
    computed: {
        upcomingMixes() {
            return this.playlist.slice(1, 1 + this.visiblePlaylistItems)
        },
        canLoadMore() {
            return this.visiblePlaylistItems < this.playlist.length - 1
        },
    },
    methods: {
        showMore() {
            this.visiblePlaylistItems += this.morePlaylistItems
        },
    },
}
</script>

<template>
    <div :class="$style.container">
        <div :class="['heading', $style.showScheduleHead]">Up Next</div>
        <transition-group
            name="fade"
            tag="div"
        >
            <ShowScheduleVerticalTile
                v-for="item in upcomingMixes"
                :key="`${item.id}`"
                :playlist-item="item"
            />
        </transition-group>
        <BaseButton
            :class="[$style.loadMoreButton, !canLoadMore && $style.disabled]"
            :modifiers="{
                fill: true,
            }"
            button-type="secondary"
            @click="showMore"
        >
            SHOW MORE
        </BaseButton>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.container {
    padding-bottom: $size-music-player;
}
.showScheduleHead {
    padding-bottom: $s-xs;
    font-size: 11px;
    color: $c-text-faded;
}
.loadMoreButton {
    margin-top: $s-s;
    &.disabled {
        pointer-events: none;
        opacity: 0.2;
    }
}
</style>
