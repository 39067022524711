export default {
    PLAYER_PREMIUM_ERROR:
        'Start listening now. <a target="_self" href="/premium">Start free trial.</a>',
    PLAYER_LOGIN_SIGNUP:
        'Start listening now. <a href="/auth/signup">Sign up</a> for free or <a href="/auth/login">Log in</a>.',
    EMAIL_NOT_FOUND: 'We cant find that email in our system, try another email or <a href="/auth/signup">Sign up</a>.',
    EMAIL_RESET_LINK_SENT: 'A reset password link has been sent to your email.',
    FIELDS_REQUIRED: 'Fields are required',
    EMAIL_REQUIRED: 'Email feild is required',
    EMAIL_PASSWORD_REQUIRED: 'Email and Password fields are required',
    RECAPTCHA_MISSING_ERROR: 'FRISKY is for humans only. Please verify that you are not a robot.',
    NEW_PASSWORD_SUCCESS: 'You now have a new password',
    USER_ALREADY_EXISTS:
        'You previously signed up with that email. <a href="/auth/login">Log in</a> or <a href="/auth/forgot-password">Reset password</a> to continue',
    USER_ALREADY_EXISTS_SOCIAL: `You previously signed up with {social}. <a href="/auth/login">Log in</a> using {social} to continue`,
    EMAIL_ALREADY_EXISTS: `This email already exists. Please try again using another one`,
    WRONG_PASSWORD: `Sorry, wrong password`,
    PASSWORD_CHANGED: `Password Changed`,
    PASSWORD_CHANGES_ACCOUNT: `Enter password to make changes to your account.`,
    DETAILS_UPDATED: `Your details have been updated`,
    SOMETHING_WENT_WRONG: `Oops, Something went wrong, please try again.`,
    PASSWORD_MATCH: `The password fields for new password and confirm password must match`,
    PASSWORD_UPDATED: `Your password has been updated.`,
    PASSWORD_SET: `Your password has been set.`,
    NO_FAVORITE_CHANNEL: `Your favorite channel list is empty`,
    DISCOVER_NEW_CHANNELS: `When you add a radio channel to your favorites, they will appear here.`,

    // Favourites
    FAV_MIX_ADDED: `Mix added to Favourites`,
    FAV_MIX_REMOVED: `Mix removed from Favourites`,
    FAV_ARTIST_ADDED: `Following Artist`,
    FAV_ARTIST_REMOVED: `Artist Unfollowed`,
    FAV_SHOW_ADDED: `Following Show`,
    FAV_SHOW_REMOVED: `Show Unfollowed`,
    FAV_ALREADY_ADDED: `This {type} has already been added on your favorites`,
    // /Favourites

    LINK_COPIED_CLIPBOARD: `Link copied to clipboard`,
    UPDATE_AVAILABLE: `An update to FRISKY is available. Reload to get latest version.`,
    CRITICAL_UPDATE_AVAILABLE: `A critical update to FRISKY is available. Page will auto-reload in `,
    AUDIO_QUALITY_UPDATED: `Audio quality updated`,
    SERVER_BUSY_TRY_AGAIN: `Server busy, please try again later.`,
}
