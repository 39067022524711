<script>
import DataLayer from '@services/data-layer/index'
import Layout from '@layouts/main'
import PlaylistsGridBlock from '@components/playlists/playlists-grid-block'

export default {
    page() {
        const title = 'Playlists'
        const description = 'Playlists'
        return {
            title,
            meta: [
                { name: 'title', vmid: 'title', content: title },
                { name: 'description', vmid: 'description', content: description },

                { property: 'og:title', vmid: 'og:title', content: title },
                { property: 'og:description', vmid: 'og:description', content: description },

                { property: 'twitter:title', vmid: 'twitter:title', content: title },
                { property: 'twitter:description', vmid: 'twitter:description', content: description },
            ],
        }
    },
    components: { Layout, PlaylistsGridBlock },
    data() {
        return {
            playlists: [],
            featuredPlaylists: [],
            recommendedPlaylists: [],
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.member.currentMember
        },
    },
    mounted() {
        this.currentUser && this.getRecommendationPlaylists()
        this.getPlaylists()
    },
    methods: {
        getRecommendationPlaylists() {
            DataLayer.playlists.getMemberRecommendations(this.currentUser.id).then(res => {
                this.recommendedPlaylists = res.body
            })
        },
        getPlaylists() {
            DataLayer.playlists.getByType({ type: 'editorial' }).then(res => {
                res.body
                    .filter(
                        playlist =>
                            playlist.revisions_id !== null &&
                            playlist.revisions_album_art &&
                            playlist.revisions_album_art.filename
                    )
                    .map(playlist => {
                        playlist.featured === 1 ? this.featuredPlaylists.push(playlist) : this.playlists.push(playlist)
                    })
            })
        },
    },
}
</script>

<template>
    <Layout>
        <div :class="$style.mainHeading">Playlists</div>
        <BaseContent>
            <BaseSection
                v-if="featuredPlaylists && featuredPlaylists.length"
                title="Featured Playlists"
                padded
            >
                <PlaylistsGridBlock
                    :playlists="featuredPlaylists"
                    type="playlists"
                />
            </BaseSection>
            <BaseSection
                v-if="recommendedPlaylists && recommendedPlaylists.length"
                title="Made for You"
                padded
            >
                <PlaylistsGridBlock
                    :playlists="recommendedPlaylists"
                    type="playlists"
                />
            </BaseSection>
            <BaseSection padded>
                <PlaylistsGridBlock
                    :playlists="playlists"
                    type="playlists"
                />
            </BaseSection>
        </BaseContent>
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.mainHeading {
    @include h3;

    margin-top: $s-h;
    margin-bottom: $s-xxl;
    letter-spacing: 0.25px;
    &::after {
        display: block;
        min-height: 1px;
        margin-top: $s-xl;
        content: '';
        background: $c-dusty-gray;
        opacity: 0.1;
    }

    @include desktop {
        @include container;
        @include h1;

        margin-bottom: $s-xxl;
    }

    @include sidePadding;
}
</style>
