<script>
import formatDate from '@utils/format-date-full'
import MixOptions from '@components/mix/mix-options'

export default {
    components: { MixOptions },
    props: {
        index: {
            type: Number,
            default: -1,
        },
        mix: {
            type: Object,
            default: () => {},
        },
        hasLinkToArtistPage: {
            type: Boolean,
            default: false,
        },
        buttons: {
            type: Object,
            default: () => {},
        },
        isIndexedList: {
            type: Boolean,
            default: false,
        },
        genres: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isTileHovered: false,
        }
    },
    computed: {
        airDate() {
            if (this.mix.episode) {
                const date = new Date(this.mix.episode.air_start)
                return formatDate(date)
            }
            return ''
        },
        isMixLoaded() {
            return this.$store.state.playback.resource && this.mix.id === this.$store.state.playback.resource.id
        },
        playbackStatus() {
            return this.$store.state.playback.status === 'playing' || this.$store.state.playback.status === 'loading'
        },
        tileIndex() {
            return this.index + 1 <= 9 ? `0${this.index + 1}` : this.index + 1
        },
    },
}
</script>

<template>
    <div
        :class="[$style.container, { [$style.active]: isMixLoaded && playbackStatus }]"
        @mouseover="isTileHovered = true"
        @mouseleave="isTileHovered = false"
    >
        <div :class="[$style.inner, $style.main]">
            <div
                v-if="isIndexedList"
                :class="[$style.inner, $style.index]"
            >
                <div :class="$style.indexMain">
                    {{ tileIndex }}
                </div>
            </div>
            <BaseResourceImage
                :resource="mix.episode"
                :class="$style.image"
                :playable-mix="mix"
                :has-hover-state="isTileHovered"
            />
            <BaseLink
                v-if="mix.show && mix.show.title"
                :to="{ name: 'show', params: { id: mix.show.id } }"
            >
                <div :class="$style.show">
                    {{ mix.show.title }}
                </div>
            </BaseLink>
            <BasePlaceholder
                v-else
                :class="$style.show"
                :text="mix.show && mix.show.title"
                type="show"
            />
        </div>
        <div
            v-if="!hasLinkToArtistPage"
            :class="[$style.inner, $style.artist, genres ? $style.artistGenres : '']"
        >
            <div :class="[$style.inner, $style.artistInner]">
                <BaseLink
                    v-if="mix.artist"
                    :to="{ path: `/artist/${mix.artist.url}/${mix.artist.id}/` }"
                >
                    {{ mix.artist.title }}
                </BaseLink>
                <BasePlaceholder
                    v-else
                    :text="mix.artist && mix.artist.title"
                    type="artist"
                />
            </div>
        </div>
        <div
            v-if="genres"
            :class="$style.genreList"
        >
            <BaseButtonGenre
                v-for="genre in mix.genre.slice(0, 2)"
                :key="genre"
                :genre="genre"
            />
            <BaseButton
                v-if="mix.genre.length > 2"
                button-type="tagSecondary"
                size="small"
            >
                +{{ mix.genre.length - 2 }} MORE
            </BaseButton>
        </div>
        <div :class="[$style.inner, $style.time]">
            <div :class="[$style.inner, $style.timeInner]">
                <BaseLink
                    v-if="mix.title"
                    :to="{ name: 'mix', params: { id: mix.id } }"
                >
                    {{ airDate }}
                </BaseLink>
                <BasePlaceholder
                    v-else
                    :text="mix.title"
                    :size="10"
                />
            </div>
        </div>
        <div :class="[$style.inner, $style.buttons]">
            <BaseButton
                v-if="mix && buttons && buttons.playCount"
                :class="[$style.button, $style.buttonPlayCount]"
                icon="play_arrow"
                size="small"
                button-type="tag"
            >
                {{ Number(mix.reach).toLocaleString() || '0' }}
            </BaseButton>
            <BaseDropdown :class="$style.button">
                <BaseButton
                    slot="trigger"
                    :modifiers="{ autoWidth: true, transparent: true, dots: true }"
                    button-type="tag"
                    aria-label="Options"
                    size="small"
                />
                <div slot="dropdown">
                    <MixOptions :mix="mix" />
                </div>
            </BaseDropdown>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.main {
    @extend %contentTitleM;

    width: span(7 wide of 12 narrow);
    min-width: span(7 wide of 12 narrow);

    @include tablet {
        width: span(4 narrow of 12 narrow);
        min-width: span(4 narrow of 12 narrow);
    }
}

.container {
    @extend %contentSubtitleM;

    display: flex;
    align-items: center;
    padding: $s-xs;
    border-bottom: 1px solid rgba($c-ebony-clay, 0.35);
    &:hover {
        background: rgba($c-tuna, 0.15);
    }
    &.active {
        background: rgba($c-tuna, 0.1);
        .main {
            color: $c-highlight;
        }
    }
    &:active {
        background: $c-active-bg;
    }
}
.inner {
    display: flex;
    align-items: center;
}
.index {
    width: span(2 wide of 12 narrow);
    min-width: span(2 wide of 12 narrow);
    max-width: 70px;
    margin-right: gutter(of 12 narrow);

    &Main {
        color: $c-shuttle-gray;
    }

    @include tablet {
        width: span(2 narrow of 12 narrow);
    }

    @include desktop {
        width: span(1 narrow of 12 narrow);
        min-width: span(1 narrow of 12 narrow);
    }
}
.image {
    display: none;
    width: 2rem;
    height: 2rem;
    margin-right: gutter(of 12 narrow);

    @include tablet {
        display: block;
    }
}

.show {
    color: $c-white;
}

.artist {
    a {
        color: $c-white;
    }

    @include desktop {
        justify-content: flex-end;
        margin-right: gutter(of 12 narrow);
        text-align: right;
    }
}

.artistGenres {
    @include desktop {
        width: span(2 narrow of 12 narrow);
    }
}

.genre {
    &List {
        display: none;

        @include desktop {
            display: flex;
            justify-content: flex-end;
            width: span(4 narrow of 12 narrow);
            max-width: span(4 narrow of 12 narrow);
            overflow: visible;
        }
    }

    + .genre {
        margin-left: $s-xs;
    }
}

.time {
    display: none;
    justify-content: flex-end;
    min-width: 10rem;
    margin-left: auto;
    color: $c-shuttle-gray;

    @include tablet {
        display: flex;
    }
}

.dots {
    margin-left: auto;

    @include desktop {
        margin-left: 0;
    }
}

.buttons {
    display: flex;
    margin-left: auto;

    @include desktop {
        margin-left: gutter(of 12 narrow);
    }
}

.button {
    margin-left: $s-s;

    @include desktop {
        margin-left: 0;
    }
}

.button + .button {
    margin-left: $s-s;
}

.button.buttonPlayCount {
    display: none;

    @include desktop {
        display: block;
    }
}
</style>
