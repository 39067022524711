<script>
export default {
    props: {
        icon: {
            type: [String, Boolean],
            default: '',
        },
        size: {
            type: String,
            default: 'large', // xsmall, xlarge, large, small, medium, iconOnly
        },
        buttonType: {
            type: String,
            default: 'primary', // tag, primary, secondary, transparent, tagSecondary, facebook, twitter, highlight, tagBlack, black, nadir, blue
        },
        active: {
            type: Boolean,
            default: false,
        },
        modifiers: {
            type: Object,
            default: () => ({
                dots: false,
                autoWidth: false,
                fill: false,
                noMargins: false,
                roundCorners: false,
            }),
        },
    },
    computed: {
        isLabel() {
            return !this.$listeners.click
        },
    },
    mounted() {},
}
</script>
<template>
    <button
        :class="[
            $style.button,
            isLabel ? $style.cannotBeHovered : '',
            $style[size],
            $style[buttonType],
            modifiers.fill ? $style.fill : '',
            modifiers.dots ? $style.dots : '',
            modifiers.autoWidth ? $style.autoWidth : '',
            modifiers.noMargins && $style.noMargins,
            modifiers.roundCorners && $style.roundCorners,
            active ? $style.active : '',
        ]"
        v-on="$listeners"
    >
        <span class="helper" />
        <BaseIcon
            v-if="icon"
            :name="icon"
            :class="[
                $style.icon,
                icon === 'signal' ? $style.iconSignal : '',
                size === 'iconOnly' ? $style.iconOnly : '',
            ]"
        />
        <span
            v-if="size !== 'icon'"
            :class="$style.main"
        >
            <slot />
        </span>
    </button>
</template>

<style lang="scss" module>
@use '@design' as *;

@mixin buttonHover {
    &::after {
        @include fill;

        z-index: $layer-negative-z-index;
        content: '';
        background: $c-button-bg;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &:hover {
        &::after {
            opacity: 1;
        }
    }
}

.icon {
    position: absolute;
    top: 50%;
    left: 1rem;
    padding-right: $s-xxs;
    margin-top: -0.6rem;
    fill: $c-white;

    &Only {
        @include center;

        margin-top: 0;
    }

    @include square(1.2rem);

    &Signal {
        left: 1.5rem;
        margin-top: -0.6rem;

        @include square(1.2rem);

        + .main {
            padding-right: $s-xxl;
            padding-left: $s-xxl + $s-m;
        }
    }
}

.small .icon {
    left: 0.5em;

    + .main {
        padding-right: 0;
        padding-left: 0;
        text-indent: 0.8rem;
    }
}

.medium .icon {
    left: 0.5em;

    + .main {
        padding-right: 0;
        padding-left: 0;
        text-indent: 1.1rem;
    }
}

.button {
    /*
    BUTTON SIZES
  */
    position: relative;
    z-index: $layer-page-z-index;
    width: 100%;
    height: 48px;
    padding: $size-button-padding;
    margin-bottom: $button-margin;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    cursor: pointer;
    background: none;
    border: 0;
    border-radius: $button-border-radius;
    outline: 0;
    transition: transform 0.1s ease-in-out;

    &:active {
        transform: scale(0.95);
    }

    &::before {
        border-radius: $button-border-radius;
    }

    &::after {
        border-radius: $button-border-radius;
    }

    &:active,
    &:focus {
        border: none;
        outline: none;
    }

    @include desktop {
        width: auto;
        min-width: 160px;
        height: 40px;
    }

    &.small,
    &.medium {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        min-width: 76px;
        height: 24px;
        padding: 0 10px;
        margin-bottom: 0;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
        white-space: nowrap;
    }

    &.xsmall {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 18px;
        font-size: 0.5em;
        font-weight: 500;
        white-space: nowrap;
    }

    &.xlarge {
        height: auto;
        padding: $s-l;
    }

    &.medium {
        font-size: 11px;
    }

    &.iconOnly {
        min-width: 0;
    }

    /*
    BUTTON STYLES
  */
    &.primary {
        color: $c-button-text;
        letter-spacing: 1px;

        @include buttonHover;
        @include baseShadow;

        &::before {
            z-index: $layer-negative-z-index;
            content: '';
            background: $c-button-bg;

            @include fill;
        }
        &::after {
            background: $c-button-bg-dark;
        }

        &:active::after,
        &.active::after {
            background: $c-highlight;
        }
    }

    &.secondary {
        color: $c-button-text;
        letter-spacing: 1px;
        background-clip: padding-box;
        border-color: transparent;

        &::before {
            z-index: $layer-negative-z-index;
            content: '';
            border: 3px solid $c-cerise-red;

            @include fill;
        }

        @include buttonHover;
        @include baseShadow;

        &:active::after,
        &.active::after {
            background: $c-highlight;
        }
    }

    &.highlight {
        color: $c-button-text;
        letter-spacing: 1px;
        border: none;

        &::before {
            z-index: $layer-negative-z-index;
            content: '';
            background: $c-highlight;

            @include fill;
        }

        &::after {
            background: lighten($c-highlight, 10%);
        }

        @include buttonHover;
        @include baseShadow;

        &:active::after,
        &.active::after {
            background: $c-highlight;
        }
    }

    &.nadir {
        color: $c-button-text;
        border: none;

        &::before,
        &::after {
            content: '';
        }

        &::before {
            z-index: $layer-negative-z-index;
            content: '';
            background: $c-nevada-grey;

            @include fill;
        }

        &:hover {
            &::before {
                background: lighten($c-nevada-grey, 10%);
            }
        }

        &:active::after,
        &.active::after {
            background: $c-nevada-grey;
        }
    }

    &.blue {
        @include baseShadow;

        position: relative;
        z-index: $layer-tooltip-z-index;
        padding: 0 $s-xxl;
        overflow: hidden;
        font-weight: 600;
        color: $c-white;
        letter-spacing: 1.5px;
        background: $c-azure-radiance;
        word-spacing: 2px;

        &::before,
        &::after {
            z-index: $layer-negative-z-index;
            content: '';
            opacity: 0;
            transition: opacity 0.3s ease-in-out;

            @include fill;
        }

        &::before {
            background: lighten($c-azure-radiance, 10%);
        }
        &::after {
            background: darken($c-azure-radiance, 10%);
        }

        &:hover::before {
            opacity: 1;
        }

        &:active::after,
        &.active::after {
            opacity: 1;
        }
    }

    &.facebook {
        @include baseShadow;

        position: relative;
        z-index: $layer-tooltip-z-index;
        padding: 0 $s-xxl;
        overflow: hidden;
        font-weight: 600;
        color: $c-white;
        letter-spacing: 1.5px;
        background: $c-facebook;
        word-spacing: 2px;

        &::before,
        &::after {
            z-index: $layer-negative-z-index;
            content: '';
            opacity: 0;
            transition: opacity 0.3s ease-in-out;

            @include fill;
        }

        &::before {
            background: lighten($c-facebook, 10%);
        }
        &::after {
            background: darken($c-facebook, 10%);
        }

        &:hover::before {
            opacity: 1;
        }

        &:active::after,
        &.active::after {
            opacity: 1;
        }
    }

    &.twitter {
        @include baseShadow;

        position: relative;
        z-index: $layer-tooltip-z-index;
        padding: 0 $s-xxl;
        overflow: hidden;
        font-weight: 600;
        color: $c-white;
        letter-spacing: 1.5px;
        background: $c-twitter;
        word-spacing: 2px;

        &::before,
        &::after {
            z-index: $layer-negative-z-index;
            content: '';
            opacity: 0;

            @include fill;
        }

        &::before {
            background: lighten($c-twitter, 10%);
        }
        &::after {
            background: darken($c-twitter, 10%);
        }

        &:hover::before {
            opacity: 1;
        }

        &:active::after,
        &.active::after {
            opacity: 1;
        }
    }

    &.google {
        @include baseShadow;

        position: relative;
        z-index: $layer-tooltip-z-index;
        padding: 0 $s-xxl;
        overflow: hidden;
        font-weight: 600;
        color: $c-white;
        letter-spacing: 1.5px;
        background: $c-google;
        word-spacing: 2px;

        &::before,
        &::after {
            z-index: $layer-negative-z-index;
            content: '';
            opacity: 0;

            @include fill;
        }

        &::before {
            background: lighten($c-google, 10%);
        }
        &::after {
            background: darken($c-google, 10%);
        }

        &:hover::before {
            opacity: 1;
        }

        &:active::after,
        &.active::after {
            opacity: 1;
        }
    }

    &.apple {
        @include baseShadow;

        position: relative;
        z-index: $layer-tooltip-z-index;
        padding: 0 $s-xxl;
        overflow: hidden;
        font-weight: 600;
        color: $c-white;
        letter-spacing: 1.5px;
        background: $c-apple-grey;
        word-spacing: 2px;

        &::before,
        &::after {
            z-index: $layer-negative-z-index;
            content: '';
            opacity: 0;

            @include fill;
        }

        &::before {
            background: lighten($c-apple-grey, 10%);
        }
        &::after {
            background: darken($c-apple-grey, 10%);
        }

        &:hover::before {
            opacity: 1;
        }

        &:active::after,
        &.active::after {
            opacity: 1;
        }
    }

    &.tag {
        color: $c-white;

        &::after {
            z-index: $layer-negative-z-index;
            content: '';
            background-color: $c-bali-hai;
            border-radius: 2px;
            opacity: 0.1;

            @include fill;
        }

        &:hover::after {
            opacity: 0.4;
        }

        &:active::after,
        &.active::after {
            background-color: $c-highlight;
            opacity: 1;
        }
    }

    &.tagSecondary {
        z-index: $layer-page-z-index;
        color: $c-white;
        background: none;

        &::before {
            z-index: $layer-negative-z-index;
            box-sizing: border-box;
            content: '';
            border: 2px solid $c-grey-heavy-metal;

            @include fill;
        }

        @include buttonHover;

        &:active::after,
        &.active::after {
            background: $c-highlight;
        }
    }

    &.black {
        @include baseShadow;

        z-index: $layer-page-z-index;
        color: $c-white;

        &::before {
            z-index: $layer-negative-z-index;
            box-sizing: border-box;
            content: '';
            background: $c-black;

            @include fill;
        }

        @include buttonHover;

        &::after {
            background: $c-shuttle-gray;
        }

        &:active::after,
        &.active::after {
            background: $c-shuttle-gray;
        }
    }

    &.white {
        @include baseShadow;

        z-index: $layer-page-z-index;
        color: $c-black;

        &::before {
            z-index: $layer-negative-z-index;
            box-sizing: border-box;
            content: '';
            background: $c-white;

            @include fill;
        }

        @include buttonHover;

        &::after {
            background: darken($c-white, 10%);
        }

        &:active::after,
        &.active::after {
            background: darken($c-white, 10%);
        }
    }

    &.tagBlack {
        @include baseShadow;

        z-index: $layer-page-z-index;
        color: $c-white;

        &::before {
            z-index: $layer-negative-z-index;
            box-sizing: border-box;
            content: '';
            border: 2px solid $c-shuttle-gray;

            @include fill;
        }

        @include buttonHover;

        &::after {
            background: $c-shuttle-gray;
        }

        &:active::after,
        &.active::after {
            background: $c-shuttle-gray;
        }
    }

    /*
    BUTTON MODIFIERS
  */

    &.autoWidth {
        width: auto;
        min-width: unset;
    }

    &.fill {
        width: 100%;
    }

    &.transparent {
        font-weight: normal;
        color: $c-text-faded;
        text-transform: none;
        background: none;

        &White {
            color: $c-white;
            text-transform: uppercase;
            transition: background 0.3s ease-out;
            &:hover {
                background: $c-highlight;
            }
        }

        &::before {
            background: none;
        }
        &::after {
            background: none;
        }

        &:hover {
            color: $c-highlight;
            .icon {
                fill: $c-highlight;
            }
        }
    }

    &.dots {
        min-width: 28px;
        &::before {
            display: block;
            content: '';
            background-color: $c-white;
            border-radius: 50%;
            box-shadow: 7px 0 $c-white, -7px 0 $c-white;

            @include square(4px);
            @include center;
        }
    }

    &.cross {
        min-width: 28px;
        .helper {
            display: block;

            &::before,
            &::after {
                display: block;
                width: 9px;
                height: 2px;
                content: '';
                background-color: $c-white;
                transform-origin: center;

                @include center;
            }

            &::after {
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }
    }

    &.cannotBeHovered {
        pointer-events: none;
        cursor: default;
    }

    &.noMargins {
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0;
    }

    &.roundCorners {
        overflow: hidden;
        border-radius: 20px;
    }

    &:disabled {
        cursor: not-allowed;
        background: $c-button-disabled-bg;
        border: 1px solid $c-shark;
        &::before,
        &::after {
            background: $c-button-disabled-bg;
        }
    }
}
</style>
