var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseButton',{class:[
        _vm.$style.button,
        _vm.buttonType === 'transparent' ? _vm.$style.buttonTransparent : '',
        _vm.isActive ? _vm.$style.buttonActive : '',
        (_vm.mix && _vm.mix.id) || (_vm.artist && _vm.artist.id) || (_vm.show && _vm.show.id) || (_vm.channel && _vm.channel.id)
            ? ''
            : _vm.$style.inactive,
    ],attrs:{"active":_vm.isLoggedIn && _vm.isActive,"button-type":_vm.isActive ? _vm.buttonType : _vm.buttonTypeActiveEvaluated,"size":_vm.size,"icon":_vm.size === 'large' ? false : _vm.isActive ? _vm.iconMain : _vm.iconAlt},on:{"click":_vm.addRemoveFav}},[(!_vm.$slots['main'] && _vm.displayCounter && _vm.size !== 'none')?_vm._t("default",function(){return [_vm._v(_vm._s(_vm.size !== 'icon-only' ? Number(_vm.totalCount > 0 ? _vm.totalCount : _vm.isActive ? 1 : 0).toLocaleString() : ''))]}):_vm._e(),_vm._v("   "),(!_vm.isActive)?_vm._t("main"):_vm._t("alt")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }