<script>
import Layout from '@layouts/main'
import LatestMixes from '@views/browse-mixes/browse-latest-mixes'
import TrendingMixes from '@views/browse-mixes/browse-trending-mixes'
import RecentlyPlayedMixes from '@views/browse-mixes/browse-recently-played-mixes'

export default {
    name: 'BrowseMixes',
    components: { Layout, LatestMixes, TrendingMixes, RecentlyPlayedMixes },
    props: {
        mainComponent: {
            type: String,
            default: 'LatestMixes',
        },
    },
    computed: {
        tabs() {
            return [
                {
                    to: { name: 'browse/mixes/latest' },
                    label: 'Latest',
                },
                {
                    to: { name: 'browse/mixes/trending' },
                    label: 'Trending',
                },
            ]
        },
        authTabs() {
            return [
                {
                    to: { name: 'browse/mixes/latest' },
                    label: 'Latest',
                },
                {
                    to: { name: 'browse/mixes/trending' },
                    label: 'Trending',
                },
                {
                    to: { name: 'browse/mixes/recently' },
                    label: 'Recently Played',
                },
            ]
        },
        loggedIn() {
            return this.$store.getters['auth/loggedIn']
        },
    },
}
</script>

<template>
    <Layout>
        <BaseContent :class="$style.page">
            <BaseSection padded>
                <h1>Mixes</h1>
            </BaseSection>
            <BaseTabPanel
                :tabs="loggedIn ? authTabs : tabs"
                :class="$style.tabs"
                is-underlined
                is-unpadded
                address-navigation
            />
            <component
                :is="mainComponent"
                :class="$style.component"
            />
        </BaseContent>
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.tabs {
    margin-bottom: $s-xxl;
}
.page {
    min-height: 101vh;
}
</style>
