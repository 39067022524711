import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import addMilliseconds from 'date-fns/addMilliseconds'

export default {
    show(oldObject) {
        const { show } = oldObject
        const { type } = show
        return {
            ...show,
            type: 'show',
            show_type: type,
        }
    },
    artist(oldObject) {
        return oldObject
    },
    mix(oldObject) {
        const {
            // eslint-disable-next-line
            occurrence_album_art,
            id,
        } = oldObject

        return {
            ...oldObject,
            id: Number(id),
            album_art: occurrence_album_art,
        }
    },
    showToOldArtist(oldObject) {
        const { artist } = oldObject
        return artist
    },
    channel(oldObject) {
        const newObject = { type: 'channel', ...oldObject }
        return newObject
    },
    schedule(oldObject) {
        const {
            time,
            // eslint-disable-next-line
            air_end,
            duration,
            // eslint-disable-next-line
            show: { id, channel_title },
            mix,
        } = oldObject
        // eslint-disable-next-line
        const duration_ms = Number(duration) * 60 * 1000
        const newObject = {
            air_time: new Date(time).getTime(),
            // eslint-disable-next-line
            air_end: air_end || addMilliseconds(time, duration_ms).getTime(),
            duration_ms,
            show_id: id,
            mix_id: mix ? mix.id : null,
            // eslint-disable-next-line
            channel_title,
            channel_id: oldObject.channel_id || channel_title.toLowerCase(),
            type: 'schedule',
        }
        return newObject
    },
    scheduleToOldShow(oldObject) {
        const { show } = oldObject
        return show
    },
    nowPlayingToOldSchedule(oldObject) {
        const { episode, station, show } = oldObject
        // eslint-disable-next-line
        const { air_start, air_end } = episode
        const newShow = {
            ...show,
            channel_id: station,
        }
        const newObject = {
            time: air_start,
            duration: differenceInMilliseconds(air_end, air_start) / 60 / 1000,
            // eslint-disable-next-line
            air_end: new Date(air_end).getTime(),
            show: newShow,
            mix: episode,
            channel_id: station,
        }
        return newObject
    },
}
