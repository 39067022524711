<script>
import ArtistBlock from '@components/artist/artist-block'

export default {
    components: {
        ArtistBlock,
    },
    props: {
        show: {
            type: Object,
            default: () => {},
        },
        artists: {
            type: Array,
            default: () => [],
        },
    },
}
</script>

<template>
    <BaseContent>
        <BaseSection
            padded
            title="The Show"
        >
            <div v-if="show.summary && show.summary.length">
                <p
                    :class="$style.info"
                    v-html="show.summary"
                />
            </div>
            <div v-else>
                <p>This show does not have a summary yet.</p>
            </div>
        </BaseSection>
        <BaseSection
            v-if="artists.length"
            padded
            title="Hosts"
        >
            <ArtistBlock
                :artists="artists"
                wrapping
            />
        </BaseSection>
    </BaseContent>
</template>

<style lang="scss" module>
@import '@design';

.info {
    columns: 320px 2;
    column-gap: $s-m * 2;
    white-space: pre-line;
}

.socialMedia {
    padding-top: $s-xl;
}

.iconContainer {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    padding: $s-xs;
    overflow: hidden;
    border: 1px solid $c-white;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 transparentize($c-black, $amount: 0.5);
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $layer-negative-z-index;
        width: 100%;
        height: 100%;
        content: '';
        background: $c-button-bg;
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    &:hover {
        border-color: transparent;
        &::before {
            opacity: 1;
        }
    }
}

.iconContainer ~ .iconContainer {
    margin-left: $s-m;
}

.icon {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    fill: $c-white;
}
</style>
