import messages from '@data/messages'

export default function messageService(key, templateData) {
    let translateString = messages[key]
    if (templateData) {
        Object.keys(templateData).forEach(key => {
            translateString = translateString.split(key).join(templateData[key])
        })
    }
    return translateString
}
