<script>
export default {
    name: 'SocialSignUp',

    props: {
        isSignUp: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            socialBaseUrl: `${process.env.VUE_APP_API_BASE_URL}v3/auth/oauth/`,
        }
    },

    methods: {
        signupWithSocial(social) {
            const path = `${this.socialBaseUrl}${social}`
            window.location.href = path
        },
    },
}
</script>

<template>
    <div :class="$style.socialSignup">
        <BaseButton
            :class="$style.buttonSocial"
            button-type="facebook"
            fill
            @click="signupWithSocial('facebook')"
        >
            {{ isSignUp ? 'SIGN UP WITH' : 'LOG IN WITH' }} FACEBOOK
        </BaseButton>
        <BaseButton
            :class="$style.buttonSocial"
            button-type="google"
            fill
            @click="signupWithSocial('google')"
        >
            {{ isSignUp ? 'SIGN UP WITH' : 'LOG IN WITH' }} GOOGLE
        </BaseButton>
        <BaseButton
            :class="$style.buttonSocial"
            button-type="apple"
            fill
            @click="signupWithSocial('apple')"
        >
            {{ isSignUp ? 'SIGN UP WITH' : 'LOG IN WITH' }} APPLE
        </BaseButton>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.socialSignup {
    margin-bottom: $s-m;
    .buttonSocial {
        width: 100%;
        margin-bottom: $s-m;
    }
}
</style>
